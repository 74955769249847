import React from "react";
import { Link } from "react-router-dom";

import { Box, Stack } from "@mui/material";

import { AppLogo } from "~src/components/lib/logos";
import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";

import MobileNavMenuButton from "./MobileNavMenuButton";
import UserTools from "./UserTools";

const Appbar: React.VFC = () => {
  const { availableRoleInfos } = useBusinessAccountHandle();
  return (
    <Box
      component="header"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: "appBar",
        width: "100%",
        backgroundColor: "var(--color-main-bg)",
        borderColor: "divider",
        borderStyle: "solid",
        borderWidth: "0 0 1px",
      }}
    >
      <Box
        sx={({ breakpoints }) => ({
          margin: "0 auto",
          maxWidth: "var(--page-max-width)",
          height: "var(--appbar-height)",
          py: 3,
          px: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          ".mobile-nav-menu-button": {
            mr: 2,
          },

          [breakpoints.down("md")]: {
            maxWidth: "none",
            px: 4,
          },
        })}
      >
        <Stack direction="row" alignItems="center">
          {availableRoleInfos && availableRoleInfos.length > 0 && (
            <MobileNavMenuButton />
          )}

          <Link to="/">
            <AppLogo />
          </Link>
        </Stack>

        <UserTools />
      </Box>
    </Box>
  );
};

export default Appbar;
