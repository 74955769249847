import { createLogger } from "~common/logging";
import ErrorType from "~src/services/ErrorType";
import refreshAuth from "~src/services/mutations/refreshAuth";
import { AuthData } from "~src/services/types/core-entity-types";
import userApiClient, { UserApiError } from "~src/services/userApiClient";
import store from "~src/store";
import { setIsAuthenticated } from "~src/store/slices/core-slice";

const logger = createLogger("auth-coordinator");

let accessToken: string | null = null;

const runRefreshLoop = (authData: AuthData): void => {
  setTimeout(() => {
    refreshAuth().then(
      (refreshedAuthData: AuthData) => {
        logger.info("Refreshed auth state");
        userApiClient.setAuthToken(refreshedAuthData.id_token);
        accessToken = refreshedAuthData.access_token;
        runRefreshLoop(refreshedAuthData);
      },
      (error) => {
        if (
          error instanceof UserApiError &&
          error.error_type === ErrorType.NOT_AUTHORIZED
        ) {
          logger.warn("Failed to refresh auth state");
          window.location.replace("/");
        } else {
          throw error;
        }
      }
    );
  }, (authData.expires_in - 10) * 1000);
};

const becomeAuthenticated = (authData: AuthData): void => {
  logger.info("User authenticated");
  userApiClient.setAuthToken(authData.id_token);
  accessToken = authData.access_token;
  store.dispatch(setIsAuthenticated(true));
  runRefreshLoop(authData);
};

const getAccessToken = (): string => {
  if (accessToken === null) {
    throw new Error("Cannot get access token for an unauthenticated user");
  }
  return accessToken;
};

export { becomeAuthenticated, getAccessToken };
