import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import TopLoading from "~src/components/TopLoading";
import { selectCoreIsInitializing } from "~src/store";

import { AppbarRenderer } from "./appbar";
import {
  AppContainer,
  GlobalStyles,
  MainContainer,
  PageContainer,
} from "./base";
import { NavRenderer } from "./nav";

const Layout: React.VFC = () => {
  const isInitializing = useSelector(selectCoreIsInitializing);

  return (
    <AppContainer>
      <GlobalStyles />

      {isInitializing && <TopLoading />}

      <AppbarRenderer />

      <PageContainer>
        <NavRenderer />

        <MainContainer>
          <Outlet />
        </MainContainer>
      </PageContainer>
    </AppContainer>
  );
};

export default Layout;
