import { Components, Theme } from "@mui/material";

const buildSvgIcon = (theme: Theme): Components["MuiSvgIcon"] => ({
  styleOverrides: {
    root: {
      "&.MuiSvgIcon-fontSizeMedium": {
        fontSize: 16,
      },
      "&.MuiSvgIcon-fontSizeLarge": {
        fontSize: 24,
      },
    },
  },
});

export default buildSvgIcon;
