import { Components, Theme } from "@mui/material";

const buildAlert = (theme: Theme): Components["MuiAlert"] => {
  const { palette, spacing, typography } = theme;
  return {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      root: {
        ...typography.bodySmall,
        padding: `${spacing(2)} ${spacing(3)}`,
        "&.MuiAlert-filledError": {
          backgroundColor: palette.error.light,
          color: palette.error.main,
          ".MuiAlert-message": {
            width: "100%",
            textAlign: "center",
          },
        },
        "&.MuiAlert-filledInfo": {
          backgroundColor: palette.info.light,
          color: palette.info.main,
        },
        "&.MuiAlert-filledSuccess": {
          backgroundColor: palette.secondary.light,
          color: palette.secondary.main,
        },
        ".MuiAlert-icon": {
          marginRight: spacing(2),
        },
        ".MuiAlert-message": {
          padding: 0,
        },
      },
    },
  };
};

export default buildAlert;
