import React, { FormEvent } from "react";

import { Stack } from "@mui/material";

type FormControlsTierProps = {
  // If onFormSubmit callback is provided, FormControlsTier will render its root
  // element as a form with onFormSubmit attached as the submit handler.
  onFormSubmit?: (event: FormEvent) => void;
};

const FormControlsTier: React.FC<FormControlsTierProps> = ({
  onFormSubmit,
  children,
}) => (
  <Stack
    component={onFormSubmit ? "form" : "div"}
    onSubmit={onFormSubmit}
    noValidate={onFormSubmit ? true : undefined}
    direction={{ lg: "row", zero: "column" }}
    spacing={4}
    position="relative"
  >
    {children}
  </Stack>
);

FormControlsTier.defaultProps = {
  onFormSubmit: undefined,
};

export default FormControlsTier;
