import React from "react";

import { TableCell } from "@mui/material";

import { formatMoney } from "~common/utils/money";
import { DetailsRow } from "~src/components/lib/table-elements";
import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";
import type { PurchaseListEntryData } from "~src/services/types/purchases-types";
import { formatTimestampUTC } from "~src/utils/date-utils";

type PurchaseListEntryRowProps = {
  data: PurchaseListEntryData;
};

const PurchaseListEntryRow: React.VFC<PurchaseListEntryRowProps> = ({
  data,
}) => {
  const { businessAccountId, isSandbox } = useBusinessAccountHandle();
  const purchaseDetailHref = `/purchases/${businessAccountId}/${
    isSandbox ? "sandbox" : "live"
  }/${data.purchase_id}`;

  return (
    <DetailsRow href={purchaseDetailHref}>
      <TableCell>
        {formatTimestampUTC(data.created_at, "MMM D YYYY, h:mmA")}
      </TableCell>
      <TableCell>{data.external_purchase_id}</TableCell>
      <TableCell>{formatMoney(data.total_amount)}</TableCell>
      <TableCell>{formatMoney(data.refund_amount)}</TableCell>
    </DetailsRow>
  );
};

export default PurchaseListEntryRow;
