const setRootCssVar = (variable: string, value: string): void => {
  document.documentElement.style.setProperty(variable, value);
};

const restoreRootCssVar = (variable: string): void => {
  document.documentElement.style.removeProperty(variable);
};

const copyTextToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    // If writeText fails, attempt to fallback to using a tempory <textarea>.
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  }
};

const measureScrollbarWidth = (): number => {
  const tempEl = document.createElement("div");
  tempEl.style.cssText =
    "overflow: scroll; visibility: hidden; position: absolute;";
  document.body.appendChild(tempEl);

  const scrollbarWidth = tempEl.offsetWidth - tempEl.clientWidth;
  tempEl.remove();

  return scrollbarWidth;
};

export {
  copyTextToClipboard,
  measureScrollbarWidth,
  restoreRootCssVar,
  setRootCssVar,
};
