import React from "react";

import { ThemeProvider as Provider } from "@mui/material";

import theme from "~src/theme";

const ThemeProvider: React.FC = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
);

export default ThemeProvider;
