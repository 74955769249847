import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useTracking } from "~common/tracking";
import { AuthGuard, ErrorPage, Layout } from "~src/components/layout";
import { APP_ENVIRONMENT, ROUTER_BASENAME } from "~src/config";
import useCurrentUserHandle from "~src/hooks/useCurrentUserHandle";
// Note: while most route components are lazy loaded, Login and PurchasesLanding,
// the most likely initial pages, are kept in the main bundle.
import { Component as Login } from "~src/routes/Login";
import { Component as PurchasesLanding } from "~src/routes/purchases/PurchasesLanding";
import {
  selectCoreIsAuthenticated,
  selectCoreIsInitializing,
} from "~src/store";

const routes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,

    children: [
      // Auth-required routes.
      {
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/purchases" replace />,
          },
          {
            path: "/purchases",
            children: [
              {
                index: true,
                element: <PurchasesLanding />,
              },
              {
                path: ":businessAccountId/:environment/:purchaseId",
                lazy: () => import("./routes/purchases/PurchaseDetail"),
                handle: {
                  isDetailPage: true,
                },
              },
              // Note: it's important we keep these routes as a sibling of the purchase detail
              // route, rather than nested under it. This is to ensure their `handle` is matched
              // by useRouteConfigHandle(), and that the detail ancestor path is correctly matched
              // to /purchases. Crucially, react-router only pipes through the handle on the
              // directly-matching route.
              {
                path: ":businessAccountId/:environment/:purchaseId/refunds/:refundId",
                lazy: () => import("./routes/purchases/PurchaseRefundDetail"),
                handle: {
                  isDetailPage: true,
                },
              },
              {
                path: ":businessAccountId/:environment/:purchaseId/settlements/:settlementId",
                lazy: () =>
                  import("./routes/purchases/PurchaseSettlementDetail"),
                handle: {
                  isDetailPage: true,
                },
              },
              {
                path: ":businessAccountId/:environment/:purchaseId/refunds/:refundId/settlements/:settlementId",
                lazy: () =>
                  import("./routes/purchases/PurchaseRefundSettlementDetail"),
                handle: {
                  isDetailPage: true,
                },
              },
            ],
          },
          {
            path: "/reports",
            children: [
              {
                index: true,
                lazy: () => import("./routes/reports/ReportsLanding"),
                handle: {
                  requiredRole: ["editor", "admin"],
                },
              },
              {
                path: "settings",
                lazy: () => import("./routes/reports/ReportingSettings"),
                handle: {
                  requiredRole: "admin",
                },
              },
            ],
          },
          {
            path: "/checkouts",
            children: [
              {
                index: true,
                lazy: () => import("./routes/checkouts/CheckoutsLanding"),
              },
              {
                path: ":businessAccountId/:environment/:checkoutId",
                lazy: () => import("./routes/checkouts/CheckoutDetail"),
                handle: {
                  isDetailPage: true,
                },
              },
            ],
          },
          {
            path: "/api-keys",
            lazy: () => import("./routes/ApiKeys"),
            handle: {
              requiredRole: ["editor", "admin"],
            },
          },
          {
            path: "/team",
            lazy: () => import("./routes/Team"),
          },
          {
            path: "/account",
            lazy: () => import("./routes/Account"),
            handle: {
              isRenderIfNoTeams: true,
            },
          },
          {
            path: "/help",
            lazy: () => import("./routes/Help"),
            handle: {
              isRenderIfNoTeams: true,
            },
          },
          {
            path: "/invitations/:invitationCode/accept",
            lazy: () => import("./routes/AcceptInvitation"),
            handle: {
              isDialogPage: true,
            },
          },
        ],
      },

      // Public routes.
      {
        children: [
          {
            path: "/login",
            element: <Login />,
            handle: {
              isDialogPage: true,
            },
          },
          {
            path: "/forgot-password",
            lazy: () => import("./routes/ForgotPassword"),
            handle: {
              isDialogPage: true,
            },
          },
          {
            path: "/forgot-password/confirm",
            lazy: () => import("./routes/ConfirmForgotPassword"),
            handle: {
              isDialogPage: true,
            },
          },
          {
            path: "/join/:invitationCode",
            lazy: () => import("./routes/Join"),
            handle: {
              isDialogPage: true,
            },
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes, {
  basename: ROUTER_BASENAME,
});

// This hook manages global analytics tracking context data. We don't export this or
// implement it as a standalone hook (in ~src/hooks) because it's designed to be used
// only once within the root App component.
const useGlobalTrackingContext = (): void => {
  const { patchTrackingContext, trackUser, clearUserTraits } = useTracking();
  const { data } = useCurrentUserHandle();
  const isInitializing = useSelector(selectCoreIsInitializing);
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const intercom = useIntercom();

  // Populate the tracking context with general tags such as the app name and environment.
  useEffect(() => {
    patchTrackingContext({
      appName: "business-portal",
      appEnv: APP_ENVIRONMENT,
    });
  }, [patchTrackingContext]);

  // Once we've loaded current user data, identify the user in the tracking environment.
  useEffect(() => {
    if (data) {
      trackUser({
        id: data.id,
        email: data.email,
        name: `${data.first_name} ${data.last_name}`,
      });
    }
  }, [trackUser, data]);

  // Once the app is finished initializing, if the user is unauthenticated, clear any
  // previous user identification tracking information that may have been assigned.
  // This is to ensure that tracking events on pages like Log in do not get attributed
  // to a user from a previous session.
  // Additionally, this effect manages the booting of Intercom, which (historically,
  // in other Catch apps and in the common library) is closely linked with user tracking.
  useEffect(() => {
    if (isInitializing) {
      return;
    }
    if (isAuthenticated) {
      intercom.boot();
    } else {
      // Note: clearUserTraits currently invokes intercom.shutdown(), which (in this case)
      // is a no-op since we haven't booted yet. However, due to this, we can't call intercom.boot()
      // until *after* clearUserTraits().
      clearUserTraits();
      intercom.boot();
    }
  }, [isInitializing, isAuthenticated, clearUserTraits, intercom]);
};

const App: React.VFC = () => {
  useGlobalTrackingContext();

  return <RouterProvider router={router} />;
};

export default App;
