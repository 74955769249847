// GENERATED FILE. DO NOT EDIT.

import React from "react";

import MediumSVG from "../vector-source/Alert.medium.svg";
import SmallSVG from "../vector-source/Alert.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const AlertIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} mediumSvg={MediumSVG} {...props} />
);

export default AlertIcon;
