import React, { forwardRef } from "react";

import { ButtonProps } from "@mui/material";

import TextButton from "./TextButton";

const TextButtonSmall: React.FC<ButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <TextButton {...props} ref={ref} size="small">
      {children}
    </TextButton>
  )
);

TextButtonSmall.displayName = "TextButtonSmall";

export default TextButtonSmall;
