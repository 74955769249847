import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import {
  selectCoreIsAuthenticated,
  selectCoreIsInitializing,
} from "~src/store";

const AuthGuard: React.FC = ({ children }) => {
  const isInitializing = useSelector(selectCoreIsInitializing);
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const location = useLocation();

  if (isInitializing) {
    return null;
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ next: location }} replace />
  );
};

export default AuthGuard;
