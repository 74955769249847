import { Components, Theme } from "@mui/material";

const buildTooltip = (theme: Theme): Components["MuiTooltip"] => {
  const { spacing, palette, typography, shadows } = theme;
  return {
    defaultProps: {
      arrow: true,
      placement: "top",
    },
    styleOverrides: {
      tooltip: {
        maxWidth: spacing(80),
        padding: spacing(4),
        borderRadius: 8,
        backgroundColor: palette.background.default,
        boxShadow: shadows[3],
        color: palette.text.primary,
        ...typography.bodyRegular,
        "[data-popper-placement*='bottom'] &.MuiTooltip-tooltip": {
          marginTop: spacing(3),
        },
        "[data-popper-placement*='top'] &.MuiTooltip-tooltip": {
          marginBottom: spacing(3),
        },
      },
      arrow: {
        color: palette.background.default,
        "[data-popper-placement*='right'] &::before": {
          filter: "drop-shadow(1px 0px 2px rgba(0, 0, 0, .6))",
        },
        "[data-popper-placement*='left'] &::before": {
          filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, .6))",
        },
        "[data-popper-placement*='top'] &::before": {
          filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, .6))",
        },
        "[data-popper-placement*='bottom'] &::before": {
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, .6))",
        },
      },
    },
  };
};

export default buildTooltip;
