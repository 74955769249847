import React, { ReactNode } from "react";

import PageHeader from "./PageHeader";

type SectionHeaderProps = {
  corner?: ReactNode;
};

// Note: for now, SectionHeaders provide the same exact UI as PageHeaders.
const SectionHeader: React.FC<SectionHeaderProps> = ({ corner, children }) => (
  <PageHeader corner={corner}>{children}</PageHeader>
);

SectionHeader.defaultProps = {
  corner: null,
};

export default SectionHeader;
