import React from "react";

import { Typography } from "@mui/material";

import { ContactSupportEmailButton } from "~src/components/lib/buttons";

import DialogBoxFooter from "./DialogBoxFooter";

const ContactHelpFooter: React.VFC = () => (
  <DialogBoxFooter>
    <Typography variant="bodySmall">
      For help, contact <ContactSupportEmailButton variant="small" />
    </Typography>
  </DialogBoxFooter>
);

export default ContactHelpFooter;
