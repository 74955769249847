type CurrencyCodes = "USD";

type CurrencySpec = {
  symbol: string;
};

type CurrencyLookup = {
  [key in CurrencyCodes]: CurrencySpec;
};

const currencies: CurrencyLookup = {
  USD: {
    symbol: "$",
  },
};

const formatMoney = (
  cents: number,
  options: {
    currency?: CurrencyCodes;
    dropCentsIfRound?: boolean;
  } = {}
): string => {
  const { dropCentsIfRound = false, currency = "USD" } = options;

  const dollars = cents / 100;
  let dollarsString;
  if (dropCentsIfRound && cents % 100 === 0) {
    dollarsString = dollars.toFixed(0);
  } else {
    dollarsString = dollars.toFixed(2);
  }
  return `${currencies[currency].symbol}${dollarsString}`;
};

export type { CurrencyCodes };
export { currencies, formatMoney };
