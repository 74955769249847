import React, { ReactNode } from "react";

import { Box } from "@mui/material";

import { FormControlsTier } from "~src/components/lib/form-controls";

type IndexViewControlsProps = {
  lookup?: ReactNode;
  isLookupExpanded?: boolean;
};

const IndexViewControls: React.FC<IndexViewControlsProps> = ({
  lookup,
  isLookupExpanded,
  children,
}) => (
  <FormControlsTier>
    {lookup && (
      <Box
        sx={{
          width: {
            lg: isLookupExpanded ? "100%" : "296px",
          },
        }}
      >
        {lookup}
      </Box>
    )}
    <Box sx={{ flexGrow: 1 }} hidden={isLookupExpanded}>
      {children}
    </Box>
  </FormControlsTier>
);

IndexViewControls.defaultProps = {
  lookup: null,
  isLookupExpanded: false,
};

export default IndexViewControls;
