import { useMemo } from "react";
import type { UIMatch } from "react-router-dom";
import { useMatches } from "react-router-dom";

import type { Role } from "~src/services/types/core-entity-types";

type UIMatchHandle = {
  requiredRole?: Role | Role[];
  isDetailPage?: boolean;
  isDialogPage?: boolean;
  isRenderIfNoTeams?: boolean;
};

type RouteConfigHandle = {
  requiredRole: Role | Role[] | null;
  isDetailPage: boolean;
  detailAncestorPath: string;
  isDialogPage: boolean;
  isRenderIfNoTeams: boolean;
};

const useRouteConfigHandle = (): RouteConfigHandle => {
  const matches = useMatches() as UIMatch<unknown, UIMatchHandle>[];

  return useMemo(() => {
    const handle: RouteConfigHandle = {
      requiredRole: null,
      isDetailPage: false,
      detailAncestorPath: "/",
      isDialogPage: false,
      isRenderIfNoTeams: false,
    };

    matches.forEach((match) => {
      const { handle: matchHandle, pathname } = match;

      if (matchHandle) {
        const { requiredRole, isDetailPage, isDialogPage, isRenderIfNoTeams } =
          matchHandle;
        if (requiredRole) {
          handle.requiredRole = requiredRole;
        }
        if (isDetailPage) {
          handle.isDetailPage = true;
        }
        if (isDialogPage) {
          handle.isDialogPage = true;
        }
        if (isRenderIfNoTeams) {
          handle.isRenderIfNoTeams = true;
        }
      }

      if (!handle.isDetailPage) {
        handle.detailAncestorPath = pathname;
      }
    });

    return handle;
  }, [matches]);
};

export default useRouteConfigHandle;
