import React from "react";

import { Event as EventIcon } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";

import { TextButtonSmall } from "~common/components/controls/buttons";
import { formatTimestampUTC } from "~src/utils/date-utils";

type IndexViewScrolledStateBarProps = {
  isPageHeaderScrolled: boolean;
  startDatetime: string;
  endDatetime: string;
  hasValidDateRange: boolean;
  clearDates: () => void;
};

const IndexViewScrolledStateBar: React.VFC<IndexViewScrolledStateBarProps> = ({
  isPageHeaderScrolled,
  startDatetime,
  endDatetime,
  hasValidDateRange,
  clearDates,
}) => (
  <Box
    aria-hidden={!isPageHeaderScrolled}
    sx={({ zIndex, transitions, breakpoints }) => ({
      position: "fixed",
      zIndex: zIndex.speedDial,
      top: "var(--appbar-height)",
      left: "max(calc(var(--desktop-nav-width) + (((100vw - var(--scrollbar-width)) - var(--page-max-width)) / 2)), var(--desktop-nav-width))",
      right: "var(--scrollbar-width)",
      py: 2,
      px: 16,
      backgroundColor: "var(--color-main-bg)",
      borderBottom: "1px solid",
      borderBottomColor: "divider",
      transition: `opacity ${transitions.duration.enteringScreen}ms ${transitions.easing.easeInOut}`,
      opacity: isPageHeaderScrolled ? 1 : 0,
      pointerEvents: isPageHeaderScrolled ? "auto" : "none",

      [breakpoints.down("md")]: {
        left: 0,
        px: 4,
      },
    })}
  >
    <Chip
      label={
        hasValidDateRange ? (
          <>
            {formatTimestampUTC(startDatetime)}&ndash;
            {formatTimestampUTC(endDatetime)}{" "}
            <TextButtonSmall
              disabled={!isPageHeaderScrolled}
              onClick={() => {
                clearDates();
                document.getElementById("root")?.scrollTo(0, 0);
              }}
            >
              Clear dates
            </TextButtonSmall>
          </>
        ) : (
          <>Showing results from all dates</>
        )
      }
      icon={<EventIcon />}
    />
  </Box>
);

export default IndexViewScrolledStateBar;
