import React, { ComponentType, ReactNode } from "react";

import { Box } from "@mui/material";

import { CompactButton } from "~common/components/controls/buttons";
import type { EditModeProps } from "~src/components/lib/editable-view";
import { EditableView } from "~src/components/lib/editable-view";

import DetailUnit from "./DetailUnit";

type SettingsDetailUnitProps = {
  label: ReactNode;
  EditComponent?: ComponentType<EditModeProps>;
  onEditSuccess?: () => void;
};

const SettingsDetailUnit: React.FC<SettingsDetailUnitProps> = ({
  label,
  EditComponent,
  onEditSuccess,
  children,
}) => {
  const isEditable = !!EditComponent;

  const detailUnit = (
    <DetailUnit label={label} variant="large">
      {children}
    </DetailUnit>
  );

  return (
    <Box
      sx={{
        py: 3,
        borderBottom: "1px solid",
        borderBottomColor: "divider",
      }}
    >
      {isEditable ? (
        <EditableView
          renderReadMode={({ openEditMode, isReturningFromEdit }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box flexGrow={1}>{detailUnit}</Box>
              <CompactButton
                onClick={() => openEditMode()}
                autoFocus={isReturningFromEdit}
                sx={{ ml: 4 }}
              >
                Edit
              </CompactButton>
            </Box>
          )}
          renderEditMode={(editModeProps) => (
            <EditComponent {...editModeProps} />
          )}
          onEditSuccess={onEditSuccess}
        />
      ) : (
        <>{detailUnit}</>
      )}
    </Box>
  );
};

SettingsDetailUnit.defaultProps = {
  EditComponent: undefined,
  onEditSuccess: undefined,
};

export default SettingsDetailUnit;
