// This module defines the MUI theme used throughout the application.
// The Business Portal is constructed in multiple passes:
// First by generating a "base" theme, based on Catch's default theme builder
// (implemented in catch-frontend-common), then by extending the base theme
// with assorted Business Portal-specific customizations, because some things
// differ slightly in the Business Portal compared to other Catch applications.

// In addition to a variety of "core" MUI components, the Business Portal makes
// use of date and date time pickers, which MUI provides under its separate expansion
// library, MUI X. These components are notable because they are substantially
// more complex than your average MUI component. Date/time pickers are, in fact,
// themselves composites of a whole bunch of different components. This is relavent
// to why some of styling overrides we make for these date-picker-related components are
// somewhat repetitive and intricate. If you've read this far, consider yourself warned.

import { alpha, createTheme, ThemeOptions } from "@mui/material";

import { buildTheme } from "~common/themes/base";
import { DatePickersActionBar } from "~src/components/lib/form-controls";
import { TableContainer } from "~src/components/lib/table-elements";

const base = buildTheme({
  name: "Business Portal Catch Theme",
  isCatch: true,
  breakpoints: {
    values: {
      zero: 0,
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1440,
      xl: 1440,
    },
  },
  palette: {
    warning: {
      main: "#fdf4d8",
      contrastText: "#866704",
    },
  },
  zIndex: {
    // These should be higher than the Intercom launcher's z-index (2147483001),
    // because we want modal overlays to sit above Intercom.
    drawer: 2147483010,
    modal: 2147483020,
    // We need to override these as well, because they're supposed to be higher
    // than whatever the *drawer* and *modal* values are.
    snackbar: 2147483030,
    tooltip: 2147483040,
  },
});

const { palette, spacing, breakpoints, typography, backdrop, shape } = base;

const adjustments: Partial<ThemeOptions> = {
  typography: {
    linkRegular: {
      fontWeight: 500,
    },
  },
  components: {
    MuiFormHelperText: {
      defaultProps: {
        // Make helperText elements <div>s by default, instead of <p>s.
        // (This allows us to include block content inside helperText elements).
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: "div",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          ".MuiSwitch-root + .MuiFormControlLabel-label, .MuiCheckbox-root + .MuiFormControlLabel-label":
            {
              marginLeft: spacing(1),
              ...typography.bodySmall,
            },
          "&:active": {
            opacity: "var(--active-state-opacity)",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "36px",

          ".MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(18px)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          ".MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    // Note: ButtonBase is a separate component from Button. The date/time pickers
    // use it for certain elements, such as the menu items in the MultiSectionDigitalClock.
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "2px solid",
            outlineColor: palette.info.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ".label": {
            whiteSpace: "nowrap",
          },
          "&.MuiButton-text.MuiButton-sizeMedium": {
            ".label": {
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall, &.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium":
            {
              width: "auto",
              height: "auto",
              padding: 0,
              margin: 0,
            },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "large",
        color: "inherit",
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
            top: "1px",
          },
          ".MuiInputAdornment-root": {
            marginRight: 0,
            ".MuiSvgIcon-root": {
              color: palette.grey[500],
            },
          },
          ".MuiSelect-icon": {
            width: "20px",
            height: "20px",
            color: palette.grey[700],
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.buttonSmall,
          color: "grey.600",

          "&:hover, &:focus": {
            backgroundColor: "var(--color-hover-state-actionbar)",
          },
          "&:active": {
            opacity: "var(--active-state-opacity)",
          },

          "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
            backgroundColor: palette.info.light,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "&.MuiAlert-filledError": {
            ".MuiAlert-message": {
              textAlign: "left",
            },
          },
          ".MuiAlert-icon": {
            padding: 0,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(backdrop.color, backdrop.opacity.light),
          ".MuiMenu-root &": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        timezone: "UTC",
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        timezone: "UTC",
        slots: {
          actionBar: DatePickersActionBar,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          ".MuiDayCalendar-weekDayLabel": {
            color: palette.grey[500],
            fontSize: "12px",
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          ".MuiPickersCalendarHeader-switchViewButton": {
            display: "none",
          },
        },
      },
    },
    MuiPickersDay: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          "&:hover, &:active, &:focus": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            backgroundColor: palette.info.light,
          },
          "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active, &.Mui-selected:focus":
            {
              backgroundColor: palette.info.main,
            },
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          ".MuiPickersMonth-monthButton": {
            "&:hover, &:active, &:focus": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: palette.info.light,
            },
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active, &.Mui-selected:focus":
              {
                backgroundColor: palette.info.main,
              },
          },
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          ".MuiPickersYear-yearButton": {
            "&:hover, &:active, &:focus": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: palette.info.light,
            },
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active, &.Mui-selected:focus":
              {
                backgroundColor: palette.info.main,
              },
            "&.Mui-disabled": {
              color: palette.action.disabled,
            },
          },
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          ".MuiMenuItem-root": {
            "&:hover, &:active, &:focus": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: palette.info.light,
            },
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active, &.Mui-selected:focus":
              {
                backgroundColor: palette.info.main,
              },
            "&.Mui-disabled": {
              color: palette.action.disabled,
            },
          },
        },
      },
    },
    MuiTimeClock: {
      styleOverrides: {
        root: {
          ".MuiClock-pin, .MuiClockPointer-root": {
            backgroundColor: palette.info.main,
          },
          ".MuiClockPointer-thumb": {
            borderColor: palette.info.main,
          },
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableRow-hover": {
            cursor: "pointer",
            "&:hover .MuiTableCell-root": {
              backgroundColor: "var(--color-hover-state-actionbar)",
            },
            "&:active": {
              opacity: "var(--active-state-opacity)",
            },
          },
          ".MuiTableCell-root": {
            // position: relative is needed for holding <VisuallyHiddenText> inside of table cells.
            // Without it, the visually hidden content (which is absolutely positioned) causes
            // the overall table layout to go haywire.
            position: "relative",
            padding: spacing(3, 4),
            borderColor: palette.divider,
            ...typography.bodySmall,

            "&:first-of-type": {
              paddingLeft: 0,
            },
            "&:last-of-type": {
              [breakpoints.up("md")]: {
                paddingRight: 0,
              },
            },
          },
          ".MuiTableHead-root": {
            ".MuiTableCell-root": {
              paddingTop: 0,
              paddingBottom: spacing(4),
              verticalAlign: "top",
              ...typography.h4,
            },
          },
        },
      },
    },

    // We have our own custom TableContainer component which may be used directly
    // to wrap an MUI Table. However, in case one does use the MUI TableContainer
    // itself, it should just default to rendering as our custom TableContainer.
    MuiTableContainer: {
      defaultProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: TableContainer,
      },
    },

    MuiChip: {
      defaultProps: {
        size: "small",
        variant: "filled",
      },
      styleOverrides: {
        root: {
          height: "20px",
          borderRadius: `${shape.borderRadius * 3}px`,
          ...typography.bodySmall,

          ".MuiChip-icon": {
            fontSize: "16px",
            color: "inherit",
          },
          ".MuiCircularProgress-root": {
            color: "inherit",
          },

          "&.MuiChip-filledDefault": {
            color: palette.grey[500],
            backgroundColor: palette.grey[200],
          },
          "&.MuiChip-filledSuccess": {
            color: palette.secondary.main,
            backgroundColor: palette.secondary.light,
          },

          ".MuiChip-label": {
            display: "inline-flex",
            alignItems: "center",

            ".MuiButton-text.MuiButton-sizeSmall": {
              padding: 0,
              marginLeft: spacing(1),
              height: "auto",
            },
          },

          "&.MuiChip-sizeMedium": {
            ...typography.bodyRegular,
          },
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          ...typography.bodySmall,

          "a, .MuiButton-text.MuiButton-sizeSmall": {
            padding: 0,
            height: "auto",
            ...typography.linkSmall,
          },

          ".MuiBreadcrumbs-separator": {
            margin: "0 0.3em",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "auto",
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root": {
            minHeight: "auto",
            outlineOffset: "-2px",
            padding: `0 0 ${spacing(4)}`,
            ...typography.buttonSmall,
            color: palette.grey[500],

            "&:not(:last-of-type)": {
              marginRight: spacing(6),
            },

            "&.Mui-selected": {
              color: palette.text.primary,
            },
          },
        },
      },
    },
  },
};

const theme = createTheme(base, adjustments);

export default theme;
