import React from "react";
import ReactDOM from "react-dom";

import { configureLogging } from "~common/logging";
import { initializeSentry } from "~common/tracking";
import {
  B2BIntercomProvider,
  ConfigEnvProvider,
  DateLocalizationProvider,
  QueryClientProvider,
  StoreProvider,
  ThemeProvider,
} from "~src/components/providers";

import App from "./App";
import {
  APP_ENVIRONMENT,
  LOGGING_MODE,
  ROUTER_BASENAME,
  SENTRY_DSN,
} from "./config";
import { coreInit } from "./coordinators/init-coordinator";

if (SENTRY_DSN) {
  initializeSentry(SENTRY_DSN, APP_ENVIRONMENT);
}

// Run a quick shim (as early as possible) to ensure that if the
// the user went to "/" (without the leading "/p/"), we immediately
// reroute them to "/p/".
(() => {
  const pathRoot = window.location.pathname.split("/")[1];
  if (`/${pathRoot}` !== ROUTER_BASENAME) {
    window.location.replace(ROUTER_BASENAME);
  }
})();

configureLogging({
  mode: LOGGING_MODE,
});

// Kickoff the async core init routine (which sets up the current
// authentication state ahead of rendering the component tree).
void coreInit();

ReactDOM.render(
  <React.StrictMode>
    <ConfigEnvProvider>
      <B2BIntercomProvider>
        <ThemeProvider>
          <DateLocalizationProvider>
            <StoreProvider>
              <QueryClientProvider>
                <App />
              </QueryClientProvider>
            </StoreProvider>
          </DateLocalizationProvider>
        </ThemeProvider>
      </B2BIntercomProvider>
    </ConfigEnvProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
