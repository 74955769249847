import React, { FormEvent, ReactNode } from "react";

import { Box, Stack } from "@mui/material";

import { AppLogo } from "~src/components/lib/logos";

type DialogBoxProps = {
  // If onFormSubmit callback is provided, DialogBox will render its root
  // element as a form with onFormSubmit attached as the submit handler.
  onFormSubmit?: (event: FormEvent) => void;
  corner?: ReactNode;
  isFullWidth?: boolean;
};

const DialogBox: React.FC<DialogBoxProps> = ({
  onFormSubmit,
  corner,
  isFullWidth,
  children,
}) => (
  <Box
    component={onFormSubmit ? "form" : "div"}
    onSubmit={onFormSubmit}
    noValidate={onFormSubmit ? true : undefined}
    sx={({ breakpoints }) => ({
      width: isFullWidth ? "100%" : "464px",
      margin: "0 auto",
      p: 8,
      backgroundColor: "var(--color-main-bg)",
      borderColor: "divider",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: 2,

      [breakpoints.down("md")]: {
        width: "auto",
        height: "100%",
        border: "none",
        borderRadius: 0,
      },
    })}
  >
    <Stack spacing={8}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <AppLogo />
        {corner}
      </Box>
      {children}
    </Stack>
  </Box>
);

DialogBox.defaultProps = {
  onFormSubmit: undefined,
  corner: null,
  isFullWidth: false,
};

export default DialogBox;
