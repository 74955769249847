import React, { ReactNode } from "react";

import { Box, Typography } from "@mui/material";

import { useTracking } from "~common/tracking";
import { CopyTextButton } from "~src/components/lib/buttons";

type DetailUnitProps = {
  label: ReactNode;
  copyTextValue?: string;
  variant?: "default" | "large";
  isMultiLine?: boolean;
};

const DetailUnit: React.FC<DetailUnitProps> = ({
  label,
  copyTextValue,
  variant,
  isMultiLine,
  children,
}) => {
  const { trackEvent } = useTracking();

  return (
    <Box>
      <Typography variant="bodySmall" component="h4" color="grey.600">
        {label}
      </Typography>
      <Box
        title={copyTextValue}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant={variant === "large" ? "bodyLarge" : "bodyRegular"}
          component="div"
          noWrap={!isMultiLine}
          flexGrow={1}
        >
          {children ? <>{children}</> : <>-</>}
        </Typography>
        {copyTextValue && (
          <Box sx={{ color: "grey.400", lineHeight: 0 }}>
            <CopyTextButton
              value={copyTextValue}
              variant="large"
              onCopySuccess={() =>
                trackEvent("Detail unit copied", {
                  value: copyTextValue,
                })
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

DetailUnit.defaultProps = {
  copyTextValue: undefined,
  variant: "default",
  isMultiLine: false,
};

export default DetailUnit;
