import React from "react";

import { Box } from "@mui/material";

const MainContainer: React.FC = ({ children }) => (
  <Box
    id="main"
    component="main"
    sx={{
      flex: 1,
      display: "flex",
      // The *min-width: 0* is needed to allow the main container to hold
      // overflowing elements (namely, tables on mobile).
      minWidth: 0,
    }}
  >
    {children}
  </Box>
);

export default MainContainer;
