import React, { forwardRef } from "react";

import { IconButtonProps } from "@mui/material";

import WrappedIconButton from "./WrappedIconButton";

const PrimaryCompactIconButton: React.FC<IconButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedIconButton
      {...props}
      ref={ref}
      size="small"
      color="regularButtonBackground"
    >
      {children}
    </WrappedIconButton>
  )
);

PrimaryCompactIconButton.displayName = "PrimaryCompactIconButton";

export default PrimaryCompactIconButton;
