import React from "react";

import { Table, TableBody, TableCell, TableHead } from "@mui/material";

import { MainContentPane } from "~src/components/lib/content-panes";
import {
  IndexViewPageHeader,
  IndexViewScrolledStateBar,
} from "~src/components/lib/index-view-elements";
import {
  DetailsHeaderRow,
  ResultsTableView,
  TableContainer,
} from "~src/components/lib/table-elements";
import useIndexView from "~src/hooks/useIndexView";
import getPurchaseByExternalId from "~src/services/queries/getPurchaseByExternalId";
import getPurchasesList from "~src/services/queries/getPurchasesList";
import type { PurchaseListEntryData } from "~src/services/types/purchases-types";

import PurchaseListEntryRow from "./PurchaseListEntryRow";

const PurchasesLandingPageContent: React.VFC = () => {
  const {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    startDatetime,
    endDatetime,
    hasValidDateRange,
    clearDates,
    listQuery,
    rawLookupValue,
    setRawLookupValue,
    lookupValue,
    isLookupExpanded,
    closeLookup,
    lookupFieldHandleRef,
    lookupQuery,
    results,
    pageHeaderRef,
    isPageHeaderScrolled,
  } = useIndexView<PurchaseListEntryData>({
    baseQueryKey: "purchases",
    listQueryFn: getPurchasesList,
    lookupQueryFn: getPurchaseByExternalId,
  });

  return (
    <>
      <MainContentPane>
        <IndexViewPageHeader
          title="Purchases"
          lookupTitle="Purchase lookup"
          lookupFieldLabel="Merchant purchase ID"
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          hasValidDateRange={hasValidDateRange}
          clearDates={clearDates}
          rawLookupValue={rawLookupValue}
          setRawLookupValue={setRawLookupValue}
          isLookupExpanded={isLookupExpanded}
          closeLookup={closeLookup}
          lookupFieldHandleRef={lookupFieldHandleRef}
          pageHeaderRef={pageHeaderRef}
        />

        <ResultsTableView
          isLoading={lookupValue ? lookupQuery.isLoading : listQuery.isLoading}
          isFetchingNextPage={
            lookupValue ? false : listQuery.isFetchingNextPage
          }
          hasNextPage={lookupValue ? false : listQuery.hasNextPage}
          fetchNextPage={lookupValue ? undefined : listQuery.fetchNextPage}
          results={results}
          noResultsContent={<>No matching purchases.</>}
        >
          <TableContainer>
            <Table aria-label="Purchases table">
              <TableHead>
                <DetailsHeaderRow>
                  <TableCell>
                    Created (<abbr>UTC</abbr>)
                  </TableCell>
                  <TableCell>Merchant purchase ID</TableCell>
                  <TableCell>Order total</TableCell>
                  <TableCell>Amount refunded</TableCell>
                </DetailsHeaderRow>
              </TableHead>

              {results.length > 0 && (
                <TableBody>
                  {results.map((purchaseListEntryData) => (
                    <PurchaseListEntryRow
                      key={purchaseListEntryData.purchase_id}
                      data={purchaseListEntryData}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </ResultsTableView>
      </MainContentPane>

      <IndexViewScrolledStateBar
        isPageHeaderScrolled={isPageHeaderScrolled}
        startDatetime={startDatetime}
        endDatetime={endDatetime}
        hasValidDateRange={hasValidDateRange}
        clearDates={clearDates}
      />
    </>
  );
};

export default PurchasesLandingPageContent;
