import { Components, Theme } from "@mui/material";

const buildTextField = (theme: Theme): Components["MuiTextField"] => {
  const { spacing, shape, palette } = theme;
  return {
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        paddingTop: spacing(0.5),
        ".MuiInputBase-root": {
          borderRadius: `${shape.textFieldBorderRadius}px`,
        },
        ".MuiInputBase-input": {
          boxSizing: "border-box",
          padding: spacing(3, 4),
          height: spacing(13),
        },
        ".MuiFormLabel-root": {
          paddingTop: spacing(0.5),
        },
        ".MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
          lineHeight: 1,
        },
        ".MuiFormLabel-colorPrimary": {
          color: palette.tertiaryText,
          "&.Mui-focused:not(.Mui-error)": {
            color: palette.text.primary,
          },
          "&.Mui-error": {
            color: palette.error,
          },
        },
        ".MuiInputBase-colorPrimary": {
          "&.Mui-focused:not(.Mui-error) fieldset": {
            borderColor: palette.text.primary,
          },
        },
      },
    },
  };
};

export default buildTextField;
