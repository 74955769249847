import React, { forwardRef } from "react";

import { SwitchProps, useTheme } from "@mui/material";

import WrappedSwitch from "./WrappedSwitch";

const DonationSwitch: React.VFC<SwitchProps> = forwardRef(
  ({ checked, onChange, sx = [], ...props }, ref) => {
    const { palette } = useTheme();
    return (
      <WrappedSwitch
        {...props}
        checked={checked}
        onChange={onChange}
        sx={[
          {
            "& .MuiSwitch-switchBase": {
              "&.Mui-checked": {
                "& + .MuiSwitch-track": {
                  backgroundColor: palette.donationMain,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                backgroundColor: palette.donationMain,
                border: `3px solid ${palette.grey[100]}`,
              },
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        ref={ref}
      />
    );
  }
);

DonationSwitch.displayName = "DonationSwitch";

export default DonationSwitch;
