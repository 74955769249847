import React, { KeyboardEvent, MouseEvent, useEffect, useRef } from "react";

import {
  ClickAwayListener,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";

type DropMenuProps = {
  id: string;
  triggerEl: HTMLButtonElement | null;
  isOpen: boolean;
  onClose: () => void;
  closeOnItemClick?: boolean;
  variant?: "default" | "large";
};

const DropMenu: React.FC<DropMenuProps> = ({
  id,
  triggerEl,
  isOpen,
  onClose,
  closeOnItemClick,
  variant,
  children,
}) => {
  const { zIndex } = useTheme();

  const handleMenuListClick = (event: MouseEvent): void => {
    if (
      closeOnItemClick &&
      (event.target as HTMLElement).closest(".drop-menu-item")
    ) {
      onClose();
    }
  };

  const handleMenuListKeyDown = (event: KeyboardEvent): void => {
    if (event.code === "Tab") {
      event.preventDefault();
      onClose();
    } else if (event.code === "Escape") {
      onClose();
    }
  };

  // Restore focus to the trigger when closing.
  const prevIsOpen = useRef<boolean>(isOpen);
  useEffect(() => {
    if (prevIsOpen.current && !isOpen) {
      triggerEl?.focus();
    }
    prevIsOpen.current = isOpen;
  }, [isOpen, triggerEl]);

  return (
    <Popper
      disablePortal
      anchorEl={triggerEl}
      open={isOpen}
      placement="bottom-end"
      style={{
        zIndex: zIndex.tooltip,
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper
          elevation={2}
          sx={{
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 2,
          }}
        >
          <MenuList
            component="div"
            id={id}
            aria-labelledby={triggerEl?.id}
            autoFocusItem={isOpen}
            onClick={handleMenuListClick}
            onKeyDown={handleMenuListKeyDown}
            sx={{
              ".drop-menu-item": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: variant === "large" ? "251px" : "240px",
                py: variant === "large" ? 2 : 1,
                px: 4,
                typography: variant === "large" ? "h2" : "buttonSmall",
                color: "grey.600",

                "&.muted": {
                  color: "grey.500",
                },

                "&:hover, &:focus": {
                  backgroundColor: "var(--color-hover-state-actionbar)",
                },
                "&:hover": {
                  cursor: "pointer",
                },
                "&:active": {
                  opacity: "var(--active-state-opacity)",
                },
              },
            }}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

DropMenu.defaultProps = {
  closeOnItemClick: false,
  variant: "default",
};

export default DropMenu;
