import React, { MouseEvent } from "react";

import { Dialog as MuiDialog, Stack } from "@mui/material";

type DialogProps = {
  open: boolean;
  onClose: () => void;
  ariaLabelledBy: string;
};

const Dialog: React.FC<DialogProps> = ({
  open,
  onClose,
  ariaLabelledBy,
  children,
}) => {
  const handleClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      target.closest(".modal-close-button") ||
      !target.closest(".MuiDialog-paper")
    ) {
      onClose();
    }
  };

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabelledBy}
      onClick={handleClick}
      className="overlay-medium"
      sx={{
        ".MuiDialog-paper": {
          p: 6,
          borderRadius: 4,
        },
      }}
    >
      <Stack spacing={6}>{children}</Stack>
    </MuiDialog>
  );
};

export default Dialog;
