import React, { forwardRef } from "react";

import PrimaryButton from "./PrimaryButton";
import { WrappedButtonProps } from "./WrappedButton";

const PrimaryCompactButton: React.FC<WrappedButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <PrimaryButton {...props} ref={ref} size="small">
      {children}
    </PrimaryButton>
  )
);

PrimaryCompactButton.displayName = "PrimaryCompactButton";

export default PrimaryCompactButton;
