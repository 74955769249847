import React, { ReactNode } from "react";

import { Box } from "@mui/material";

import { useTracking } from "~common/tracking";
import { ViewMoreButton } from "~src/components/lib/buttons";
import { AccentAwareLoadingBar } from "~src/components/lib/loading-states";
import type { Environment } from "~src/store/slices/businessAccountEnvironment-slice";

import NoResults from "./NoResults";

type ResultsTableViewProps = {
  isLoading?: boolean;
  loadingEnvironment?: Environment;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => Promise<unknown>;
  results: unknown[];
  noResultsContent?: ReactNode;
};

const ResultsTableView: React.FC<ResultsTableViewProps> = ({
  isLoading,
  loadingEnvironment,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  results,
  noResultsContent,
  children,
}) => {
  const { trackEvent } = useTracking();

  return (
    <Box className="results-table-view">
      {children}
      {(isLoading || isFetchingNextPage) && (
        <AccentAwareLoadingBar environment={loadingEnvironment} />
      )}

      {!isLoading && !results.length && (
        <NoResults>{noResultsContent}</NoResults>
      )}

      {!isLoading && hasNextPage && (
        <ViewMoreButton
          onClick={() => {
            trackEvent("View more button clicked");
            fetchNextPage && !isFetchingNextPage && fetchNextPage();
          }}
          isLoading={isFetchingNextPage}
        />
      )}
    </Box>
  );
};

ResultsTableView.defaultProps = {
  isLoading: false,
  loadingEnvironment: undefined,
  isFetchingNextPage: false,
  hasNextPage: false,
  fetchNextPage: undefined,
  noResultsContent: null,
};

export default ResultsTableView;
