import { Components, Theme } from "@mui/material";

const buildSelect = (theme: Theme): Components["MuiSelect"] => {
  const { spacing } = theme;

  return {
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
    },
    styleOverrides: {
      select: {
        boxSizing: "border-box",
        padding: spacing(3, 4),
        height: spacing(13),
      },
    },
  };
};

export default buildSelect;
