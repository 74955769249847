import React, { forwardRef } from "react";

import { Alert, AlertProps } from "@mui/material";

const ErrorAlert: React.FC<AlertProps> = forwardRef(
  ({ children, icon, ...props }, ref) => (
    <Alert severity="error" icon={icon || false} {...props} ref={ref}>
      {children}
    </Alert>
  )
);

export default ErrorAlert;
