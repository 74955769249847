import React, { forwardRef } from "react";

import { IconButtonProps } from "@mui/material";

import WrappedIconButton from "./WrappedIconButton";

const DefaultIconButton: React.FC<IconButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedIconButton {...props} ref={ref}>
      {children}
    </WrappedIconButton>
  )
);

DefaultIconButton.displayName = "DefaultIconButton";

export default DefaultIconButton;
