import React from "react";

import { Box, Typography } from "@mui/material";

const Skipnav: React.VFC = () => (
  <Box
    sx={({ zIndex }) => ({
      "&:not(:focus-within)": {
        position: "absolute",
        overflow: "hidden",
        width: "1px",
        height: "1px",
        margin: "-1px",
        padding: 0,
        whiteSpace: "nowrap",
        border: 0,
        clip: "rect(0 0 0 0)",
      },

      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: zIndex.tooltip + 1,
      display: "flex",
      justifyContent: "flex-start",
    })}
  >
    <Typography
      component="a"
      variant="buttonSmall"
      href="#main"
      sx={({ shadows }) => ({
        p: 2,
        boxShadow: shadows[2],
        color: "grey.600",
        backgroundColor: "grey.100",
      })}
    >
      Skip to content
    </Typography>
  </Box>
);

export default Skipnav;
