import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const selectCoreIsInitializing = createSelector(
  (state: RootState) => state.core.isInitializing,
  (isInitializing) => isInitializing
);

const selectCoreIsAuthenticated = createSelector(
  (state: RootState) => state.core.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);

const selectCoreIsMobileNavOpen = createSelector(
  (state: RootState) => state.core.isMobileNavOpen,
  (isMobileNavOpen) => isMobileNavOpen
);

export {
  selectCoreIsAuthenticated,
  selectCoreIsInitializing,
  selectCoreIsMobileNavOpen,
};
