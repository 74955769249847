import React from "react";

import { Box, LinearProgress } from "@mui/material";

import type { Environment } from "~src/store/slices/businessAccountEnvironment-slice";

type AccentAwareLoadingBarProps = {
  environment?: Environment;
};

const AccentAwareLoadingBar: React.VFC<AccentAwareLoadingBarProps> = ({
  environment,
}) => (
  <Box
    component="span"
    sx={{
      display: "block",
      width: "100%",
      color: environment
        ? `var(--color-accent-main-${environment})`
        : "var(--color-accent-main)",
    }}
  >
    <LinearProgress color="inherit" sx={{ width: "100%", height: "2px" }} />
  </Box>
);

export default AccentAwareLoadingBar;
