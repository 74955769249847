import React from "react";

import { ErrorAlert } from "~common/components/alerts";

type PasswordStrengthErrorAlertProps = {
  isLengthSatisfied?: boolean;
  isSymbolSatisfied?: boolean;
  isNumberSatisfied?: boolean;
  isCapitalLetterSatisfied?: boolean;
};

const PasswordStrengthErrorAlert: React.VFC<PasswordStrengthErrorAlertProps> =
  ({
    isLengthSatisfied,
    isSymbolSatisfied,
    isNumberSatisfied,
    isCapitalLetterSatisfied,
  }) => (
    <ErrorAlert sx={{ ul: { mx: 3 } }}>
      <ul>
        {!isLengthSatisfied && (
          <li>Password must be at least 8 characters long</li>
        )}
        {!isSymbolSatisfied && (
          <li>Password must include a special character</li>
        )}
        {!isNumberSatisfied && <li>Password must include a number</li>}
        {!isCapitalLetterSatisfied && (
          <li>Password must include a capital letter</li>
        )}
      </ul>
    </ErrorAlert>
  );

PasswordStrengthErrorAlert.defaultProps = {
  isLengthSatisfied: false,
  isSymbolSatisfied: false,
  isNumberSatisfied: false,
  isCapitalLetterSatisfied: false,
};

export default PasswordStrengthErrorAlert;
