import { alpha, Components, Theme } from "@mui/material";

const buildBackdrop = (theme: Theme): Components["MuiBackdrop"] => {
  const { backdrop, zIndex } = theme;
  return {
    styleOverrides: {
      root: {
        zIndex: zIndex.tooltip - 1,
        "&.MuiBackdrop-root": {
          ".overlay-none &": {
            backgroundColor: "transparent",
          },
          ".overlay-light &": {
            backgroundColor: alpha(backdrop.color, backdrop.opacity.light),
          },
          ".overlay-medium &": {
            backgroundColor: alpha(backdrop.color, backdrop.opacity.medium),
          },
          ".overlay-dark &": {
            backgroundColor: alpha(backdrop.color, backdrop.opacity.dark),
          },
        },
      },
    },
  };
};

export default buildBackdrop;
