import React, { forwardRef } from "react";

import { Switch, SwitchProps } from "@mui/material";

const WrappedSwitch: React.VFC<SwitchProps> = forwardRef(
  ({ checked, onChange, ...props }, ref) => (
    <Switch {...props} checked={checked} onChange={onChange} ref={ref} />
  )
);

WrappedSwitch.displayName = "WrappedSwitch";

export default WrappedSwitch;
