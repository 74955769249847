import { Components, Theme } from "@mui/material";

const buildSwitch = (theme: Theme): Components["MuiSwitch"] => {
  const { spacing, palette, shape, transitions } = theme;
  return {
    defaultProps: {
      color: "primary",
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 0,
        width: spacing(9.5),
        height: spacing(4.5),
        display: "flex",
        overflow: "visible",
        "& .MuiSwitch-switchBase": {
          padding: 1,
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              opacity: 1,
            },
          },
        },
        ".MuiSwitch-input": {
          height: spacing(12),
          top: "50%",
          left: "-50%",
          transform: `translateY(-50%)`,
        },
        "& .MuiSwitch-track": {
          borderRadius: `${shape.borderRadius * 20}px`,
          border: `1px solid ${palette.grey[300]}`,
          backgroundColor: palette.grey[300],
          opacity: 1,
        },
        ".MuiSwitch-switchBase.Mui-checked ~ .MuiSwitch-track": {
          border: `1px solid ${palette.primary.main}`,
        },
        "& .MuiSwitch-thumb": {
          height: spacing(4),
          width: spacing(4),
          transition: transitions.create(["width"], {
            duration: 200,
          }),
          color: palette.common.white,
        },
        ".Mui-focusVisible": {
          outline: "2px solid",
          outlineColor: palette.info.main,
        },
      },
    },
  };
};

export default buildSwitch;
