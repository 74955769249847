import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const selectBusinessAccountId = createSelector(
  (state: RootState) => state.businessAccountEnvironment.businessAccountId,
  (businessAccountId) => businessAccountId
);

const selectEnvironment = createSelector(
  (state: RootState) => state.businessAccountEnvironment.environment,
  (environment) => environment
);

export { selectBusinessAccountId, selectEnvironment };
