import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  LocalAtm as LocalAtmIcon,
  OpenInNew as OpenInNewIcon,
  PersonAddOutlined as PersonAddOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  VpnKeyOutlined as VpnKeyOutlinedIcon,
} from "@mui/icons-material";
import { Backdrop, Box, Stack, useMediaQuery, useTheme } from "@mui/material";

import { useTracking } from "~common/tracking";
import { selectCoreIsMobileNavOpen } from "~src/store";
import { toggleMobileNav } from "~src/store/slices/core-slice";

import NavBusinessAccountHeader from "./NavBusinessAccountHeader";
import NavEnvironmentSection from "./NavEnvironmentSection";
import NavGroupContainer from "./NavGroupContainer";
import NavItem from "./NavItem";
import NavSubheading from "./NavSubheading";

const Nav: React.VFC = () => {
  const { trackEvent } = useTracking();
  const location = useLocation();
  const isMobileNavOpen = useSelector(selectCoreIsMobileNavOpen);
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isMobileMediaQuery = useMediaQuery(breakpoints.down("md"));

  // Close the mobile nav, if it's open, when navigating to another page.
  useEffect(() => {
    if (isMobileNavOpen) {
      dispatch(toggleMobileNav());
    }
    // We don't need/want isMobileNavOpen as a dependency of this effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  // Handle the edge case where the window was resized from mobile->desktop
  // after opening the mobile nav. We want to automatically "close" the mobile
  // nav once we're back up to a desktop viewport size, so that the backdrop
  // goes away.
  useEffect(() => {
    if (!isMobileMediaQuery && isMobileNavOpen) {
      dispatch(toggleMobileNav());
    }
  }, [dispatch, isMobileMediaQuery, isMobileNavOpen]);

  return (
    <>
      <Box
        component="nav"
        sx={({ spacing, zIndex, transitions }) => ({
          "--padding": spacing(6),
          position: "relative",
          zIndex: zIndex.appBar - 1,
          width: "var(--desktop-nav-width)",
          flexShrink: 0,
          padding: "var(--padding)",
          backgroundColor: "var(--color-main-bg)",
          borderColor: "divider",
          borderStyle: "solid",
          borderWidth: "0 1px 0 0",

          // Fill the background color in the space to the left of the nav
          // in case the viewport is wider than the page max width.
          "::before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: "calc(-1 * ((100vw - var(--page-max-width)) / 2))",
            bottom: 0,
            right: "100%",
            bgcolor: "var(--color-main-bg)",
            pointerEvents: "none",
          },

          [breakpoints.down("md")]: {
            position: "fixed",
            top: "var(--appbar-height)",
            left: 0,
            bottom: 0,
            width: "calc(100% - var(--mobile-drawer-gutter))",
            overflow: "auto",
            transform: isMobileNavOpen
              ? "translate3d(0, 0, 0)"
              : "translate3d(-100%, 0, 0)",
            transition: `transform ${transitions.duration.enteringScreen}ms ${transitions.easing.easeInOut}`,
          },
        })}
      >
        <Stack
          spacing={6}
          sx={({ breakpoints: bp }) => ({
            position: "sticky",

            [bp.up("md")]: {
              top: "calc(var(--appbar-height) + var(--padding))",
            },
          })}
        >
          <NavBusinessAccountHeader />

          <NavEnvironmentSection />

          <NavGroupContainer>
            <NavSubheading>Transactions</NavSubheading>

            <NavItem href="/purchases" icon={<LocalAtmIcon />}>
              Purchases
            </NavItem>
            <NavItem href="/reports" icon={<DescriptionOutlinedIcon />}>
              Reports
            </NavItem>
          </NavGroupContainer>

          <NavGroupContainer>
            <NavSubheading>Integration</NavSubheading>

            <NavItem href="/checkouts" icon={<ShoppingCartOutlinedIcon />}>
              Checkouts
            </NavItem>
            <NavItem href="/api-keys" icon={<VpnKeyOutlinedIcon />}>
              API Keys
            </NavItem>
            <NavItem
              href="https://catch.readme.io/"
              icon={<OpenInNewIcon />}
              onClick={() => trackEvent("Documentation nav link clicked")}
            >
              Documentation
            </NavItem>
          </NavGroupContainer>

          <NavGroupContainer>
            <NavSubheading>Admin</NavSubheading>

            <NavItem href="/team" icon={<PersonAddOutlinedIcon />}>
              Team
            </NavItem>
          </NavGroupContainer>
        </Stack>
      </Box>

      <Backdrop
        open={isMobileNavOpen}
        onClick={() => dispatch(toggleMobileNav())}
        sx={{ zIndex: ({ zIndex }) => zIndex.appBar - 2 }}
      />
    </>
  );
};

export default Nav;
