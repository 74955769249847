import React from "react";

import { useTracking } from "~common/tracking";
import { SUPPORT_EMAIL } from "~src/support";

import type { CopyTextButtonVariant } from "./CopyTextButton";
import CopyTextButton from "./CopyTextButton";

type ContactSupportEmailButtonProps = {
  variant?: CopyTextButtonVariant;
};

const ContactSupportEmailButton: React.VFC<ContactSupportEmailButtonProps> = ({
  variant,
}) => {
  const { trackEvent } = useTracking();

  return (
    <CopyTextButton
      value={SUPPORT_EMAIL}
      variant={variant}
      onCopySuccess={() => trackEvent("Contact support email copied")}
    >
      {SUPPORT_EMAIL}
    </CopyTextButton>
  );
};

export default ContactSupportEmailButton;
