import { useCallback, useState } from "react";

import { copyTextToClipboard } from "~src/utils/dom-utils";

type CopyTextHandle = {
  copy: () => Promise<void>;
  isSuccess: boolean;
  closeSuccess: () => void;
};

const useCopyText = (value: string): CopyTextHandle => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const copy = useCallback(async (): Promise<void> => {
    await copyTextToClipboard(value);
    setIsSuccess(true);
  }, [value]);

  const closeSuccess = () => setIsSuccess(false);

  return {
    copy,
    isSuccess,
    closeSuccess,
  };
};

export default useCopyText;
