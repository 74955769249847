import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M103.252 40.2939C104.139 38.3621 105.079 36.7957 105.81 37.0568C106.436 37.2656 106.593 37.9966 106.018 40.0851C105.444 42.1735 103.669 47.5513 103.669 47.5513C103.669 47.5513 106.123 58.0981 106.488 62.3272C106.801 66.5564 104.818 71.9342 98.919 73.135C93.0203 74.3359 88.9486 69.8979 86.8084 67.2351L75.3242 37.0568C77.0468 36.9001 80.4921 39.3541 85.399 46.1938C90.3059 53.0335 95.0561 59.2467 95.0561 59.2467C95.0561 59.2467 92.9681 51.5716 92.1851 47.708C91.4021 43.8965 92.0807 38.0488 92.1851 36.7957C92.2895 35.5426 93.8033 35.7515 94.2731 36.9001C94.6907 38.101 94.6385 42.8001 94.6385 42.8001C94.6385 42.8001 97.2486 34.6028 97.7706 33.1409C98.2926 31.679 98.919 31.2091 99.4932 31.4179L100.12 32.6188C100.12 32.6188 101.425 30.2693 102.312 30.687C103.199 31.1047 102.834 33.8719 102.834 33.8719C102.834 33.8719 103.826 32.7232 104.504 32.8799C105.183 32.9843 103.252 40.2939 103.252 40.2939Z"
      fill="#E1D4F8"
    />
    <path
      d="M56.5314 61.7006L55.0697 107.699C54.9131 109.787 56.5836 111.563 58.7238 111.563H78.4557C79.7608 111.563 80.8048 110.518 80.8048 109.213C80.8048 108.273 80.2828 107.438 79.3953 107.072L68.5376 102.217L70.8344 61.5439"
      fill="#E1D4F8"
    />
    <path
      d="M56.5314 61.7006L55.0697 107.699C54.9131 109.787 56.5836 111.563 58.7238 111.563H78.4557C79.7608 111.563 80.8048 110.518 80.8048 109.213C80.8048 108.273 80.2828 107.438 79.3953 107.072L68.5376 102.217L70.8344 61.5439"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.2954 61.7011L43.1162 108.587C42.803 110.362 41.2892 111.615 39.5144 111.615H18.9994C17.6944 111.615 16.6504 110.571 16.6504 109.266C16.6504 108.326 17.1724 107.491 18.0598 107.125L28.9176 102.27L38.2093 61.5967"
      fill="#E1D4F8"
    />
    <path
      d="M53.2954 61.7011L43.1162 108.587C42.803 110.362 41.2892 111.615 39.5144 111.615H18.9994C17.6944 111.615 16.6504 110.571 16.6504 109.266C16.6504 108.326 17.1724 107.491 18.0598 107.125L28.9176 102.27L38.2093 61.5967"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.0312 66.7134C46.7702 68.9063 45.3086 78.1477 43.5338 83.0034C41.7067 87.8591 41.6023 93.5502 40.9759 95.0121C40.3495 96.474 40.2451 98.0404 40.2451 98.0404"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M68.8676 98.3506H55.5994C55.3491 100.186 54.8985 104.609 55.0987 107.613C55.3491 111.368 57.3518 111.368 57.8525 111.619C58.3532 111.869 75.6268 111.869 78.3806 111.869C81.1344 111.869 81.3847 108.364 80.133 107.613C79.1316 107.012 72.0386 103.858 68.6172 102.356L68.8676 98.3506Z"
      fill="#D02F90"
    />
    <path
      d="M29.8141 98.3506H45.0851C45.3354 100.186 43.7832 104.609 43.583 107.613C43.3326 111.368 40.5496 111.368 40.0489 111.619C39.5483 111.869 22.2746 111.869 19.5208 111.869C16.7671 111.869 16.5167 108.364 17.7684 107.613C18.7698 107.012 25.8629 103.858 29.2842 102.356L29.8141 98.3506Z"
      fill="#D02F90"
    />
    <path
      d="M56.5314 61.7006L55.0697 107.699C54.9131 109.787 56.5836 111.563 58.7238 111.563H78.4557C79.7608 111.563 80.8048 110.518 80.8048 109.213C80.8048 108.273 80.2828 107.438 79.3953 107.072L68.5376 102.217L70.8344 61.5439"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.2954 61.7011L43.1162 108.587C42.803 110.362 41.2892 111.615 39.5144 111.615H18.9994C17.6944 111.615 16.6504 110.571 16.6504 109.266C16.6504 108.326 17.1724 107.491 18.0598 107.125L28.9176 102.27L38.2093 61.5967"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.6103 66.8174C40.2449 69.8457 40.5059 74.8058 40.2449 76.6332C39.9839 78.4606 37.9481 81.8543 37.3217 85.3525C36.6953 88.8507 34.6594 94.2285 34.3984 97.7267"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M30.0664 98.04H45.1003"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.5112 37.2134C30.3791 37.3178 23.1232 46.4548 16.8069 56.114C10.4906 65.7731 10.856 68.9581 12.683 71.5164C14.5101 74.0748 18.0597 75.3801 21.244 76.5809C24.4804 77.7818 28.5521 78.7216 28.5521 78.7216C28.5521 78.7216 29.0219 83.1074 29.3873 84.2038C29.7527 85.3003 29.9093 85.8224 30.4313 86.0313L31.7886 87.0233L32.676 86.2923L33.0936 86.9189C33.981 87.4932 34.5552 85.8224 35.0772 83.5251C35.5992 81.2278 36.7476 75.5889 36.7476 75.5889C36.7476 75.5889 39.9841 77.8862 40.6627 78.2517C41.3413 78.6172 42.2287 78.565 42.4897 77.6774C42.7507 76.7898 40.0885 73.8137 38.7835 72.3518C37.4784 70.8899 36.1212 69.5324 33.8766 69.0103C31.6319 68.4881 26.3075 66.9218 26.3075 66.9218L33.5112 37.2134Z"
      fill="#E1D4F8"
    />
    <path
      d="M60.2902 66.9741C60.2902 66.9741 60.2902 77.8341 60.1336 80.8102C59.977 83.7863 60.1858 87.911 59.8204 90.626C59.455 93.341 59.3506 97.779 59.3506 97.779"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M64.936 66.8174C65.0926 70.9421 64.153 76.6854 64.7794 82.3765C65.4058 88.0153 65.6668 89.8949 65.5102 92.5055C65.3536 95.1161 64.7794 97.9877 64.7794 97.9877"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M55.8008 98.1445H68.6944"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.8421 10.7947L19.3127 60.0824C17.4335 63.0585 19.5215 66.9221 23.0712 66.9221H86.182C89.6795 66.9221 91.8197 63.0585 89.9405 60.0824L58.3068 10.7947C56.5842 8.02743 52.5647 8.02743 50.8421 10.7947Z"
      fill="#6B27DD"
      stroke="#232639"
      strokeMiterlimit="10"
    />
    <path
      d="M53.1906 20.6107L30.2223 56.4801C29.5437 57.5766 30.3267 59.0385 31.6317 59.0385H77.5684C78.8735 59.0385 79.6565 57.5766 78.9779 56.4801L56.0095 20.6107C55.3309 19.6187 53.8171 19.6187 53.1906 20.6107Z"
      fill="#E1D4F8"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.9657 53.7124C55.9747 53.7124 56.7927 52.8943 56.7927 51.885C56.7927 50.8758 55.9747 50.0576 54.9657 50.0576C53.9567 50.0576 53.1387 50.8758 53.1387 51.885C53.1387 52.8943 53.9567 53.7124 54.9657 53.7124Z"
      fill="#D02F90"
      stroke="#232639"
      strokeMiterlimit="10"
    />
    <path
      d="M46.8749 40.5559C47.5382 40.5559 48.076 40.0181 48.076 39.3546C48.076 38.6912 47.5382 38.1533 46.8749 38.1533C46.2116 38.1533 45.6738 38.6912 45.6738 39.3546C45.6738 40.0181 46.2116 40.5559 46.8749 40.5559Z"
      fill="#232639"
    />
    <path
      d="M61.5956 40.5559C62.2589 40.5559 62.7967 40.0181 62.7967 39.3546C62.7967 38.6912 62.2589 38.1533 61.5956 38.1533C60.9323 38.1533 60.3945 38.6912 60.3945 39.3546C60.3945 40.0181 60.9323 40.5559 61.5956 40.5559Z"
      fill="#232639"
    />
    <path
      d="M54.2348 46.8207C54.2348 46.8207 50.9984 28.8599 54.7568 28.8599C58.1499 28.8599 55.1223 46.8207 55.1223 46.8207H54.2348Z"
      fill="#D02F90"
      stroke="#232639"
      strokeMiterlimit="10"
    />
    <path
      d="M87.5391 35.3863C87.5391 35.3863 89.6271 29.9563 95.7346 28.8599"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.9321 26.406C90.9321 26.406 95.9956 23.3777 100.224 25.7273"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.5112 37.2139C30.3791 37.3183 23.1232 46.4553 16.8069 56.1145C10.4906 65.7736 10.856 68.9585 12.683 71.5169C14.5101 74.0753 18.0597 75.3806 21.244 76.5814C24.4804 77.7823 28.5521 78.7221 28.5521 78.7221C28.5521 78.7221 29.0219 83.1079 29.3873 84.2043C29.7527 85.3008 29.9093 85.8229 30.4313 86.0317"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.1097 78.1475C31.1097 78.1475 30.3789 85.7181 31.1619 86.6057C31.9971 87.4933 32.7801 86.2403 32.7801 86.2403"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.4069 77.5209C33.2503 79.3483 32.2063 86.3446 33.0937 86.9712C33.9811 87.5455 34.5554 85.8747 35.0774 83.5774C35.5994 81.2801 36.7478 75.6412 36.7478 75.6412C36.7478 75.6412 39.9842 77.9386 40.6629 78.304C41.3415 78.6695 42.2289 78.6173 42.4899 77.7297C42.7509 76.8421 40.0886 73.866 38.7836 72.4041C37.4786 70.9422 36.1214 69.5847 33.8767 69.0626C31.6321 68.5405 26.3076 66.9741 26.3076 66.9741"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.2715 37.0571C76.9941 36.9004 80.4394 39.3544 85.3463 46.1941C90.2531 53.0338 95.0034 59.247 95.0034 59.247C95.0034 59.247 92.9154 51.5719 92.1324 47.7082C91.3494 43.8968 92.028 38.0491 92.1324 36.796C92.2368 35.5429 93.7506 35.7518 94.2204 36.9004C94.6902 38.1013 94.638 42.8003 94.638 42.8003C94.638 42.8003 97.2481 34.6031 97.7701 33.1412C98.2921 31.6793 98.9185 31.2094 99.4927 31.4182C100.119 31.5748 100.38 32.8801 99.5971 35.6473C98.8141 38.4146 98.2921 40.242 98.2921 40.242"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.955 41.0253C101.216 39.9811 102.938 33.8723 103.095 31.7839C103.147 31.0529 102.573 30.5308 102.155 30.5308C101.425 30.4786 100.485 31.4706 100.12 32.9847"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.782 41.7039C103.043 41.0251 103.93 38.4146 104.452 36.2739C104.817 34.7597 105.078 33.7155 104.87 33.2456C104.4 32.2014 103.095 32.9846 102.677 34.2898"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.7559 67.2353C88.9483 69.8981 92.9678 74.336 98.8665 73.1352C104.765 71.9343 106.801 66.5565 106.436 62.3274C106.122 58.0982 103.617 47.5515 103.617 47.5515C103.617 47.5515 105.339 42.1737 105.966 40.0852C106.592 37.9968 106.436 37.318 105.757 37.0569C104.974 36.7959 104.504 37.2866 103.617 39.2184"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.6629 74.6494C40.1931 75.1193 39.7233 76.3202 42.0723 78.0432"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.0059 83.8388C31.4757 83.7344 32.2065 84.2043 31.7889 87.0237"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.833 83.682C33.6682 83.5775 34.1902 84.3085 33.6682 86.658"
      stroke="#232639"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
