import React from "react";

import { Skeleton } from "@mui/material";

type SkeletonWrapperProps = {
  isLoading: boolean;
  skeletonVariant?: "text" | "circular" | "rectangular";
  skeletonWidth?: number | string;
};

const SkeletonWrapper: React.FC<SkeletonWrapperProps> = ({
  isLoading,
  skeletonVariant,
  skeletonWidth,
  children,
}) => {
  if (isLoading) {
    return (
      <Skeleton variant={skeletonVariant} width={skeletonWidth}>
        {children}
      </Skeleton>
    );
  }

  return <>{children}</>;
};

SkeletonWrapper.defaultProps = {
  skeletonVariant: "text",
  skeletonWidth: undefined,
};

export default SkeletonWrapper;
