import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Environment = "live" | "sandbox";

type BusinessAcccountEnvironmentState = {
  businessAccountId: string | null;
  environment: Environment | null;
};

const initialState: BusinessAcccountEnvironmentState = {
  businessAccountId: null,
  environment: null,
};

const slice = createSlice({
  name: "businessAccountEnvironment",
  initialState,
  reducers: {
    setBusinessAccountId: (state, action: PayloadAction<string | null>) => {
      state.businessAccountId = action.payload;
    },
    setEnvironment: (state, action: PayloadAction<Environment | null>) => {
      state.environment = action.payload;
    },
  },
});

export const { setBusinessAccountId, setEnvironment } = slice.actions;
export default slice.reducer;
export type { Environment };
