import type {
  CurrentUserResponseData,
  PatchCurrentUserPayload,
} from "../types/current-user-types";
import userApiClient from "../userApiClient";

const patchCurrentUser = async (
  payload: PatchCurrentUserPayload
): Promise<CurrentUserResponseData> =>
  userApiClient.patch<CurrentUserResponseData>("/current", payload);

export default patchCurrentUser;
