import React from "react";

import { Box, Typography } from "@mui/material";

import ModalCloseButton from "./ModalCloseButton";

type ModalTitlebarProps = {
  variant?: "h2" | "h3";
  titleId?: string;
};

const ModalTitlebar: React.FC<ModalTitlebarProps> = ({
  variant,
  titleId,
  children,
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    }}
  >
    <Typography id={titleId} variant={variant} sx={{ flexGrow: 1 }}>
      {children}
    </Typography>
    <ModalCloseButton />
  </Box>
);

ModalTitlebar.defaultProps = {
  variant: "h2",
  titleId: undefined,
};

export default ModalTitlebar;
