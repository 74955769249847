import React from "react";
import { useNavigate } from "react-router-dom";

import { TableRow } from "@mui/material";

import ViewDetailsCell from "./ViewDetailsCell";

type DetailsRowProps = {
  href?: string;
  onClick?: () => void;
  isMuted?: boolean;
};

const DetailsRow: React.FC<DetailsRowProps> = ({
  href,
  onClick,
  isMuted,
  children,
}) => {
  const navigate = useNavigate();

  const handleRowClick = (): void => {
    if (href) {
      navigate(href);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <TableRow
      hover
      onClick={handleRowClick}
      sx={
        isMuted
          ? {
              td: {
                color: "grey.400",
              },
            }
          : undefined
      }
    >
      {children}
      <ViewDetailsCell href={href} onClick={onClick} />
    </TableRow>
  );
};

DetailsRow.defaultProps = {
  href: undefined,
  onClick: () => {},
  isMuted: false,
};

export default DetailsRow;
