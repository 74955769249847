import React from "react";

import { css, Global } from "@emotion/react";
import { CssBaseline, useTheme } from "@mui/material";

import { measureScrollbarWidth } from "~src/utils/dom-utils";

const GlobalStyles: React.VFC = () => {
  const { palette, spacing } = useTheme();

  return (
    <>
      <CssBaseline />
      <Global
        styles={css`
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          :root {
            --color-base-bg: ${palette.grey[100]};
            --color-main-bg: ${palette.background.default};

            --color-accent-main-live: ${palette.primary.main};
            --color-accent-mid-live: ${palette.primary.light};
            --color-accent-light-live: ${palette.primary.light};

            --color-accent-main-sandbox: ${palette.tertiary.main};
            --color-accent-mid-sandbox: ${palette.tints?.purple?.light};
            --color-accent-light-sandbox: ${palette.tertiary.light};

            --color-accent-main: var(--color-accent-main-live);
            --color-accent-mid: var(--color-accent-mid-live);
            --color-accent-light: var(--color-accent-light-live);

            --color-hover-state-actionbar: ${palette.grey[200]};

            --active-state-opacity: 0.75;

            --page-max-width: 1440px;
            --appbar-height: 64px;
            --mobile-drawer-gutter: ${spacing(4)};
            --desktop-nav-width: 300px;
            --scrollbar-width: ${measureScrollbarWidth()}px;
          }

          html,
          body,
          #root {
            height: 100%;
            overscroll-behavior-x: none;
          }

          body {
            background-color: var(--color-base-bg);
          }

          #root {
            overflow-x: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          button {
            appearance: none;
            background: none;
            border: none;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
          }
          button:not([disabled]) {
            cursor: pointer;
          }

          a:active,
          button:not([disabled]):active {
            opacity: var(--active-state-opacity);
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
