import React, { forwardRef, ReactNode } from "react";

import { Box, Stack } from "@mui/material";

type PageHeaderProps = {
  children?: ReactNode;
  corner?: ReactNode;
};

const PageHeader = forwardRef<HTMLElement, PageHeaderProps>(
  ({ corner, children }, ref) => (
    <Box
      ref={ref}
      component="header"
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Stack spacing={4} sx={{ flexGrow: 1 }}>
        {children}
      </Stack>
      {corner}
    </Box>
  )
);

PageHeader.defaultProps = {
  children: null,
  corner: null,
};

PageHeader.displayName = "PageHeader";

export default PageHeader;
