import * as Sentry from "@sentry/react";

import { ErrorResponseData } from "../services/types/error-handling-types";
import { AppEnvironment } from "../utils/config-utils";

let isSentryInitialized = false;
const nonProductionEnvs = ["development", "staging"];

const initializeSentry = (dsn: string, environment: AppEnvironment): void => {
  Sentry.init({
    dsn,
    allowUrls: [
      /getcatch\.com/,
      ...(nonProductionEnvs.includes(environment) ? [/localhost/] : []),
    ],
    beforeSend: (event, hint) => {
      const error = hint?.originalException;
      // Do not modify normal errors thrown by the client
      if (error instanceof Error || typeof error === "string" || !error) {
        return event;
      }

      // If the original exception is not any of the above types, it might include our ErrorResponseData type
      // This data munging is currently necessary because restful-react does not raise exceptions as instances of
      // the Error class. As a result, Sentry captures these errors and displays a truly awful event message:
      // "Non-Error promise rejection captured with keys: data, message, status". If in the future restful-react changes
      // their implementation to raise Error subclasses, we will not need the below code anymore since Sentry will
      // recognize the exceptions as Errors and format them correctly.
      const castError = error as {
        data: ErrorResponseData;
        message: string;
        status: number;
      };
      if (castError.data && castError.message && castError.status) {
        const message = castError.data?.message || castError.message;
        // Change Sentry event message to the error message we are getting from the backend
        const exceptionValues = event.exception?.values || [];
        const exception = exceptionValues[0];
        if (exception) {
          exception.value = `Service Error - ${message}`;
        }
      }

      return event;
    },
    ignoreErrors: [
      // We typically see "Failed to fetch" errors when there's some extension or weird
      // browser interference that messes up our fetch() call. There isn't anything
      // actionable when this happens, and it should be ok to ignore these. Actual
      // service errors (i.e. 500s) should still be alerted on our backend.
      "Failed to fetch:",
      // This issue is not caused by our app and there isn't any action for us to
      // take if a request has been aborted.
      "AbortError: The user aborted a request.",
      // We've found that SyntaxError errors are always coming from external code and
      // create noise. If there's an actual syntax error in our own code, it's unlikely
      // it would have made it through CI.
      "SyntaxError",
      // Random plugins/extensions/browsers
      "top.GLOBALS",
      "UnhandledRejection: Non-Error promise rejection captured with value:",
      /TypeError:.*/,
      "QuotaExceededError: The quota has been exceeded.",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      "Can't find variable: PaymentAutofillConfig",
      "Identifier 'windowOpenFn' has already been declared",

      // Mysterious reference errors that appear from third party scripts. Our apps are unlikely
      // to have these errors from their own code provided we're using Typescript in a responsible
      // manner (i.e. no `any`, etc).
      // https://sentry.io/organizations/catch-inc/issues/3417364627/?project=6194244
      /ReferenceError: .+ is not defined$/,

      // Seems likely related to a Metamask extension.
      // https://sentry.io/organizations/catch-inc/issues/3256660907/?project=6194244
      "chain is not set up",

      // Noise from Google Ad JavaScript.
      // https://sentry.io/organizations/catch-inc/issues/3718971055/activity/?project=6194244
      "Unexpected identifier 'link'",

      // Unclear the cause, believed to be noise.
      // https://sentry.io/organizations/catch-inc/issues/3817820999/activity/?project=6194244&referrer=slack
      "Can't find variable: __AutoFillPopupClose__",

      // Unclear the cause, believed to be noise.
      // https://catch-inc.sentry.io/issues/3620190090/?project=6194244
      "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",

      // Noise from Microsoft edge on iOS
      "ReferenceError: Can't find variable: msDiscoverChatAvailable",

      // A large source of noise from errors on merchants' websites that don't affect their Catch integration
      // https://get-catch.slack.com/archives/C036RF4KMQR/p1693682419100299
      // https://catch-inc.sentry.io/issues/4449057493/?project=6194244
      "SecurityError",
    ],
    denyUrls: [
      // Chrome extensions
      /extension\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    environment,
  });

  isSentryInitialized = true;
};

class SentryException extends Error {
  constructor(
    exceptionMessage: string,
    rawError: unknown,
    additionalData?: Record<string, unknown>
  ) {
    const errorData = rawError as Error;

    const message = errorData.message || exceptionMessage;

    super(message);

    this.name = exceptionMessage;
    // Make sure the associated stack trace persists into Sentry
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryException);
    }

    Sentry.addBreadcrumb({
      type: "error",
      level: Sentry.Severity.Error,
      message,
      data: { rawError, ...additionalData },
    });
  }
}

const getSentryInitState = () => isSentryInitialized;

export { getSentryInitState, initializeSentry, SentryException };
