import { Components, Theme } from "@mui/material";

const buildAccordion = (theme: Theme): Components["MuiAccordion"] => {
  const { palette, spacing, shape } = theme;
  return {
    styleOverrides: {
      root: {
        "&:before": {
          display: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
        },
        "&.MuiPaper-default": {
          borderBottom: `1px solid ${palette.grey[300]}`,
          boxShadow: "none",
          minHeight: "0px",
          ".MuiAccordionSummary-root": {
            padding: spacing(0, 0, 6),
          },
          ".MuiAccordionDetails-root": {
            padding: spacing(0, 0, 6),
          },
        },
        "&.MuiPaper-card": {
          padding: 0,
          margin: 0,
          borderRadius: `${shape.borderRadius * 2}px`,
          border: `1px solid ${palette.grey[300]}`,
          "&.Mui-expanded": {
            border: "none",
            backgroundColor: palette.grey[200],
          },
          ".MuiAccordionSummary-root": {
            padding: spacing(4),
            "&.Mui-expanded": {
              padding: spacing(4, 4, 6),
              cursor: "default",
            },
          },
          ".MuiAccordionDetails-root": {
            padding: spacing(4),
            paddingTop: 0,
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          color: palette.grey[600],
          ".MuiSvgIcon-fontSizeMedium": {
            fontSize: "24px",
          },
        },
        ".MuiAccordionSummary-root": {
          alignItems: "flex-start",
          minHeight: 0,
          "&.Mui-expanded": {
            minHeight: 0,
          },
        },
        ".MuiAccordionSummary-content": {
          margin: 0,
        },
        ".MuiAccordionDetails-root": {
          margin: 0,
        },
        ".MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
      },
    },
  };
};

export default buildAccordion;
