import React, { useEffect } from "react";

import {
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";

import useCopyText from "~src/hooks/lib/useCopyText";

type CopyTextButtonVariant = "regular" | "small" | "large";

type CopyTextButtonProps = {
  value: string;
  variant?: CopyTextButtonVariant;
  onCopySuccess?: () => void;
};

const CopyTextButton: React.FC<CopyTextButtonProps> = ({
  value,
  variant,
  onCopySuccess,
  children,
}) => {
  const { copy, isSuccess, closeSuccess } = useCopyText(value);

  useEffect(() => {
    let timer: number;

    if (isSuccess) {
      timer = window.setTimeout(() => closeSuccess(), 3000);
    }

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [isSuccess, closeSuccess]);

  let typographyVariant = "linkRegular";
  if (variant === "small") {
    typographyVariant = "linkSmall";
  } else if (variant === "large") {
    typographyVariant = "linkLarge";
  }

  return (
    <>
      <Box
        className="copy-text-button"
        component="button"
        type="button"
        onClick={() => {
          void copy().then(() => onCopySuccess && onCopySuccess());
        }}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          typography: typographyVariant,

          ".MuiSvgIcon-root": {
            ml: 1,
          },
        }}
      >
        {children}
        {isSuccess ? (
          <CheckIcon
            fontSize="inherit"
            sx={{
              "&, .MuiTextField-root .MuiInputAdornment-root &": {
                color: "secondary.main",
              },
            }}
          />
        ) : (
          <ContentCopyIcon fontSize="inherit" />
        )}
      </Box>
    </>
  );
};

CopyTextButton.defaultProps = {
  variant: "regular",
  onCopySuccess: undefined,
};

export default CopyTextButton;
export type { CopyTextButtonVariant };
