import React, { forwardRef } from "react";

import { IconButton, IconButtonProps } from "@mui/material";

const WrappedIconButton: React.FC<IconButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <IconButton ref={ref} {...props}>
      {children}
    </IconButton>
  )
);

WrappedIconButton.displayName = "WrappedIconButton";

export default WrappedIconButton;
