import React, { ChangeEvent, ReactNode, useState } from "react";

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";

type PasswordFieldProps = {
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: ReactNode;
  autoFocus?: boolean;
};

const PasswordField: React.VFC<PasswordFieldProps> = ({
  value,
  onChange,
  label,
  error,
  helperText,
  autoFocus,
}) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      type={isShowPassword ? "text" : "password"}
      error={error}
      helperText={helperText}
      FormHelperTextProps={{
        sx: {
          ".password-strength-helper": {
            ml: "-14px",
            mr: "-14px",
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box
              component="button"
              type="button"
              aria-label="Toggle password visibility"
              onClick={() => setIsShowPassword((current) => !current)}
              sx={{
                lineHeight: 0,
                ".MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              }}
            >
              {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordField.defaultProps = {
  error: false,
  helperText: undefined,
  autoFocus: undefined,
};

export default PasswordField;
