import React from "react";

import { TableCell, TableRow } from "@mui/material";

import { VisuallyHiddenText } from "~src/components/lib/utils";

const DetailsHeaderRow: React.FC = ({ children }) => (
  <TableRow>
    {children}
    <TableCell
      sx={{
        ".MuiTable-root &": {
          padding: "0",
        },
      }}
    >
      <VisuallyHiddenText>Details</VisuallyHiddenText>
    </TableCell>
  </TableRow>
);

export default DetailsHeaderRow;
