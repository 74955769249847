import React, { ReactNode } from "react";

import { Box, Stack, Typography } from "@mui/material";

type ConfirmActionPromptProps = {
  prompt: ReactNode;
  variant?: "regular" | "small";
};

const ConfirmActionPrompt: React.FC<ConfirmActionPromptProps> = ({
  prompt,
  variant,
  children,
}) => (
  <Stack spacing={4}>
    <Typography
      variant={variant === "small" ? "bodySmall" : "bodyRegular"}
      component="p"
    >
      {prompt}
    </Typography>
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 4,
      }}
    >
      {children}
    </Box>
  </Stack>
);

ConfirmActionPrompt.defaultProps = {
  variant: "regular",
};

export default ConfirmActionPrompt;
