import React from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { AccentAwareLoadingBar } from "~src/components/lib/loading-states";
import useRouteConfigHandle from "~src/hooks/useRouteConfigHandle";
import { selectCoreIsAuthenticated } from "~src/store";

// Render an AccentAwareLoadingBar across the top of the page while content is loading.
// We use this, instead of AccentAwareLoadingBar directly, in cases where we need the
// loading bar to extend to the full viewport width (even if wider than the page max width).

const TopLoading: React.VFC = () => {
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const { isDialogPage } = useRouteConfigHandle();

  return (
    <Box
      component="span"
      sx={{
        position: "fixed",
        // The top offset, based on whether or not the appbar is being shown on the page,
        // is determined using the same logic that's in <AppbarRenderer />.
        top:
          isAuthenticated && !isDialogPage
            ? "calc(var(--appbar-height) + 1px)"
            : 0,
        left: 0,
        right: 0,
      }}
    >
      <AccentAwareLoadingBar />
    </Box>
  );
};

export default TopLoading;
