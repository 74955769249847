import React from "react";

import { Stack } from "@mui/material";

import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { useTracking } from "~common/tracking";

import EnvironmentToggle from "./EnvironmentToggle";
import NavSubheading from "./NavSubheading";

const NavEnvironmentSection: React.VFC = () => {
  const { trackEvent } = useTracking();
  const headingId = useUniqueId("environment");

  return (
    <Stack spacing={2}>
      <NavSubheading
        id={headingId}
        helperText="Live shows data from real consumer usage, while Sandbox shows data from tests."
        onHelpButtonClick={() =>
          trackEvent("Environment tooltip button clicked")
        }
      >
        Environment
      </NavSubheading>
      <EnvironmentToggle ariaLabelledBy={headingId} />
    </Stack>
  );
};

export default NavEnvironmentSection;
