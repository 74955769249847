import React from "react";

import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

type ViewMoreButtonProps = {
  onClick?: () => void;
  isLoading?: boolean;
};

const ViewMoreButton: React.VFC<ViewMoreButtonProps> = ({
  onClick,
  isLoading,
}) => (
  <Box
    component="button"
    type="button"
    onClick={onClick}
    disabled={isLoading ? true : undefined}
    sx={({ palette }) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      py: 3,
      px: 9,
      typography: "buttonSmall",
      color: "grey.500",
      borderStyle: "solid",
      borderColor: "divider",
      borderWidth: "1px 0",

      ".results-table-view &": {
        borderTop: "none",
      },

      "&:hover": {
        backgroundColor: "var(--color-hover-state-actionbar)",
      },

      "&[disabled] span, &[disabled] .MuiSvgIcon-root": {
        opacity: palette.action.disabledOpacity,
        pointerEvents: "none",
      },
    })}
  >
    <span>View more</span>
    <KeyboardArrowDownIcon />
  </Box>
);

export default ViewMoreButton;
