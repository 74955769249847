import React, { useRef, useState } from "react";
import { useIntercom } from "react-use-intercom";

import {
  AccountBox as AccountBoxIcon,
  ArrowDropDown as ArrowDropDownIcon,
  HelpOutline as HelpOutlineIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { Avatar, Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { useTracking } from "~common/tracking";
import {
  DropMenu,
  DropMenuDivider,
  DropMenuItem,
} from "~src/components/lib/drop-menu";
import { SkeletonWrapper } from "~src/components/lib/loading-states";
import useCurrentUserHandle from "~src/hooks/useCurrentUserHandle";
import logout from "~src/services/mutations/logout";

const UserTools: React.VFC = () => {
  const intercom = useIntercom();
  const { trackEvent } = useTracking();
  const { isLoading, data } = useCurrentUserHandle();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonId = useUniqueId("user-tools-btn");
  const dropMenuId = useUniqueId("user-tools-menu");

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess() {
      intercom.shutdown();
      window.location.replace("/");
    },
  });

  const getInitials = (): string => {
    if (data) {
      return `${data.first_name[0]}${data.last_name[0]}`.toUpperCase();
    }
    return "";
  };

  return (
    <>
      <Box
        id={buttonId}
        ref={buttonRef}
        component="button"
        type="button"
        aria-controls={isMenuOpen ? dropMenuId : undefined}
        aria-expanded={isMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => !isMenuOpen && setIsMenuOpen(true)}
        sx={({ palette }) => ({
          "--color-fill": isMenuOpen ? palette.grey[500] : palette.grey[400],
          display: "flex",
          alignItems: "center",
          // Set the color here so that <ArrowDropDownIcon />
          // can inherit it. The <Avatar /> will override this to white.
          color: "var(--color-fill)",
        })}
      >
        <SkeletonWrapper isLoading={isLoading} skeletonVariant="circular">
          <Avatar
            sx={{
              width: 32,
              height: 32,
              fontWeight: 700,
              fontSize: "0.875rem",
              backgroundColor: "var(--color-fill)",
            }}
          >
            {getInitials()}
          </Avatar>
        </SkeletonWrapper>

        <ArrowDropDownIcon />
      </Box>

      <DropMenu
        id={dropMenuId}
        triggerEl={buttonRef.current}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        closeOnItemClick
      >
        <DropMenuItem href="/account" icon={<AccountBoxIcon />}>
          Account
        </DropMenuItem>

        <DropMenuItem href="/help" icon={<HelpOutlineIcon />}>
          Get help
        </DropMenuItem>
        <DropMenuItem
          icon={<LogoutIcon />}
          onClick={() => {
            trackEvent("Logout menu item clicked");
            logoutMutation.mutate();
          }}
        >
          Log out
        </DropMenuItem>

        <DropMenuDivider />

        <DropMenuItem
          href="https://www.getcatch.com/terms"
          isMuted
          onClick={() => trackEvent("Supplemental terms menu item clicked")}
        >
          Supplemental terms
        </DropMenuItem>
        <DropMenuItem
          href="https://www.getcatch.com/privacy"
          isMuted
          onClick={() => trackEvent("Privacy notice menu item clicked")}
        >
          Privacy notice
        </DropMenuItem>
      </DropMenu>
    </>
  );
};

export default UserTools;
