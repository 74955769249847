import React from "react";

import { Box } from "@mui/material";

type ColumnsContainerProps = {
  variant?: "default" | "compact";
};

const ColumnsContainer: React.FC<ColumnsContainerProps> = ({
  variant,
  children,
}) => (
  <Box
    sx={({ breakpoints }) => ({
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: variant === "compact" ? 12 : 24,

      // Prevent columns from being blown out by wide content.
      "& > *": {
        minWidth: 0,
      },

      [breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: variant === "compact" ? 6 : 12,
      },
    })}
  >
    {children}
  </Box>
);

ColumnsContainer.defaultProps = {
  variant: "default",
};

export default ColumnsContainer;
