import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

import { VisuallyHiddenText } from "~src/components/lib/utils";
import { selectCoreIsMobileNavOpen } from "~src/store";
import { toggleMobileNav } from "~src/store/slices/core-slice";

const MobileNavMenuButton: React.VFC = () => {
  const isMobileNavOpen = useSelector(selectCoreIsMobileNavOpen);
  const dispatch = useDispatch();

  return (
    <Box
      component="button"
      className="mobile-nav-menu-button"
      onClick={() => dispatch(toggleMobileNav())}
      sx={({ breakpoints }) => ({
        lineHeight: 0,

        [breakpoints.up("md")]: {
          display: "none",
        },
      })}
    >
      {isMobileNavOpen ? <CloseIcon /> : <MenuIcon />}
      <VisuallyHiddenText>Toggle navigation</VisuallyHiddenText>
    </Box>
  );
};

export default MobileNavMenuButton;
