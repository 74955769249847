import { Components, Theme } from "@mui/material";

const buildIconButton = (theme: Theme): Components["MuiIconButton"] => {
  const { palette } = theme;
  return {
    defaultProps: {
      color: "inherit",
      size: "medium",
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          background: "none",
        },
        "&.Mui-focusVisible": {
          outline: "2px solid",
          outlineColor: palette.info.main,
        },
        color: palette.grey[700],
        path: {
          color: "inherit",
        },
        "&.MuiIconButton-colorRegularButtonBackground": {
          color: palette.regularButtonBackground,
          path: {
            color: "inherit",
          },
        },
        "&.MuiIconButton-sizeMedium": {
          height: "48px",
          width: "48px",
          padding: "12px",
          margin: "-12px",
          ".MuiSvgIcon-root": {
            fontSize: "24px",
          },
        },
        "&.MuiIconButton-sizeSmall": {
          height: "40px",
          width: "40px",
          padding: "12px",
          margin: "-12px",
          ".MuiSvgIcon-root": {
            fontSize: "16px",
          },
        },
      },
    },
  };
};

export default buildIconButton;
