import React from "react";
import { IntercomProvider as ReactUseIntercomProvider } from "react-use-intercom";

import { INTERCOM_APP_ID } from "~src/config";

const B2BIntercomProvider: React.FC = ({ children }) => (
  // Booting is managed manually by <App />.
  <ReactUseIntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
    {children}
  </ReactUseIntercomProvider>
);

export default B2BIntercomProvider;
