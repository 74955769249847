import { useEffect, useState } from "react";

// *useDebounce* implementation is based on (AKA lovingly taken from) useHooks.
// See https://usehooks.com/usedebounce.

const useDebounce = <T>(value: T, delay = 300): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => window.clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
