import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Box, Skeleton, Typography } from "@mui/material";

import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { useTracking } from "~common/tracking";
import { DropMenu, DropMenuItem } from "~src/components/lib/drop-menu";
import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";

const NavBusinessAccountHeader: React.VFC = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const {
    isLoading,
    activeRoleInfo,
    availableRoleInfos,
    activateBusinessAccount,
  } = useBusinessAccountHandle();
  const hasMultipleRoles = availableRoleInfos && availableRoleInfos.length > 1;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonId = useUniqueId("business-account-menu-btn");
  const dropMenuId = useUniqueId("business-account-menu");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography variant="h2" component="h1">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>{activeRoleInfo?.business_account.merchant.name}</>
          )}
        </Typography>

        {hasMultipleRoles && (
          <Box
            id={buttonId}
            ref={buttonRef}
            component="button"
            type="button"
            aria-label="Switch business account"
            aria-controls={isMenuOpen ? dropMenuId : undefined}
            aria-expanded={isMenuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={() => !isMenuOpen && setIsMenuOpen(true)}
            sx={{
              ml: 2,
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "grey.300",
              borderRadius: "50%",

              ".MuiSvgIcon-root": {
                color: "grey.600",
              },
            }}
          >
            <ArrowDropDownIcon />
          </Box>
        )}
      </Box>

      {hasMultipleRoles && (
        <DropMenu
          id={dropMenuId}
          triggerEl={buttonRef.current}
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          closeOnItemClick
          variant="large"
        >
          {availableRoleInfos
            .filter(
              ({ business_account }) =>
                business_account.id !== activeRoleInfo?.business_account.id
            )
            .map(({ business_account }) => (
              <DropMenuItem
                key={business_account.id}
                onClick={() => {
                  const { id } = business_account;
                  trackEvent("Select business account menu item clicked", {
                    selectedBusinessAccountId: id,
                  });
                  activateBusinessAccount(id);
                  navigate("/");
                }}
              >
                {business_account.merchant.name}
              </DropMenuItem>
            ))}
        </DropMenu>
      )}
    </>
  );
};

export default NavBusinessAccountHeader;
