import { Components, Theme } from "@mui/material";

const buildDrawer = (theme: Theme): Components["MuiDrawer"] => {
  const { spacing } = theme;

  return {
    styleOverrides: {
      root: {
        ".MuiDrawer-paper": {
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          maxHeight: `calc(100vh - ${spacing(16)})`,
        },
        ".MuiDrawer-paperAnchorTop": {
          borderBottomRightRadius: "16px",
          borderBottomLeftRadius: "16px",
        },
        ".MuiDrawer-paperAnchorBottom": {
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
        },
      },
    },
  };
};

export default buildDrawer;
