import React from "react";

import { Typography } from "@mui/material";

type ParagraphProps = {
  isMuted?: boolean;
};

const Paragraph: React.FC<ParagraphProps> = ({ isMuted, children }) => (
  <Typography
    variant="bodyRegular"
    paragraph
    sx={{
      color: isMuted ? "grey.500" : "grey.600",
      a: {
        typography: "linkRegular",
      },
    }}
  >
    {children}
  </Typography>
);

Paragraph.defaultProps = {
  isMuted: false,
};

export default Paragraph;
