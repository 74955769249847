import React from "react";

import { Box } from "@mui/material";

const TextFieldPair: React.FC = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",

      ".MuiTextField-root:first-of-type": {
        ".MuiInputBase-root": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
      ".MuiTextField-root:last-of-type": {
        marginLeft: "-1px",

        ".MuiInputBase-root": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
    }}
  >
    {children}
  </Box>
);

export default TextFieldPair;
