import type { ErrorResponseData } from "./types/error-handling-types";

class UserApiError extends Error {
  error_type: string;

  status: number;

  constructor(message: string, error_type: string, status: number) {
    super(message);
    this.error_type = error_type;
    this.status = status;
  }
}

let authToken: string | null = null;

const setAuthToken = (newAuthToken: string | null): void => {
  authToken = newAuthToken;
};

const payloadToQuerystring = (payload: Record<string, unknown>): string => {
  // Prune *undefined* values from the payload before serializing it to a querystring.
  // (We want undefined values to be omitted entirely from the querystring, rather than
  // serialized to the string "undefined").
  const pruned: Record<string, unknown> = {};
  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    if (value !== undefined) {
      pruned[key] = value;
    }
  });

  // The URLSearchParams constructor wants a Record<string, string>, not
  // a Record<string, unknown>. In reality, the values in the pruned payload can
  // be non-string primitives as long as they're serializable.
  const params = new URLSearchParams(pruned as Record<string, string>);
  return `?${params.toString()}`;
};

const callUserApi = async <TResponseData>(
  path: string,
  payload?: Record<string, unknown>,
  requestOptions: RequestInit = {}
): Promise<TResponseData> => {
  if (!requestOptions.headers) {
    requestOptions.headers = {};
  }

  if (payload) {
    const { method } = requestOptions;
    if (method === "POST" || method === "PUT" || method === "PATCH") {
      Object.assign(requestOptions.headers, {
        "Content-Type": "application/json",
      });

      requestOptions.body = JSON.stringify(payload);
    } else if (method === "GET") {
      path += payloadToQuerystring(payload);
    }
  }

  if (authToken) {
    Object.assign(requestOptions.headers, {
      Authorization: authToken,
    });
  }

  const response = await fetch(
    `/api/business-portal-svc/user${path}`,
    requestOptions
  );

  if (response.ok) {
    return response.json() as Promise<TResponseData>;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { message, error_type } = (await response.json()) as ErrorResponseData;
  throw new UserApiError(message, error_type, response.status);
};

const get = async <TResponseData>(
  path: string,
  payload?: Record<string, unknown>,
  requestOptions: RequestInit = {}
): Promise<TResponseData> => {
  requestOptions.method = "GET";
  return callUserApi<TResponseData>(path, payload, requestOptions);
};

const post = async <TResponseData = unknown>(
  path: string,
  payload?: Record<string, unknown>,
  requestOptions: RequestInit = {}
): Promise<TResponseData> => {
  requestOptions.method = "POST";
  return callUserApi<TResponseData>(path, payload, requestOptions);
};

const put = async <TResponseData = unknown>(
  path: string,
  payload?: Record<string, unknown>,
  requestOptions: RequestInit = {}
): Promise<TResponseData> => {
  requestOptions.method = "PUT";
  return callUserApi<TResponseData>(path, payload, requestOptions);
};

const patch = async <TResponseData = unknown>(
  path: string,
  payload?: Record<string, unknown>,
  requestOptions: RequestInit = {}
): Promise<TResponseData> => {
  requestOptions.method = "PATCH";
  return callUserApi<TResponseData>(path, payload, requestOptions);
};

const userApiClient = {
  setAuthToken,
  get,
  post,
  put,
  patch,
};

export default userApiClient;
export { UserApiError };
