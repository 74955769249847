import React, { forwardRef } from "react";

import DefaultButton from "./DefaultButton";
import { WrappedButtonProps } from "./WrappedButton";

const CompactButton: React.FC<WrappedButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <DefaultButton {...props} ref={ref} size="small">
      {children}
    </DefaultButton>
  )
);

CompactButton.displayName = "CompactButton";

export default CompactButton;
