import React, { KeyboardEvent, ReactNode, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Box, Typography } from "@mui/material";

type DropMenuItemProps = {
  onClick?: () => void;
  href?: string;
  autoFocus?: boolean;
  icon?: ReactNode;
  isMuted?: boolean;
};

const DropMenuItem: React.FC<DropMenuItemProps> = ({
  onClick,
  href,
  autoFocus,
  icon,
  isMuted,
  children,
}) => {
  const itemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (autoFocus) {
      itemRef.current?.focus();
    }
  }, [autoFocus]);

  const baseProps = {
    ref: itemRef,
    className: `drop-menu-item ${isMuted ? "muted" : ""}`,
    role: "menuitem",
    tabIndex: autoFocus ? 0 : -1,
    onClick: () => onClick && onClick(),
  };

  const content = (
    <>
      <Typography variant="inherit" component="span" noWrap>
        {children}
      </Typography>

      {icon}
    </>
  );

  if (href) {
    const isExternal = href.startsWith("http");

    if (isExternal) {
      return (
        <a {...baseProps} href={href} target="_blank" rel="noreferrer">
          {content}
        </a>
      );
    }

    return (
      <Link {...baseProps} to={href}>
        {content}
      </Link>
    );
  }

  return (
    <Box
      {...baseProps}
      component="div"
      onKeyDown={(event: KeyboardEvent) => {
        if (event.code === "Enter" && onClick) {
          onClick();
        }
      }}
    >
      {content}
    </Box>
  );
};

DropMenuItem.defaultProps = {
  onClick: undefined,
  href: undefined,
  autoFocus: false,
  icon: null,
  isMuted: false,
};

export default DropMenuItem;
