import React from "react";

import { Box } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// Render text that is visually hidden, while still accessible to screen readers.

const VisuallyHiddenText: React.FC = ({ children }) => (
  <Box component="span" sx={visuallyHidden}>
    {children}
  </Box>
);

export default VisuallyHiddenText;
