import React from "react";

import { Box } from "@mui/material";

const NoResults: React.FC = ({ children }) => (
  <Box
    sx={{
      py: 3,
      px: 4,
      typography: "bodySmall",
      textAlign: "center",
      color: "grey.600",
      borderBottom: "1px solid",
      borderBottomColor: "divider",
    }}
  >
    {children ? <>{children}</> : "No results"}
  </Box>
);

export default NoResults;
