import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Breadcrumbs } from "@mui/material";

import { TextButtonSmall } from "~common/components/controls/buttons";

type Crumb = {
  label: ReactNode;
  href?: string;
  onClick?: () => void;
};

type PageBreadcrumbsProps = {
  crumbs: Crumb[];
  current: ReactNode;
};

const PageBreadcrumbs: React.VFC<PageBreadcrumbsProps> = ({
  crumbs,
  current,
}) => (
  <Breadcrumbs aria-label="Breadcrumb">
    {crumbs.map(({ label, href, onClick }, index) => {
      if (href) {
        return (
          <Link key={href} to={href}>
            {label}
          </Link>
        );
      }

      return (
        // The crumbs list in breadcrumbs should always be static,
        // so we're okay using the index for the key. ¯\_(ツ)_/¯
        // eslint-disable-next-line react/no-array-index-key
        <TextButtonSmall key={index} onClick={onClick}>
          {label}
        </TextButtonSmall>
      );
    })}
    <span>{current}</span>
  </Breadcrumbs>
);

export default PageBreadcrumbs;
export type { Crumb };
