import React from "react";

import { Box } from "@mui/material";

import { CatchLogo } from "~common/components/logos";

const AppLogo: React.VFC = () => (
  <Box
    sx={{
      width: "102px",
      height: "32px",
    }}
  >
    <CatchLogo />
  </Box>
);

export default AppLogo;
