import { useEffect, useMemo } from "react";
import { useIntercom } from "react-use-intercom";

import * as Sentry from "@sentry/react";
import getGlobal from "globalthis";
import { serializeError } from "serialize-error";

import { useEnv } from "../contexts/env-context";
import { createLogger } from "../logging";
import { injectSegmentScript } from "./segment";
import { getSentryInitState, SentryException } from "./sentry";

const logger = createLogger("tracking");

let isSegmentInjected = false;
let contextData = {};
let userTraits: Record<string, unknown> = {};

// This hook is used internally (within other hooks) to ensure
// that the Segment script is injected once. It is not intended
// for direct use outside of this module.
const useInjectSegment = (): void => {
  const { SEGMENT_KEY } = useEnv();

  if (!isSegmentInjected) {
    getGlobal();
    injectSegmentScript(SEGMENT_KEY);
    isSegmentInjected = true;
    logger.info("Injected Segment");
  }
};

type UseTrackingMethods = {
  // Exceptions are unexpected/unhandled
  captureException: ({
    exceptionMessage,
    component,
    rawError,
    additionalData,
  }: {
    exceptionMessage: string;
    component: string;
    rawError: unknown;
    additionalData?: Record<string, unknown>;
  }) => void;
  // Errors are handled/expected
  trackError: (
    component: string,
    erroredOn: string,
    overrides?: Record<string, unknown>
  ) => void;
  trackEvent: (event: string, overrides?: Record<string, unknown>) => void;
  trackPage: (pageName?: string, overrides?: Record<string, unknown>) => void;
  trackUser: (overrides?: Record<string, unknown>) => void;
  clearUserTraits: () => void;
  patchTrackingContext: (overrides: Record<string, unknown>) => void;
  getTrackingContext: () => Record<string, unknown>;
};

const useTracking = (): UseTrackingMethods => {
  const isSentryInitialized = getSentryInitState();

  const intercom = useIntercom();

  useInjectSegment();

  return useMemo(
    () => ({
      captureException: ({
        exceptionMessage,
        component,
        rawError,
        additionalData = {},
      }) => {
        const originalError = serializeError(rawError);
        const properties = {
          exceptionMessage,
          ...contextData,
          ...additionalData,
          originalError,
        };

        logger.fatal(properties, "Capture exception: %s", component);
        analytics.track(`Exception: ${component}`, properties);

        if (isSentryInitialized) {
          Sentry.captureException(
            new SentryException(`Exception: ${exceptionMessage}`, rawError, {
              ...additionalData,
              originalError,
            })
          );
        }
      },
      trackError: (component, erroredOn, overrides?) => {
        const properties = {
          erroredOn,
          ...contextData,
          ...overrides,
        };
        logger.error(properties, "Track error: %s", component);
        analytics.track(`Error: ${component}`, properties);
      },
      trackEvent: (event, overrides?) => {
        const properties = { ...contextData, ...overrides };
        logger.info(properties, "Track event: %s", event);
        analytics.track(event, properties);
      },
      trackPage: (pageName, overrides?) => {
        const properties = { ...contextData, ...overrides };
        logger.info(properties, "Track page view: %s", pageName);
        analytics.page(pageName, properties);
        intercom.update();
      },
      trackUser: (overrides?) => {
        userTraits = { ...userTraits, ...overrides };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        logger.info(userTraits as any, "Track user");
        if (overrides && overrides.id) {
          analytics.identify(overrides.id as string, userTraits);
        } else {
          analytics.identify(userTraits);
        }

        if (userTraits?.id) {
          const userId = userTraits.id as string | undefined;

          intercom.update({
            userId,
            ...userTraits,
          });
        }

        if (isSentryInitialized) {
          Sentry.setUser(userTraits);
        }
      },
      clearUserTraits: () => {
        logger.info("Clear user");
        userTraits = {};
        analytics.ready(() => {
          analytics.user().traits(userTraits);
        });

        intercom.shutdown();

        if (isSentryInitialized) {
          Sentry.configureScope((scope) => scope.setUser(null));
        }
      },
      patchTrackingContext: (overrides) => {
        contextData = { ...contextData, ...overrides };
        logger.info(contextData, "Patch tracking context");
      },
      getTrackingContext: () => contextData,
    }),
    [intercom, isSentryInitialized]
  );
};

const useTrackPageView = (
  pageName: string,
  overrides?: Record<string, unknown>
): void => {
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage(pageName, overrides);
  }, [trackPage, pageName, overrides]);
};

export { useTracking, useTrackPageView };
