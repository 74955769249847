import React from "react";

import { Stack } from "@mui/material";

type MainContentPaneProps = {
  isTransparent?: boolean;
};

const MainContentPane: React.FC<MainContentPaneProps> = ({
  isTransparent,
  children,
}) => (
  <Stack
    spacing={12}
    sx={({ breakpoints }) => ({
      position: "relative",
      width: "100%",
      py: 8,
      px: 16,
      backgroundColor: isTransparent ? "transparent" : "var(--color-main-bg)",

      // Fill the background color in the space to the right of the content pane
      // in case the viewport is wider than the page max width.
      "::before": {
        content: "''",
        position: "absolute",
        top: 0,
        right: "calc(-1 * ((100vw - var(--page-max-width)) / 2))",
        bottom: 0,
        left: "100%",
        bgcolor: isTransparent ? "transparent" : "var(--color-main-bg)",
        pointerEvents: "none",
      },

      [breakpoints.down("md")]: {
        px: 4,
      },
    })}
  >
    {children}
  </Stack>
);

MainContentPane.defaultProps = {
  isTransparent: false,
};

export default MainContentPane;
