import React, { useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { Launch as LaunchIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { serializeError } from "serialize-error";

import { CompactButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";
import { DialogContentPane } from "~src/components/lib/content-panes";
import {
  ContactHelpFooter,
  DialogBox,
  ErrorCodeBanner,
} from "~src/components/lib/dialog-box";
import { BusinessAcccountHandlePermalinkError } from "~src/hooks/useBusinessAccountHandle";
import useDocumentTitle from "~src/hooks/useDocumentTitle";
import { UserApiError } from "~src/services/userApiClient";

import {
  AppContainer,
  GlobalStyles,
  MainContainer,
  PageContainer,
} from "./base";

const ErrorPage: React.VFC = () => {
  const { trackError, captureException } = useTracking();
  useDocumentTitle("Oops");

  const error = useRouteError();
  const isBusinessAccountHandlePermalinkError =
    error instanceof BusinessAcccountHandlePermalinkError;

  let content = null;

  if (
    isRouteErrorResponse(error) ||
    error instanceof UserApiError ||
    isBusinessAccountHandlePermalinkError
  ) {
    if (isBusinessAccountHandlePermalinkError || error.status === 404) {
      content = (
        <Stack spacing={2}>
          <ErrorCodeBanner>404</ErrorCodeBanner>
          <Typography variant="h1" component="h2">
            Page not found
          </Typography>
          <Typography variant="bodyRegular" component="p">
            You might&apos;ve clicked an old link, or maybe this page has moved.
            In any case, sorry about that!
          </Typography>
        </Stack>
      );
    }
  }

  const isHandledError = !!content;
  if (!isHandledError) {
    content = (
      <Stack spacing={2}>
        <ErrorCodeBanner>OOPS</ErrorCodeBanner>
        <Typography variant="h1" component="h2">
          Something went wrong
        </Typography>
        <Typography variant="bodyRegular" component="p">
          A detailed report has been sent to our engineers.
        </Typography>
      </Stack>
    );
  }

  useEffect(() => {
    const component = "ErrorPage";
    if (isHandledError) {
      trackError(component, "", serializeError(error));
    } else {
      captureException({
        exceptionMessage: error instanceof Error ? error.message : "",
        component,
        rawError: error,
      });
    }
  }, [error, isHandledError, trackError, captureException]);

  return (
    <AppContainer>
      <GlobalStyles />
      <PageContainer>
        <MainContainer>
          <DialogContentPane>
            <DialogBox
              corner={
                <CompactButton
                  endIcon={<LaunchIcon fontSize="inherit" />}
                  onClick={() => {
                    // Use location.assign(), and not router-based navigation, so
                    // that we navigate with a full page reload. We want this in case
                    // we got an error that resulted in the app being in a weird state.
                    window.location.assign("/");
                  }}
                >
                  Home
                </CompactButton>
              }
            >
              {content}
              <ContactHelpFooter />
            </DialogBox>
          </DialogContentPane>
        </MainContainer>
      </PageContainer>
    </AppContainer>
  );
};

export default ErrorPage;
