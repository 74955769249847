import React from "react";

import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

const ViewDetailsArrow: React.VFC = () => (
  <ChevronRightIcon
    aria-label="Details"
    fontSize="inherit"
    sx={({ transitions }) => ({
      fontSize: "20px",
      transition: `transform ${transitions.duration.shorter}ms ${transitions.easing.easeInOut}`,
    })}
  />
);

export default ViewDetailsArrow;
