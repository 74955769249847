import React, { KeyboardEvent, ReactNode, useState } from "react";

import { Box } from "@mui/material";

import usePreviousValue from "~common/hooks/usePreviousValue";

type ReadModeProps = {
  openEditMode: () => void;
  isReturningFromEdit: boolean;
};

type EditModeProps = {
  closeEditMode: () => void;
  onSuccess: () => void;
};

type EditableViewProps = {
  renderReadMode: (props: ReadModeProps) => ReactNode;
  renderEditMode: (props: EditModeProps) => ReactNode;
  onEditSuccess?: () => void;
};

const EditableView: React.VFC<EditableViewProps> = ({
  renderReadMode,
  renderEditMode,
  onEditSuccess = () => {},
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const wasEditMode = usePreviousValue(isEditMode);

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.code === "Escape" && isEditMode) {
      setIsEditMode(false);
    }
  };

  return (
    <Box sx={{ display: "contents" }} onKeyDown={handleKeyDown}>
      {isEditMode
        ? renderEditMode({
            closeEditMode: () => setIsEditMode(false),
            onSuccess: onEditSuccess,
          })
        : renderReadMode({
            openEditMode: () => setIsEditMode(true),
            isReturningFromEdit: !!wasEditMode,
          })}
    </Box>
  );
};

export default EditableView;
export type { EditModeProps, ReadModeProps };
