import React, { useEffect } from "react";

import { Typography } from "@mui/material";

import { useTracking } from "~common/tracking";
import { DialogContentPane } from "~src/components/lib/content-panes";
import { ContactHelpFooter, DialogBox } from "~src/components/lib/dialog-box";

const NoTeams: React.VFC = () => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent("Viewed No Teams page dialog");
  }, [trackEvent]);

  return (
    <DialogContentPane>
      <DialogBox>
        <Typography variant="h1">
          We couldn&apos;t find any teams associated with your account.
        </Typography>
        <ContactHelpFooter />
      </DialogBox>
    </DialogContentPane>
  );
};

export default NoTeams;
