import React from "react";

import { Divider, Stack } from "@mui/material";

const DialogBoxFooter: React.FC = ({ children }) => (
  <Stack
    direction="row"
    spacing={2}
    divider={<Divider orientation="vertical" flexItem />}
    sx={{
      color: "grey.600",
      typography: "bodyRegular",

      a: {
        typography: "linkRegular",
      },
    }}
  >
    {children}
  </Stack>
);

export default DialogBoxFooter;
