import { Components, Theme } from "@mui/material";

const buildCheckbox = (theme: Theme): Components["MuiCheckbox"] => {
  const { palette, shape } = theme;

  return {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: palette.primary.main,
        "&.Mui-focusVisible svg": {
          borderRadius: `${shape.borderRadius}px`,
          outline: "2px solid",
          outlineColor: palette.info.main,
        },
      },
    },
  };
};

export default buildCheckbox;
