// Takes pixel distances from suffixed string to integer.
// Useful for certain components that need to compute screen
// distances as numbers but consume a string value (like
// `theme.spacing(5)`).
//
// e.g. "10px" -> 10
//
// TODO: May be worthwhile checking for number + "px"
// pattern and throwing an error.
const pixelStringToInt = (pixelString: string) =>
  parseInt(pixelString.replace(/\D/g, ""), 10);

export { pixelStringToInt };
