// Custom implementation of the PickersActionBar slot component for date/time pickers.
// See https://mui.com/x/react-date-pickers/custom-components/#action-bar.
// This is repurposed from MUI's default implementation, except that we use different
// components to render the action buttons (MUI's default version uses default MUI buttons
// and does not provide an API to control this, other than overriding the entire action bar).
// See https://github.com/mui/mui-x/blob/master/packages/x-date-pickers/src/PickersActionBar/PickersActionBar.tsx
// for further reference.

import React from "react";

import { DialogActions } from "@mui/material";
import type { PickersActionBarProps } from "@mui/x-date-pickers";

import { TextButtonSmall } from "~common/components/controls/buttons";

const DatePickersActionBar: React.VFC<PickersActionBarProps> = ({
  onAccept,
  onClear,
  onCancel,
  onSetToday,
  actions,
  ...props
}) => {
  if (actions == null || actions.length === 0) {
    return null;
  }

  const buttons = actions?.map((actionType) => {
    switch (actionType) {
      case "clear":
        return (
          <TextButtonSmall onClick={onClear} key={actionType}>
            Clear
          </TextButtonSmall>
        );

      case "cancel":
        return (
          <TextButtonSmall onClick={onCancel} key={actionType}>
            Cancel
          </TextButtonSmall>
        );

      case "accept":
        return (
          <TextButtonSmall onClick={onAccept} key={actionType}>
            OK
          </TextButtonSmall>
        );

      case "today":
        return (
          <TextButtonSmall onClick={onSetToday} key={actionType}>
            Today
          </TextButtonSmall>
        );

      default:
        return null;
    }
  });

  return (
    <DialogActions
      {...props}
      sx={{
        justifyContent: "space-between",
      }}
    >
      {buttons}
    </DialogActions>
  );
};

export default DatePickersActionBar;
