import React, { forwardRef } from "react";

import { WrappedButton } from "~common/components/controls/buttons";
import type { WrappedButtonProps } from "~common/components/controls/buttons/WrappedButton";
import type { Environment } from "~src/store/slices/businessAccountEnvironment-slice";

type AccentAwareButtonProps = WrappedButtonProps & {
  environment?: Environment;
};

const AccentAwareButton: React.FC<AccentAwareButtonProps> = forwardRef(
  ({ children, environment, sx = {}, ...props }, ref) => (
    <WrappedButton
      {...props}
      ref={ref}
      variant="contained"
      color="primary"
      sx={[
        {
          "&.MuiButton-containedPrimary, &.MuiButton-containedPrimary:hover, &.MuiButton-containedPrimary:active":
            {
              backgroundColor: environment
                ? `var(--color-accent-main-${environment})`
                : "var(--color-accent-main)",
            },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </WrappedButton>
  )
);

AccentAwareButton.displayName = "AccentAwareButton";

export default AccentAwareButton;
