import React from "react";

import { Stack } from "@mui/material";

const NavGroupContainer: React.FC = ({ children }) => (
  <Stack spacing={2} sx={{ pb: 2 }}>
    {children}
  </Stack>
);

export default NavGroupContainer;
