import type {
  GetPurchaseByExternalIdPayload,
  GetPurchaseByExternalIdResponseData,
} from "../types/purchases-types";
import userApiClient from "../userApiClient";

const getPurchaseByExternalId = async (
  externalPurchaseId: string,
  payload: GetPurchaseByExternalIdPayload
): Promise<GetPurchaseByExternalIdResponseData> =>
  userApiClient.get<GetPurchaseByExternalIdResponseData>(
    `/purchases/external_purchase_id/${externalPurchaseId}`,
    payload
  );

export default getPurchaseByExternalId;
