import {
  BreakpointsOptions,
  createTheme,
  PaletteOptions,
  ShapeOptions,
  Theme,
  ThemeOptions,
} from "@mui/material";
import { deepmerge } from "@mui/utils";

import CircularBlackWoff2 from "../../assets/fonts/CircularXXWeb-Black.woff2";
import CircularBoldWoff2 from "../../assets/fonts/CircularXXWeb-Bold.woff2";
import CircularMediumWoff2 from "../../assets/fonts/CircularXXWeb-Medium.woff2";
import CircularRegularWoff2 from "../../assets/fonts/CircularXXWeb-Regular.woff2";
import GraphikMediumWoff2 from "../../assets/fonts/Graphik-Medium-Web.woff2";
import GraphikRegularWoff2 from "../../assets/fonts/Graphik-Regular-Web.woff2";
import { CIRCULAR_FONT_FAMILY, GRAPHIK_FONT_FAMILY } from "../shared";
import buildAccordion from "./component-builders/accordion";
import buildAlert from "./component-builders/alert";
import buildBackdrop from "./component-builders/backdrop";
import buildBadge from "./component-builders/badge";
import buildButton from "./component-builders/button";
import buildCheckbox from "./component-builders/checkbox";
import buildDialog from "./component-builders/dialog";
import buildDrawer from "./component-builders/drawer";
import buildIconButton from "./component-builders/iconButton";
import buildPaper from "./component-builders/paper";
import buildSelect from "./component-builders/select";
import buildSnackbar from "./component-builders/snackbar";
import buildSvgIcon from "./component-builders/svgicon";
import buildSwitch from "./component-builders/switch";
import buildTextField from "./component-builders/textfield";
import buildTooltip from "./component-builders/tooltip";

const DEFAULT_PALETTE: PaletteOptions = (function defaultPalette() {
  // The comments next to the color palette values refer to the names
  // used for these colors in the Figma designs.

  const primaryMain = "#d02f90"; // highlights/pink 1
  const primaryLight = "#fff5fb"; // highlights/pink 2
  const secondaryMain = "#0c7d25"; // highlights/green 1
  const secondaryLight = "#e6f4e9"; // highlights/green 2
  const tertiaryMain = "#6b27dd"; // highlights/purple 1
  const tertiaryLight = "#f9f6fe"; // highlights/purple 2
  const tertiaryContrast = "#c7ff00"; // highlights/lime
  const errorMain = "#c11126"; // function/red 1
  const errorLight = "#fde8ea"; // function/red 2
  const infoMain = "#0273eb"; // function/blue 1
  const infoLight = "#e6f1fd"; // function/blue 2
  const grey1 = "#fcfcfd"; // greys/grey 1
  const grey2 = "#f6f7f8"; // greys/grey 2
  const grey3 = "#dcdde5"; // greys/grey 3
  const grey4 = "#a1a4ba"; // greys/grey 4
  const grey5 = "#686d8d"; // greys/grey 5
  const grey6 = "#45485e"; // greys/grey 6
  const grey7 = "#232639"; // greys/grey 7
  const purpleLight = "#e1d4f8"; // shades-tints/purple-light
  const purpleLighter = "#f1eafe"; // shades-tints/purple-lighter
  const white = "#ffffff";
  const black = "#000000";

  const palette: PaletteOptions = {
    mode: "light",
    coloringStyle: "color",

    common: {
      white,
      black,
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
    },
    tertiary: {
      main: tertiaryMain,
      light: tertiaryLight,
      contrastText: tertiaryContrast,
    },
    error: {
      main: errorMain,
      light: errorLight,
    },
    info: {
      main: infoMain,
      light: infoLight,
    },
    grey: {
      100: grey1,
      200: grey2,
      300: grey3,
      400: grey4,
      500: grey5,
      600: grey6,
      700: grey7,
    },
    tints: {
      purple: {
        main: purpleLight,
        light: purpleLighter,
      },
    },

    primaryText: grey7,
    secondaryText: grey6,
    tertiaryText: grey5,
    regularButtonBackground: primaryMain,
    regularButtonText: white,
    bankButtonBackground: infoMain,
    cartBackground: grey1,
    cartPrimaryText: grey7,
    cartSecondaryText: grey6,
    cartEarningCreditsText: primaryMain,
    cartRedeemingCreditsText: secondaryMain,
    cartRedeemingCreditsBackground: secondaryLight,
    tofuPrimaryAccent: primaryMain,
    tofuSecondaryAccent: grey7,
    tofuTertiaryAccent: tertiaryMain,
    tofuTertiaryAccentContrast: tertiaryContrast,
    tofuPrimaryBackground: purpleLighter,
    primaryStroke: grey3,
    donationMain: tertiaryMain,
    donationLight: tertiaryLight,
    boostLight: primaryLight,
    scrollHintText: grey4,
    primaryBackground: purpleLighter,
  };

  return palette;
})();

const DEFAULT_SHAPE: ShapeOptions = {
  borderRadius: 4,
  buttonBorderRadius: 4,
  textFieldBorderRadius: 4,
};

const DEFAULT_TYPOGRAPHY: ThemeOptions["typography"] = {
  fontFamily: `${CIRCULAR_FONT_FAMILY}, Helvetica, Arial, sans-serif`,
  h1: {
    fontSize: "1.625rem",
    lineHeight: 1.23,
    fontWeight: 700,
    letterSpacing: "-0.01em",
  },
  h2: {
    fontSize: "1.25rem",
    lineHeight: 1.4,
    fontWeight: 700,
    letterSpacing: "-0.01em",
  },
  h3: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 700,
  },
  h4: {
    fontSize: "0.875rem",
    lineHeight: 1.429,
    fontWeight: 700,
  },
  bodyLarge: {
    fontSize: "1.125rem",
    lineHeight: 1.556,
    fontWeight: 400,
  },
  bodyRegular: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  bodySmall: {
    fontSize: "0.875rem",
    lineHeight: 1.429,
    fontWeight: 400,
  },
  linkLarge: {
    fontSize: "1.125rem",
    lineHeight: 1.556,
    fontWeight: 400,
    textDecoration: "underline",
  },
  linkRegular: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
    textDecoration: "underline",
  },
  linkSmall: {
    fontSize: "0.875rem",
    lineHeight: 1.429,
    fontWeight: 500,
    textDecoration: "underline",
  },
  button: {
    fontSize: "1.125rem",
    lineHeight: 1.556,
    fontWeight: 500,
    textTransform: "none",
  },
  buttonSmall: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 500,
  },
};

const DEFAULT_BREAKPOINTS: BreakpointsOptions = {
  values: {
    zero: 0,
    xs: 360,
    sm: 480,
    md: 960,
    lg: 1200,
    xl: 1536,
  },
};

const buildBase = (options: Partial<ThemeOptions> = {}): Theme => {
  const defaultOptions: ThemeOptions = {
    name: "",
    isCatch: false,
    isMerchant: false,

    // The comments next to the color palette values refer to the names
    // used for these colors in the Figma designs.
    palette: {
      ...DEFAULT_PALETTE,
      action: {
        hover: "rgba(255, 255, 255, 0.16)",
        hoverOpacity: 0.16,
        active: "rgba(35, 38, 57, 0.08)",
        activatedOpacity: 0.08,
      },
    },
    shape: {
      ...DEFAULT_SHAPE,
    },
    typography: {
      ...DEFAULT_TYPOGRAPHY,
    },
    spacing: 4,
    breakpoints: {
      ...DEFAULT_BREAKPOINTS,
    },
  };

  return createTheme(deepmerge(defaultOptions, options));
};

const plugDerivedValueShims = (theme: Theme): Theme => {
  const { palette, typography } = theme;
  return createTheme(theme, {
    palette: {
      background: {
        default: palette.common.white,
        paper: palette.grey[200],
      },
      text: {
        primary: palette.primaryText,
        secondary: palette.secondaryText,
        disabled: palette.grey[400],
      },
      divider: palette.primaryStroke,
    },
    typography: {
      // Remap built-in typography variants to our own (various MUI components
      // use these by default).
      subtitle1: typography.h3,
      subtitle2: typography.h4,
      body1: typography.bodyLarge,
      body2: typography.bodyRegular,
      caption: typography.bodySmall,
      overline: typography.bodySmall,
    },
    backdrop: {
      opacity: {
        light: 0.16,
        medium: 0.56,
        dark: 0.72,
      },
      color: palette.grey[700],
    },
  });
};

const plugComponentCustomizations = (theme: Theme): Theme =>
  createTheme(theme, {
    components: {
      MuiAccordion: buildAccordion(theme),
      MuiAlert: buildAlert(theme),
      MuiBadge: buildBadge(theme),
      MuiBackdrop: buildBackdrop(theme),
      MuiButton: buildButton(theme),
      MuiCheckbox: buildCheckbox(theme),
      MuiDialog: buildDialog(theme),
      MuiDrawer: buildDrawer(theme),
      MuiIconButton: buildIconButton(theme),
      MuiPaper: buildPaper(theme),
      MuiSelect: buildSelect(theme),
      MuiSnackbar: buildSnackbar(theme),
      MuiSvgIcon: buildSvgIcon(theme),
      MuiSwitch: buildSwitch(theme),
      MuiTextField: buildTextField(theme),
      MuiTooltip: buildTooltip(theme),
    },
  });

const plugFonts = (theme: Theme): Theme => {
  const fontFamily = theme.typography.fontFamily?.split(",")[0].trim();

  let fontFaceCss;
  if (fontFamily === GRAPHIK_FONT_FAMILY) {
    fontFaceCss = `
      @font-face {
        font-family: "${GRAPHIK_FONT_FAMILY}";
        src: url(${String(GraphikRegularWoff2)}) format("woff2");
        font-weight: 400;
      }

      @font-face {
        font-family: "${GRAPHIK_FONT_FAMILY}";
        src: url(${String(GraphikMediumWoff2)}) format("woff2");
        font-weight: 500;
      }
      
      @font-face {
        font-family: "${GRAPHIK_FONT_FAMILY}";
        src: url(${String(GraphikMediumWoff2)}) format("woff2");
        font-weight: 700;
      }
    `;
  } else if (fontFamily === CIRCULAR_FONT_FAMILY) {
    fontFaceCss = `
      @font-face {
        font-family: "${CIRCULAR_FONT_FAMILY}";
        src: url(${String(CircularRegularWoff2)}) format("woff2");
        font-weight: 400;
      }

      @font-face {
        font-family: "${CIRCULAR_FONT_FAMILY}";
        src: url(${String(CircularMediumWoff2)}) format("woff2");
        font-weight: 500;
      }
      
      @font-face {
        font-family: "${CIRCULAR_FONT_FAMILY}";
        src: url(${String(CircularBoldWoff2)}) format("woff2");
        font-weight: 700;
      }

      @font-face {
        font-family: "${CIRCULAR_FONT_FAMILY}";
        src: url(${String(CircularBlackWoff2)}) format("woff2");
        font-weight: 900;
      }
    `;
  } else {
    return theme;
  }

  return createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: fontFaceCss,
      },
    },
  });
};

const buildTheme = (baseOptions?: Partial<ThemeOptions>): Theme =>
  plugFonts(
    plugComponentCustomizations(plugDerivedValueShims(buildBase(baseOptions)))
  );

export { buildTheme, DEFAULT_PALETTE, DEFAULT_SHAPE, DEFAULT_TYPOGRAPHY };
