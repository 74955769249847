import React, { forwardRef } from "react";

import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  useTheme,
} from "@mui/material";

type WrappedButtonProps = ButtonProps & {
  loading?: boolean;
  loadingMessage?: React.ReactNode;
  target?: string;
};

const WrappedButton: React.FC<WrappedButtonProps> = forwardRef(
  (
    { loading, loadingMessage, className, onClick, size, children, ...props },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Button
        className={loading ? `is-loading ${className || ""}` : className}
        onClick={loading ? undefined : onClick}
        size={size}
        ref={ref}
        {...props}
      >
        <Box
          component="span"
          className="label"
          sx={{
            position: "relative",
            zIndex: 2,
            color: loading ? "transparent" : undefined,
          }}
        >
          {children}
        </Box>

        {loading && (
          <Box
            component="span"
            sx={{
              position: "absolute",
              zIndex: 2,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              color="inherit"
              size={theme.spacing(size === "small" ? 4 : 5)}
              sx={{ flexShrink: 0 }}
            />
            {loadingMessage}
          </Box>
        )}
      </Button>
    );
  }
);

WrappedButton.defaultProps = {
  loading: false,
};

WrappedButton.displayName = "WrappedButton";

export default WrappedButton;
export type { WrappedButtonProps };
