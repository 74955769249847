import React from "react";

import { Box } from "@mui/material";

type LogoVariant = "dark" | "light" | "mono-dark" | "mono-light";
type LogoSize = "small" | "medium" | "large";

const sizeToWidth = (size: LogoSize): number => {
  if (size === "small") {
    return 57;
  }
  if (size === "medium") {
    return 88;
  }
  return 105;
};

const sizeToHeight = (size: LogoSize): number => {
  if (size === "small") {
    return 18;
  }
  if (size === "medium") {
    return 28;
  }
  return 36;
};

type CatchLogoProps = {
  variant?: LogoVariant;
  size?: LogoSize;
};

const CatchLogo: React.VFC<CatchLogoProps> = ({ variant, size }) => (
  <Box
    component="span"
    sx={(theme) => {
      const { palette } = theme;
      return {
        display: "inline-flex",
        width: size ? sizeToWidth(size) : "100%",
        height: size ? sizeToHeight(size) : "100%",
        svg: {
          display: "block",
          width: "100%",
          height: "auto",
        },
        ".type-light, .type-mono-light path": {
          fill: palette.common.white,
        },
        ".type-dark path": {
          fill: palette.text.primary,
        },
        ".type-mono-dark path": {
          fill: palette.common.black,
        },
        ".mark-mono-dark path": {
          fill: palette.common.black,
        },
        ".mark-mono-light path": {
          fill: palette.common.white,
        },
      };
    }}
  >
    <svg
      viewBox="0 0 226 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Catch"
    >
      <g className={`type-${variant || "dark"}`}>
        <path d="M86.12 58.9C80 58.9 74.84 56.86 70.64 52.78C66.44 48.7 64.34 43.36 64.34 36.76C64.34 30.2 66.46 24.86 70.7 20.74C74.98 16.62 80.08 14.56 86 14.56C91.44 14.56 95.8 15.94 99.08 18.7C102.4 21.46 104.54 24.78 105.5 28.66L97.7 31.12C97.1 28.72 95.84 26.7 93.92 25.06C92 23.38 89.36 22.54 86 22.54C82.52 22.54 79.46 23.78 76.82 26.26C74.22 28.74 72.92 32.24 72.92 36.76C72.92 41.08 74.2 44.5 76.76 47.02C79.36 49.54 82.48 50.8 86.12 50.8C89.44 50.8 92.08 49.94 94.04 48.22C96.04 46.5 97.38 44.46 98.06 42.1L105.8 44.44C105.36 46.16 104.64 47.84 103.64 49.48C102.68 51.12 101.42 52.66 99.86 54.1C98.3 55.54 96.32 56.7 93.92 57.58C91.56 58.46 88.96 58.9 86.12 58.9Z" />
        <path d="M109.46 49.96C109.46 47.44 110.28 45.42 111.92 43.9C113.56 42.38 115.68 41.42 118.28 41.02L125.54 39.94C127.02 39.74 127.76 39.04 127.76 37.84C127.76 36.72 127.32 35.8 126.44 35.08C125.6 34.36 124.38 34 122.78 34C121.1 34 119.76 34.46 118.76 35.38C117.8 36.3 117.26 37.44 117.14 38.8L110.06 37.3C110.34 34.74 111.6 32.48 113.84 30.52C116.08 28.56 119.04 27.58 122.72 27.58C127.12 27.58 130.36 28.64 132.44 30.76C134.52 32.84 135.56 35.52 135.56 38.8V53.32C135.56 55.08 135.68 56.64 135.92 58H128.6C128.4 57.12 128.3 55.94 128.3 54.46C126.42 57.38 123.52 58.84 119.6 58.84C116.56 58.84 114.1 57.96 112.22 56.2C110.38 54.44 109.46 52.36 109.46 49.96ZM121.28 52.9C123.16 52.9 124.7 52.38 125.9 51.34C127.14 50.26 127.76 48.5 127.76 46.06V44.74L121.1 45.76C118.66 46.12 117.44 47.36 117.44 49.48C117.44 50.44 117.78 51.26 118.46 51.94C119.14 52.58 120.08 52.9 121.28 52.9Z" />
        <path d="M153.268 19.66V28.48H159.208V35.56H153.268V47.92C153.268 49.16 153.548 50.04 154.108 50.56C154.668 51.08 155.548 51.34 156.748 51.34C157.828 51.34 158.648 51.26 159.208 51.1V57.7C158.048 58.18 156.548 58.42 154.708 58.42C151.828 58.42 149.548 57.62 147.868 56.02C146.188 54.38 145.348 52.14 145.348 49.3V35.56H140.008V28.48H141.508C142.988 28.48 144.108 28.06 144.868 27.22C145.668 26.34 146.068 25.2 146.068 23.8V19.66H153.268Z" />
        <path d="M178.954 34.96C176.834 34.96 175.054 35.7 173.614 37.18C172.174 38.66 171.454 40.68 171.454 43.24C171.454 45.8 172.174 47.84 173.614 49.36C175.094 50.84 176.894 51.58 179.014 51.58C180.894 51.58 182.394 51.1 183.514 50.14C184.634 49.14 185.394 47.96 185.794 46.6L192.814 48.94C192.094 51.66 190.534 54 188.134 55.96C185.734 57.92 182.694 58.9 179.014 58.9C174.614 58.9 170.914 57.42 167.914 54.46C164.954 51.46 163.474 47.72 163.474 43.24C163.474 38.72 164.934 34.98 167.854 32.02C170.814 29.06 174.454 27.58 178.774 27.58C182.534 27.58 185.614 28.56 188.014 30.52C190.414 32.44 191.954 34.78 192.634 37.54L185.494 39.94C184.534 36.62 182.354 34.96 178.954 34.96Z" />
        <path d="M206.662 40.66V58H198.682V14.56H206.662V30.94C208.462 28.78 211.122 27.7 214.642 27.7C218.242 27.7 220.962 28.8 222.802 31C224.682 33.16 225.622 35.96 225.622 39.4V58H217.642V40.78C217.642 39.02 217.182 37.6 216.262 36.52C215.382 35.44 214.022 34.9 212.182 34.9C210.542 34.9 209.222 35.44 208.222 36.52C207.262 37.6 206.742 38.98 206.662 40.66Z" />
      </g>
      <g className={`mark-${variant || "dark"}`}>
        {(variant === "light" || variant === "dark") && (
          <>
            <path
              d="M5.22127 0.268233C13.138 3.44477 38.6493 14.4602 42.6197 25.9738C45.1235 33.2343 40.6419 45.6701 35.8935 55.7281C36.2088 50.7036 35.249 45.6711 33.3971 40.8779C30.6341 33.7264 25.8721 27.0608 20.3059 21.626C15.3138 16.4546 8.37811 11.7436 3.65217 8.53345C2.97222 8.0716 2.33802 7.64082 1.76193 7.2442C-0.280906 5.8378 -0.617414 2.90786 1.12151 1.14297C2.19204 0.0564559 3.80282 -0.300911 5.22127 0.268233Z"
              fill="#6B27DD"
            />
            <path
              d="M26.6581 33.0572C27.8495 41.9699 24.0989 55.6483 21.8984 63.6734C21.6785 64.4753 21.4741 65.2207 21.2917 65.8993C20.6506 68.2836 22.1994 70.7457 24.6495 71.1378C26.2696 71.3971 27.8804 70.6731 28.7081 69.2639C29.2729 68.3024 29.9823 67.0711 30.7823 65.6306C34.5963 57.8954 34.1721 49.565 31.134 41.7016C29.983 38.7225 28.4594 35.8196 26.6581 33.0572Z"
              fill="#E1309C"
            />
          </>
        )}
        {(variant === "mono-light" || variant === "mono-dark") && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.6197 25.9738C38.6493 14.4602 13.138 3.44477 5.22127 0.268233C3.80282 -0.300911 2.19204 0.0564559 1.12151 1.14297C-0.617414 2.90786 -0.280906 5.8378 1.76193 7.2442C2.33802 7.64082 2.97222 8.0716 3.65217 8.53345L3.65218 8.53346C8.37812 11.7436 15.3138 16.4546 20.3059 21.626C25.8721 27.0608 30.6341 33.7264 33.3971 40.8779C35.249 45.6711 36.2088 50.7036 35.8935 55.7281C40.6419 45.6701 45.1235 33.2343 42.6197 25.9738ZM21.8984 63.6735C24.0989 55.6483 27.8496 41.9699 26.6581 33.0572C28.4594 35.8196 29.983 38.7225 31.134 41.7016C34.1721 49.565 34.5963 57.8954 30.7823 65.6306C29.9823 67.0711 29.2729 68.3024 28.7081 69.2639C27.8804 70.6731 26.2696 71.3972 24.6495 71.1378C22.1994 70.7457 20.6506 68.2837 21.2917 65.8993C21.4741 65.2207 21.6785 64.4753 21.8984 63.6735Z"
          />
        )}
      </g>
    </svg>
  </Box>
);

CatchLogo.defaultProps = {
  variant: "dark",
};

export default CatchLogo;
