import React from "react";

import { useTrackPageView } from "~common/tracking";
import { PageContentRenderer } from "~src/components/authenticated-page-content";
import { PurchasesLandingPageContent } from "~src/components/purchases/landing";
import useDocumentTitle from "~src/hooks/useDocumentTitle";

const Component: React.VFC = () => {
  useDocumentTitle("Purchases");
  useTrackPageView("Purchases landing");

  return (
    <PageContentRenderer>
      <PurchasesLandingPageContent />
    </PageContentRenderer>
  );
};

Component.displayName = "PurchasesLanding";

export { Component };
