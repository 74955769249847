import React from "react";
import { useSelector } from "react-redux";

import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";
import useRouteConfigHandle from "~src/hooks/useRouteConfigHandle";
import { selectCoreIsAuthenticated } from "~src/store";

import Nav from "./Nav";

const NavRenderer: React.VFC = () => {
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const { isLoading, availableRoleInfos } = useBusinessAccountHandle();
  const { isDialogPage } = useRouteConfigHandle();

  if (
    !isAuthenticated ||
    isDialogPage ||
    isLoading ||
    !availableRoleInfos?.length
  ) {
    return null;
  }
  return <Nav />;
};

export default NavRenderer;
