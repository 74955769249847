import { createLogWriter } from "@roarr/browser-log-writer";
import { Logger, Roarr } from "roarr";

type LoggingMode = "on" | "off" | "default";

type LoggingOptions = {
  mode?: LoggingMode;
};

const configureLogging = (options: LoggingOptions = {}) => {
  const { mode = "default" } = options;

  window.ROARR.write = createLogWriter({
    storage: {
      getItem: (name) => {
        // Shim Roarr Browser Log Writer's localStorage access
        // to give precedence to configured logging modes.
        if (name === "ROARR_LOG") {
          if (mode === "on") {
            return "true";
          }
          if (mode === "off") {
            return null;
          }
        }
        // Fallback to reading "ROARR_LOG" item from local storage.
        // try/catch in case the user has third-party cookies blocked,
        // which may sometimes cause localStorage to throw errors.
        try {
          return localStorage.getItem(name);
        } catch {
          return null;
        }
      },
      setItem: (name, value) => {
        // Roarr Browser Log Writer expects a Storage that implements
        // `setItem()`, even though, currently, this isn't actually
        // used for anything.
        try {
          localStorage.setItem(name, value);
        } catch {
          // Do nothing.
        }
      },
    },
  });
};

const createLogger = (
  namespace: string,
  context: Record<string, unknown> = {}
): Logger =>
  Roarr.child({
    ...context,
    namespace,
  });

export { configureLogging, createLogger };
export type { LoggingMode };
