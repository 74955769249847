import React, { forwardRef } from "react";

import { ButtonProps } from "@mui/material";

import WrappedButton from "./WrappedButton";

const TextButton: React.FC<ButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedButton {...props} ref={ref} variant="text" color="inherit">
      {children}
    </WrappedButton>
  )
);

TextButton.displayName = "TextButton";

export default TextButton;
