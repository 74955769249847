// Unlike the date formatting utils currently exported from
// catch-frontend-common, these ones are implemented using DayJS
// (since that's already a project-specific dependency).
// In the future, we may consider refactoring this into (or consolidating with)
// the common library.

import dayjs from "dayjs";

const formatTimestampLocal = (timestamp: string, format = "M/D/YY"): string =>
  dayjs.utc(timestamp).local().format(format);

const formatTimestampUTC = (timestamp: string, format = "M/D/YY"): string =>
  dayjs.utc(timestamp).format(format);

export { formatTimestampLocal, formatTimestampUTC };
