import { alpha, Components, Theme } from "@mui/material";

const buildButton = (theme: Theme): Components["MuiButton"] => {
  const { palette, spacing, shape, typography, shadows } = theme;
  return {
    defaultProps: {
      variant: "outlined",
      color: "inherit",
      size: "medium",
      disableRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: `${shape.buttonBorderRadius}px`,
        "&.MuiButton-sizeMedium": {
          padding: spacing(3, 4),
          height: spacing(13),
        },
        "&:not(.MuiButton-text) .MuiSvgIcon-fontSizeMedium": {
          fontSize: "16px",
        },
        "&.MuiButton-sizeSmall": {
          padding: spacing(2, 4),
          height: spacing(10),
          ...typography.buttonSmall,
        },
        "&:not(.MuiButton-text) .MuiSvgIcon-fontSizeSmall": {
          fontSize: "14px",
        },
        "&:not(.MuiButton-text) .MuiButton-startIcon": {
          marginLeft: 0,
        },
        "&:not(.MuiButton-text) .MuiButton-endIcon": {
          marginRight: 0,
        },
        "&.MuiButton-contained": {
          boxShadow: shadows[3],
        },
        "&.MuiButton-outlined": {
          borderColor: palette.grey[500],
          color: palette.grey[700],
        },
        "&:after": {
          content: "''",
          display: "none",
          pointerEvents: "none",
          position: "absolute",
          zIndex: 2,
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          borderRadius: `${shape.buttonBorderRadius}px`,
        },
        "&:hover:not(:active)": {
          "&.MuiButton-contained:after": {
            display: "block",
            background: "rgba(255, 255, 255, 0.2)",
          },
          "&.MuiButton-outlined": {
            backgroundColor: palette.grey[200],
          },
        },
        "&.is-loading": {
          "&.MuiButton-outlined": {
            backgroundColor: palette.grey[200],
          },
          "&:after": {
            display: "block",
            background: "rgba(255, 255, 255, 0.2)",
          },
          ".MuiButton-startIcon, .MuiButton-endIcon": {
            opacity: 0,
          },
        },
        "&:active": {
          "&.MuiButton-contained": {
            boxShadow: "none",
          },
          "&.MuiButton-outlined": {
            backgroundColor: alpha(
              palette.grey[700],
              palette.action.activatedOpacity
            ),
          },
          "&:after": {
            display: "none",
          },
        },
        "&.Mui-focusVisible": {
          "&.MuiButton-outlined": {
            backgroundColor: alpha(
              palette.grey[700],
              palette.action.hoverOpacity
            ),
          },
          outline: "2px solid",
          outlineColor: palette.info.main,
        },
        "&.Mui-disabled": {
          "&.MuiButton-contained": {
            boxShadow: "none",
          },
          "&:after": {
            display: "block",
            background: "rgba(255, 255, 255, 0.6)",
          },
        },
        "&.MuiButton-containedRegularButtonBackground": {
          backgroundColor: palette.regularButtonBackground,
          color: palette.regularButtonText,
          "&:hover, &:active": {
            backgroundColor: palette.regularButtonBackground,
          },
        },
        "&.MuiButton-containedBankButtonBackground": {
          backgroundColor: palette.bankButtonBackground,
          color: palette.regularButtonText,
          "&:hover, &:active": {
            backgroundColor: palette.bankButtonBackground,
          },
        },
        "&.MuiButton-text": {
          padding: spacing(1),
          minWidth: "auto",
          "&.inline": {
            padding: 0,
            display: "inline-block",
            verticalAlign: "top",
            ".label": {
              verticalAlign: "top",
            },
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-disabled": {
            ".label": {
              color: palette.grey[400],
            },
          },
          "&.MuiButton-sizeMedium": {
            height: spacing(6),
            ".label": {
              ...typography.linkRegular,
            },
            ".MuiButton-endIcon": {
              marginLeft: "2px",
            },
          },
          "&.MuiButton-sizeSmall": {
            height: spacing(5),
            ".label": {
              ...typography.linkSmall,
            },
            ".MuiButton-endIcon": {
              marginLeft: 0,
            },
          },
          ".label": {
            color: palette.grey[600],
            textDecoration: "underline",
          },
          ".MuiButton-startIcon, .MuiButton-endIcon": {
            ".MuiSvgIcon-fontSizeMedium": {
              fontSize: "20px",
            },
          },
        },
      },
    },
  };
};

export default buildButton;
