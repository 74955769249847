import React, { ReactNode } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { AlertIcon } from "~common/components/icons/vector";

import ModalCloseButton from "./ModalCloseButton";

type DialogNoticeContentProps = {
  title: ReactNode;
  titleId?: string;
  message?: ReactNode;
};

const DialogNoticeContent: React.VFC<DialogNoticeContentProps> = ({
  title,
  titleId,
  message,
}) => (
  <Stack component="header" sx={{ textAlign: "center" }}>
    <Box sx={{ textAlign: "right", lineHeight: 0 }}>
      <ModalCloseButton />
    </Box>

    <Box sx={{ lineHeight: 0, mt: 2, mb: 4 }}>
      <AlertIcon size="small" />
    </Box>

    <Typography id={titleId} variant="h2">
      {title}
    </Typography>

    {message && (
      <Typography component="p" variant="bodyRegular" sx={{ mt: 2 }}>
        {message}
      </Typography>
    )}
  </Stack>
);

export default DialogNoticeContent;
