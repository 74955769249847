import React from "react";

import { Stack } from "@mui/material";

type SectionProps = {
  variant?: "default" | "cozy";
};

const Section: React.FC<SectionProps> = ({ variant, children }) => (
  <Stack spacing={variant === "cozy" ? 8 : 6} component="section">
    {children}
  </Stack>
);

Section.defaultProps = {
  variant: "default",
};

export default Section;
