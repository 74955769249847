import React, { forwardRef } from "react";

import WrappedButton, { WrappedButtonProps } from "./WrappedButton";

const PrimaryButton: React.FC<WrappedButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedButton
      {...props}
      ref={ref}
      variant="contained"
      color="regularButtonBackground"
    >
      {children}
    </WrappedButton>
  )
);

PrimaryButton.displayName = "PrimaryButton";

export default PrimaryButton;
