import React from "react";

import { Box, SxProps, Theme } from "@mui/material";

type VectorIconSize =
  | "icon"
  | "extrasmall"
  | "small"
  | "medium"
  | "large"
  | "original";

type VectorIconProps = {
  size: VectorIconSize;
  sx?: SxProps<Theme>;
  iconSvg?: React.VFC;
  extrasmallSvg?: React.VFC;
  smallSvg?: React.VFC;
  mediumSvg?: React.VFC;
  largeSvg?: React.VFC;
};

export type VectorIconBaseProps = {
  size: VectorIconSize;
  sx?: SxProps<Theme>;
};

const sizeToWidth = (size: VectorIconSize) => {
  if (size === "large") {
    return 240;
  }
  if (size === "medium") {
    return 120;
  }
  if (size === "small") {
    return 80;
  }
  if (size === "extrasmall") {
    return 64;
  }
  return 20;
};

const sizeToHeight = (size: VectorIconSize) => {
  if (size === "large") {
    return 360;
  }
  if (size === "medium") {
    return 120;
  }
  if (size === "small") {
    return 80;
  }
  if (size === "extrasmall") {
    return 64;
  }
  return 20;
};

const VectorIcon: React.VFC<VectorIconProps> = ({
  size,
  sx = [],
  iconSvg,
  extrasmallSvg,
  smallSvg,
  mediumSvg,
  largeSvg,
}) => {
  let SvgComponent: React.VFC = () => <></>;

  // This should try to select the correct glyph for the size,
  // but also fallback to another glyph as gracefully as possible.

  if (size === "icon" && iconSvg) {
    SvgComponent = iconSvg;
  } else if (size === "extrasmall" && extrasmallSvg) {
    SvgComponent = extrasmallSvg;
  } else if (size === "small" && smallSvg) {
    SvgComponent = smallSvg;
  } else if (size === "medium" && mediumSvg) {
    SvgComponent = mediumSvg;
  } else if (size === "large" && largeSvg) {
    SvgComponent = largeSvg;
  } else if (size === "large" && mediumSvg) {
    SvgComponent = mediumSvg;
  } else if (smallSvg) {
    SvgComponent = smallSvg;
  } else if (extrasmallSvg) {
    SvgComponent = extrasmallSvg;
  } else if (iconSvg) {
    SvgComponent = iconSvg;
  } else if (mediumSvg) {
    SvgComponent = mediumSvg;
  } else if (largeSvg) {
    SvgComponent = largeSvg;
  }

  return (
    <Box
      className="VectorIcon"
      component="span"
      sx={[
        {
          display: "inline-flex",
          ...(size !== "original" && { width: sizeToWidth(size) }),
          ...(size !== "original" && { height: sizeToHeight(size) }),
          svg: {
            display: "block",
            width: "100%",
            height: "auto",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <SvgComponent />
    </Box>
  );
};

VectorIcon.defaultProps = {
  iconSvg: undefined,
  extrasmallSvg: undefined,
  smallSvg: undefined,
  mediumSvg: undefined,
  largeSvg: undefined,
  sx: undefined,
};

export default VectorIcon;
