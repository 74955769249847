import Cookies from "js-cookie";

const setLocalStorageWithFallback = (
  key: string,
  value: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  try {
    localStorage.setItem(key, value);
    onSuccess && onSuccess();
  } catch (err) {
    onError && onError(err as Error);
  }

  // localStorage does not expire so the fallback expiration is
  // 400 days, the longest time span supported by any browser
  Cookies.set(key, value, {
    expires: 400,
    sameSite: "none",
    secure: true,
  });
};

const getLocalStorageWithFallback = (
  key: string,
  onError?: (err: Error) => void
) => {
  let item;

  try {
    item = localStorage.getItem(key);
  } catch (err) {
    onError && onError(err as Error);
  }

  if (!item) {
    item = Cookies.get(key);
  }

  return item;
};

const removeLocalStorageWithFallback = (
  key: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  try {
    localStorage.removeItem(key);
    onSuccess && onSuccess();
  } catch (err) {
    onError && onError(err as Error);
  }

  Cookies.remove(key);
};

export {
  getLocalStorageWithFallback,
  removeLocalStorageWithFallback,
  setLocalStorageWithFallback,
};
