import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import {
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";

type LookupFieldHandle = {
  notifyFieldClosed: () => void;
};

type LookupFieldProps = {
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  error?: boolean;
  helperText?: ReactNode;
};

const LookupField = forwardRef<LookupFieldHandle, LookupFieldProps>(
  ({ value, onChange, onClear, label, error, helperText }, ref) => {
    // The label shrink needs to be manually controlled because the
    // start adornment on the input disrupts MUI's default shrinking
    // behavior.
    const [shrink, setShrink] = useState<boolean>(false);

    useEffect(() => {
      value.length && setShrink(true);
    }, [value]);

    useImperativeHandle(
      ref,
      () => ({
        notifyFieldClosed: () => setShrink(false),
      }),
      []
    );

    return (
      <TextField
        label={label}
        value={value}
        onFocus={() => setShrink(true)}
        onBlur={() => !value.length && setShrink(false)}
        onChange={onChange}
        onKeyDown={(event) => event.code === "Escape" && onClear && onClear()}
        error={error}
        helperText={helperText}
        InputLabelProps={{
          shrink,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: onClear ? (
            <InputAdornment position="end">
              <Box
                component="button"
                type="button"
                aria-label="Clear"
                onClick={() => onClear()}
                sx={{
                  lineHeight: 0,
                  ".MuiTextField-root .MuiInputAdornment-root & .MuiSvgIcon-root":
                    {
                      color: "grey.300",
                    },
                }}
              >
                <CancelIcon />
              </Box>
            </InputAdornment>
          ) : null,
        }}
        sx={{
          ".MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
            pl: 10,
          },
        }}
      />
    );
  }
);

LookupField.defaultProps = {
  onClear: undefined,
  error: false,
  helperText: undefined,
};

LookupField.displayName = "LookupField";

export default LookupField;
export type { LookupFieldHandle };
