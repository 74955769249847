import React, { forwardRef } from "react";

import { ButtonProps } from "@mui/material";

import TextButton from "./TextButton";

const InlineTextButton: React.FC<ButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <TextButton {...props} ref={ref} className="inline">
      {children}
    </TextButton>
  )
);

export default InlineTextButton;
