import React from "react";

const SVG: React.VFC = () => (
  <svg
    aria-label="Man Pointing Left"
    aria-hidden="true"
    focusable="false"
    width="98"
    height="105"
    viewBox="0 0 98 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6976 41.5091C20.5914 39.5797 7.38724 34.8367 5.53544 34.1132C2.47593 32.9073 -0.583571 37.4092 3.92517 39.9013C8.27289 42.313 15.3581 46.0109 20.1888 48.0207C24.9391 50.0305 28.7233 51.7187 28.7233 51.7187C28.7233 51.7187 18.8201 47.5384 16.8073 51.7187C14.7945 55.899 25.0196 59.8381 25.0196 59.8381C25.0196 59.8381 15.197 55.1754 13.8283 58.793C12.4596 62.491 21.0745 66.6713 21.0745 66.6713C21.0745 66.6713 13.5868 62.8125 12.1375 65.8674C10.9298 68.3595 14.3919 71.0124 21.9601 75.273C29.5284 79.5337 55.0511 99.2294 64.7932 102.686C74.5353 106.143 93.4559 102.284 96.2739 81.6239C97.8036 69.9673 94.0195 55.0951 79.9297 46.1717C67.1281 38.0523 56.6614 39.0974 56.6614 39.0974C56.6614 39.0974 56.3393 37.3288 52.9577 37.9719C49.5762 38.615 45.0674 41.7503 45.0674 41.7503C45.0674 41.7503 42.0885 43.8404 38.3848 44.162C34.6007 44.4835 26.4689 42.5542 26.4689 42.5542L24.6976 41.5091Z"
      fill="#E1D4F8"
    />
    <path
      d="M49.3346 33.3094C49.3346 33.3094 49.3346 36.0427 47.9658 38.6152C45.953 42.4739 41.3638 43.921 37.2576 44.3229C33.4735 44.7249 25.5832 43.1975 21.9601 39.5799C18.337 35.9623 18.9811 26.3155 18.9811 26.3155C18.9811 26.3155 14.0698 22.6175 14.7944 20.1254C15.519 17.7941 19.0616 19 21.2354 18.4372C23.4093 17.8745 25.5832 11.6844 27.3545 9.03155C29.1257 6.37867 34.8422 1.87681 43.1351 3.72579C47.2412 4.61008 53.5213 9.67467 54.0043 16.5079C54.4874 23.341 53.3602 25.592 53.3602 25.592"
      fill="#E1D4F8"
    />
    <path
      d="M49.3346 33.3094C49.3346 33.3094 49.3346 36.0427 47.9659 38.6152C45.953 42.4739 41.3638 43.921 37.2576 44.3229C33.4735 44.7249 25.5832 43.1975 21.9601 39.5799C18.337 35.9623 18.9811 26.3155 18.9811 26.3155C18.9811 26.3155 14.0698 22.6175 14.7944 20.1254C15.519 17.7941 19.0616 19 21.2354 18.4372C23.4093 17.8745 25.5832 11.6844 27.3545 9.03155C29.1257 6.37867 34.8422 1.87681 43.1351 3.72579C47.2412 4.61008 53.5213 9.67467 54.0043 16.5079C54.4874 23.341 53.3602 25.592 53.3602 25.592"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.0116 1.23355C30.0116 3.3237 34.4398 12.3274 43.9404 13.6137C43.9404 13.6137 44.4234 22.4566 49.2542 23.7429C54.085 25.0291 55.1317 27.3604 54.3266 30.0937C53.3604 33.4701 47.644 32.5054 47.644 32.5054"
      fill="#E1D4F8"
    />
    <path
      d="M30.0116 1.23355C30.0116 3.3237 34.4398 12.3274 43.9404 13.6137C43.9404 13.6137 44.4234 22.4566 49.2542 23.7429C54.085 25.0291 55.1317 27.3604 54.3266 30.0937C53.3604 33.4701 47.644 32.5054 47.644 32.5054"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5622 14.096L37.0966 18.839"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.4738 19.8046C34.1855 19.8046 34.7625 19.2285 34.7625 18.5178C34.7625 17.8072 34.1855 17.2311 33.4738 17.2311C32.7621 17.2311 32.1851 17.8072 32.1851 18.5178C32.1851 19.2285 32.7621 19.8046 33.4738 19.8046Z"
      fill="#232639"
    />
    <path
      d="M36.1303 21.7331C37.338 22.939 37.9822 25.9134 36.3719 30.978C34.7616 36.123 33.715 40.2229 34.037 43.8405"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.1106 39.2581C58.1106 39.2581 57.1445 43.4384 50.7034 47.1364C44.2624 50.8344 43.5377 51.5579 43.5377 51.5579L59.4794 60.8832C59.4794 60.8832 39.9146 87.3316 40.9613 88.2963C42.008 89.2609 62.1363 103.41 68.9799 103.57C75.8236 103.731 88.9472 103.731 94.1001 89.1002C99.2529 74.4691 95.9519 59.5165 85.0021 49.8697C74.0523 40.2228 58.1106 39.2581 58.1106 39.2581Z"
      fill="#6B27DD"
    />
    <path
      d="M18.981 29.2094C20.9938 28.0839 25.8246 32.1035 28.079 33.3093C30.3333 34.5152 31.4605 31.5407 30.5749 31.2996C29.6892 31.0584 27.1933 28.7271 24.7779 26.7173C22.3625 24.7075 19.9471 26.1546 18.981 26.3957V29.2094Z"
      fill="#D02F90"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M46.5972 40.7052C49.6567 39.1778 54.407 36.5249 56.9834 38.3739C59.5598 40.2229 56.1782 42.9562 52.1526 45.8502C48.0464 48.7443 43.5377 51.5579 43.5377 51.5579L59.4793 60.8832L40.4782 87.7336"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.7502 45.207C51.7502 45.207 48.9322 41.0267 57.4666 39.8208L51.7502 45.207Z"
      fill="#E1D4F8"
    />
    <path
      d="M51.7502 45.207C51.7502 45.207 48.9322 41.0267 57.4666 39.8208"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.82355 34.6759C10.1246 36.766 10.7687 38.7758 9.96356 39.9013C9.15843 41.0267 6.58201 41.1071 3.92507 39.4993C1.50967 38.0523 1.50967 36.2837 2.3148 34.9975C3.03942 33.872 4.64969 33.3896 6.82355 34.6759Z"
      fill="#E1D4F8"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6976 41.5091C20.5914 39.5797 7.38724 34.8367 5.53544 34.1132C2.47593 32.9073 -0.583571 37.4092 3.92517 39.9013C8.27289 42.313 15.3581 46.0109 20.1888 48.0207C24.9391 50.0305 28.7233 51.7187 28.7233 51.7187C28.7233 51.7187 18.8201 47.5384 16.8073 51.7187C14.7945 55.899 25.0196 59.8381 25.0196 59.8381C25.0196 59.8381 15.197 55.1754 13.8283 58.793C12.4596 62.491 21.0745 66.6713 21.0745 66.6713C21.0745 66.6713 13.5868 62.8125 12.1375 65.8674C10.9298 68.3595 14.3919 71.0124 21.9601 75.273C29.5284 79.5337 55.0511 99.2294 64.7932 102.686C74.5353 106.143 93.4559 102.284 96.2739 81.6239C97.8036 69.9673 94.0195 55.0951 79.9297 46.1717C69.1409 39.3385 60.2844 38.9366 57.6275 39.017"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.3464 22.9388C35.2367 22.9388 37.5798 20.5994 37.5798 17.7135C37.5798 14.8276 35.2367 12.4881 32.3464 12.4881C29.4561 12.4881 27.1131 14.8276 27.1131 17.7135C27.1131 20.5994 29.4561 22.9388 32.3464 22.9388Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
