import React, { forwardRef } from "react";
import { useHref, useNavigate } from "react-router-dom";

import { WrappedButton } from "~common/components/controls/buttons";
import type { WrappedButtonProps } from "~common/components/controls/buttons/WrappedButton";

type InternalLinkButtonProps = WrappedButtonProps & {
  href: string;
};

const InternalLinkButton: React.FC<InternalLinkButtonProps> = forwardRef(
  ({ href, children, ...props }, ref) => {
    const routerLinkHref = useHref(href);
    const navigate = useNavigate();

    return (
      <WrappedButton
        {...props}
        href={routerLinkHref}
        onClick={(event) => {
          event.preventDefault();
          navigate(href);
        }}
        ref={ref}
      >
        {children}
      </WrappedButton>
    );
  }
);

InternalLinkButton.displayName = "InternalLinkButton";

export default InternalLinkButton;
