import { useEffect } from "react";

const baseTitle = document.title;

const useDocumentTitle = (title: string): void => {
  useEffect(() => {
    document.title = title ? `${title} « ${baseTitle}` : baseTitle;
  }, [title]);
};

export default useDocumentTitle;
