import React from "react";

import { Box } from "@mui/material";

const PageContainer: React.FC = ({ children }) => (
  <Box
    sx={{
      flex: 1,
      display: "flex",
      width: "100%",
      maxWidth: "var(--page-max-width)",
    }}
  >
    {children}
  </Box>
);

export default PageContainer;
