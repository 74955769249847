import React, { forwardRef } from "react";

import { DeleteForeverOutlined as DeleteForeverOutlinedIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

import { WrappedButton } from "~common/components/controls/buttons";
import type { WrappedButtonProps } from "~common/components/controls/buttons/WrappedButton";

type DangerButtonProps = WrappedButtonProps & {
  isDelete?: boolean;
};

const DangerButton: React.FC<DangerButtonProps> = forwardRef(
  ({ children, isDelete, loading, ...props }, ref) => (
    <WrappedButton
      {...props}
      ref={ref}
      loading={loading}
      startIcon={
        isDelete ? (
          <DeleteForeverOutlinedIcon
            fontSize="small"
            sx={{ color: "error.main" }}
          />
        ) : undefined
      }
    >
      <Box
        component="span"
        sx={{ color: loading ? "transparent" : "error.main" }}
      >
        {children}
      </Box>
    </WrappedButton>
  )
);

DangerButton.displayName = "DangerButton";

export default DangerButton;
