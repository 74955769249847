import React, { forwardRef } from "react";

import WrappedButton, { WrappedButtonProps } from "./WrappedButton";

const BankButton: React.FC<WrappedButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedButton
      ref={ref}
      variant="contained"
      color="bankButtonBackground"
      {...props}
    >
      {children}
    </WrappedButton>
  )
);

BankButton.displayName = "BankButton";

export default BankButton;
