import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CoreState = {
  isInitializing: boolean;
  isAuthenticated: boolean;
  isMobileNavOpen: boolean;
};

const initialState: CoreState = {
  isInitializing: true,
  isAuthenticated: false,
  isMobileNavOpen: false,
};

const slice = createSlice({
  name: "core",
  initialState,
  reducers: {
    endInitializing: (state) => {
      state.isInitializing = false;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    toggleMobileNav: (state) => {
      const { isMobileNavOpen } = state;
      state.isMobileNavOpen = !isMobileNavOpen;
    },
  },
});

export const { endInitializing, setIsAuthenticated, toggleMobileNav } =
  slice.actions;
export default slice.reducer;
