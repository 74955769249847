import React, { forwardRef } from "react";

import {
  alpha,
  Backdrop,
  Box,
  Tooltip,
  TooltipProps,
  useTheme,
} from "@mui/material";

import { pixelStringToInt } from "../../utils/screen-distance-utils";

const WrappedTooltip: React.FC<TooltipProps> = forwardRef(
  ({ children, open, onClose, enterTouchDelay = 0, ...props }, ref) => {
    const { palette, breakpoints, spacing } = useTheme();

    return (
      <Box component="span" sx={{ lineHeight: 0 }}>
        <Tooltip
          open={open}
          onClose={onClose}
          // if we don't set this to a default of 0, mui defaults it to 700 ms so a user must
          // long press the opener element to open the tooltip on mobile
          enterTouchDelay={enterTouchDelay}
          {...props}
          ref={ref}
          PopperProps={{
            // gives space between tooltip and borders of screen, using theme spacing.
            modifiers: [
              {
                name: "preventOverflow",
                options: {
                  padding: pixelStringToInt(spacing(4)),
                },
              },
            ],
          }}
        >
          {children}
        </Tooltip>
        <Backdrop
          open={!!open}
          onClick={onClose}
          sx={{
            // pointerEvents property prevents the backdrop from closing the tooltip
            pointerEvents: "none",
            backgroundColor: alpha(
              palette.grey[700],
              palette.action.hoverOpacity
            ),
            [breakpoints.up("md")]: {
              display: "none",
            },
          }}
        />
      </Box>
    );
  }
);

WrappedTooltip.displayName = "WrappedTooltip";

export default WrappedTooltip;
