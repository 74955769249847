import React, { ChangeEvent, FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

import { Stack, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { ErrorAlert } from "~common/components/alerts";
import { PrimaryButton } from "~common/components/controls/buttons";
import { useTracking, useTrackPageView } from "~common/tracking";
import { DialogContentPane } from "~src/components/lib/content-panes";
import { DialogBox, DialogBoxFooter } from "~src/components/lib/dialog-box";
import { PasswordField } from "~src/components/lib/form-controls";
import { becomeAuthenticated } from "~src/coordinators/auth-coordinator";
import useDocumentTitle from "~src/hooks/useDocumentTitle";
import ErrorType from "~src/services/ErrorType";
import login from "~src/services/mutations/login";
import { UserApiError } from "~src/services/userApiClient";
import { selectCoreIsAuthenticated } from "~src/store";
import { REQUEST_INFO_MAILTO_LINK } from "~src/support";

const Component: React.VFC = () => {
  const { trackEvent } = useTracking();
  useDocumentTitle("Log in");
  useTrackPageView("Log in");

  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const location = useLocation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [hasEmailFieldError, setHasEmailFieldError] = useState<boolean>(false);
  const [hasPasswordFieldError, setHasPasswordFieldError] =
    useState<boolean>(false);

  const getNextPath = (): string =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    location.state?.next?.pathname || "/";

  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess(data) {
      becomeAuthenticated(data);
    },
    // This is a no-op, but we need the type annotation here so that
    // we get the correct type inference below when we use loginMutation.error.
    onError(error: UserApiError) {},
  });

  const validate = (): boolean => {
    let isValid = true;

    if (email) {
      setHasEmailFieldError(false);
    } else {
      setHasEmailFieldError(true);
      isValid = false;
    }

    if (password) {
      setHasPasswordFieldError(false);
    } else {
      setHasPasswordFieldError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();

    trackEvent("Log in form submitted", {
      email,
    });

    if (loginMutation.isLoading) {
      return;
    }

    if (validate()) {
      loginMutation.mutate({
        email,
        password,
      });
    } else {
      // Reset the mutation if client side validation failed,
      // to clear its error state. (The error state is cleared
      // automatically when we call loginMutation.mutate()).
      loginMutation.reset();
    }
  };

  if (isAuthenticated) {
    return <Navigate to={getNextPath()} replace />;
  }

  return (
    <DialogContentPane>
      <DialogBox onFormSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant="h1">Welcome back!</Typography>
          <Typography variant="bodyRegular" component="p">
            Log in to access your Catch Business portal.
          </Typography>
        </Stack>
        <Stack spacing={3}>
          <TextField
            type="email"
            label="Email"
            value={email}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value.trim());
              if (hasEmailFieldError) {
                setHasEmailFieldError(false);
              }
            }}
            error={hasEmailFieldError}
          />
          <PasswordField
            label="Password"
            value={password}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
              if (hasPasswordFieldError) {
                setHasPasswordFieldError(false);
              }
            }}
            error={hasPasswordFieldError}
          />
          {loginMutation.error?.error_type === ErrorType.NOT_AUTHORIZED && (
            <ErrorAlert>Your email or password was incorrect.</ErrorAlert>
          )}
        </Stack>
        <PrimaryButton type="submit" loading={loginMutation.isLoading}>
          Log in
        </PrimaryButton>
        <DialogBoxFooter>
          <Link to="/forgot-password">Reset password</Link>
          <a
            href={REQUEST_INFO_MAILTO_LINK}
            onClick={() => trackEvent("Request info link clicked")}
          >
            Request info
          </a>
        </DialogBoxFooter>
      </DialogBox>
    </DialogContentPane>
  );
};

Component.displayName = "Login";

export { Component };
