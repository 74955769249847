import React from "react";

import { Box } from "@mui/material";

const DialogContentPane: React.FC = ({ children }) => (
  <Box
    sx={({ breakpoints }) => ({
      width: "100%",
      pt: 16,

      [breakpoints.down("md")]: {
        pt: 0,
      },
    })}
  >
    {children}
  </Box>
);

export default DialogContentPane;
