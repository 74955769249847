import React, { FormEvent, MouseEvent, ReactNode } from "react";

import { Box, Drawer, Stack } from "@mui/material";

import { AccentAwareLoadingBar } from "~src/components/lib/loading-states";
import type { Environment } from "~src/store/slices/businessAccountEnvironment-slice";

type SideSheetProps = {
  open: boolean;
  onClose: () => void;
  bottom?: ReactNode;
  isLoading?: boolean;
  loadingEnvironment?: Environment;
  // If onFormSubmit callback is provided, SideSheet will render its paper
  // element as a form with onFormSubmit attached as the submit handler.
  onFormSubmit?: (event: FormEvent) => void;
};

const SideSheet: React.FC<SideSheetProps> = ({
  open,
  onClose,
  bottom,
  isLoading,
  loadingEnvironment,
  onFormSubmit,
  children,
}) => {
  const handleClick = (event: MouseEvent) => {
    if ((event.target as HTMLElement).closest(".modal-close-button")) {
      onClose();
    }
  };

  const paperProps = {
    component: onFormSubmit ? "form" : "div",
    onSubmit: onFormSubmit,
    noValidate: onFormSubmit ? true : undefined,
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onClick={handleClick}
      anchor="right"
      PaperProps={paperProps}
      sx={({ breakpoints }) => ({
        ".MuiDrawer-paper": {
          maxHeight: "100%",
          width: 420,
          alignItems: "stretch",

          [breakpoints.down("md")]: {
            width: "calc(100% - var(--mobile-drawer-gutter))",
          },
        },
      })}
    >
      {isLoading && (
        <Box sx={{ position: "absolute", top: 0, left: 0, right: 0 }}>
          <AccentAwareLoadingBar environment={loadingEnvironment} />
        </Box>
      )}
      <Stack
        spacing={8}
        sx={{
          pt: 8,
          px: 8,
          pb: 16,
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {children}
      </Stack>
      {bottom && (
        <Box
          sx={{
            pt: 4,
            px: 8,
            pb: 8,
            borderColor: "divider",
            borderStyle: "solid",
            borderWidth: "1px 0 0",
          }}
        >
          {bottom}
        </Box>
      )}
    </Drawer>
  );
};

SideSheet.defaultProps = {
  bottom: null,
  isLoading: false,
  loadingEnvironment: undefined,
  onFormSubmit: undefined,
};

export default SideSheet;
