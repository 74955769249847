import React from "react";

import { Box } from "@mui/material";

type TableContainerProps = {
  evenNColumns?: number;
};

const TableContainer: React.FC<TableContainerProps> = ({
  evenNColumns,
  children,
}) => (
  <Box
    sx={({ spacing, breakpoints }) => ({
      [breakpoints.down("md")]: {
        width: `calc(100% + ${spacing(4)})`,
        overflowX: "auto",
        scrollbarWidth: "none",

        "&::-webkit-scrollbar": {
          display: "none",
        },

        ".MuiTable-root": {
          minWidth: "600px",
        },
      },

      ".MuiTableCell-root": {
        width: evenNColumns ? `${100 / evenNColumns}%` : undefined,
      },
    })}
  >
    {children}
  </Box>
);

TableContainer.defaultProps = {
  evenNColumns: undefined,
};

export default TableContainer;
