import { Components, Theme } from "@mui/material";

const buildDialog = (theme: Theme): Components["MuiDialog"] => {
  const { spacing, shape, breakpoints } = theme;

  return {
    styleOverrides: {
      root: {
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "0",
          padding: spacing(13, 6, 8, 6),
          margin: spacing(24, "auto"),
          maxWidth: "400px",
          maxHeight: `calc(100vh - ${spacing(28)})`,
          borderRadius: `${shape.borderRadius * 2}px`,
          [breakpoints.down("sm")]: {
            margin: spacing(16, "auto"),
            width: `calc(100% - ${spacing(4)})`,
            maxHeight: `calc(100vh - ${spacing(20)})`,
          },
        },
      },
    },
  };
};

export default buildDialog;
