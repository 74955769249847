import React from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

const ModalCloseButton: React.VFC = () => (
  <Box
    className="modal-close-button"
    component="button"
    type="button"
    aria-label="Close"
    sx={{ lineHeight: 0, color: "grey.500" }}
  >
    <CloseIcon />
  </Box>
);

export default ModalCloseButton;
