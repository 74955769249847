import React, { useState } from "react";

import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { WrappedTooltip } from "~common/components/tooltips";

type NavSubheadingProps = {
  id?: string;
  helperText?: string;
  onHelpButtonClick?: () => void;
};

const NavSubheading: React.FC<NavSubheadingProps> = ({
  id,
  helperText,
  onHelpButtonClick,
  children,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  return (
    <Typography
      id={id}
      variant="h4"
      component="h2"
      sx={{
        display: "flex",
        alignItems: "center",
        color: "grey.500",
        button: {
          ml: 1,
          lineHeight: 0,
        },

        ".MuiSvgIcon-root": {
          fontSize: "16px",
          color: "grey.400",
        },
      }}
    >
      <span>{children}</span>
      {helperText && (
        <WrappedTooltip
          title={helperText}
          open={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
        >
          <button
            aria-label="Help"
            type="button"
            onClick={() => {
              setIsTooltipOpen((prev) => !prev);
              onHelpButtonClick && onHelpButtonClick();
            }}
          >
            <HelpOutlineIcon />
          </button>
        </WrappedTooltip>
      )}
    </Typography>
  );
};

NavSubheading.defaultProps = {
  id: undefined,
  helperText: undefined,
  onHelpButtonClick: undefined,
};

export default NavSubheading;
