import { createLogger } from "~common/logging";
import ErrorType from "~src/services/ErrorType";
import refreshAuth from "~src/services/mutations/refreshAuth";
import { UserApiError } from "~src/services/userApiClient";
import store from "~src/store";
import { endInitializing } from "~src/store/slices/core-slice";

import { becomeAuthenticated } from "./auth-coordinator";

const logger = createLogger("init-coordinator");

const coreInit = async (): Promise<void> => {
  try {
    const authData = await refreshAuth();
    logger.info("Completing core init for authenticated user");
    becomeAuthenticated(authData);
  } catch (error) {
    if (
      error instanceof UserApiError &&
      error.error_type === ErrorType.NOT_AUTHORIZED
    ) {
      logger.info("Completing core init for anonymous user");
    } else {
      throw error;
    }
  }

  store.dispatch(endInitializing());
};

export { coreInit };
