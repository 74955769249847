const SUPPORT_EMAIL = "merchant-support@getcatch.com";

const REQUEST_INFO_EMAIL_SUBJECT =
  "Request for Information on Catch Business Portal";

const REQUEST_INFO_EMAIL_BODY =
  "Hi Catch team, I’d like to learn more about how I can access the Business Portal.";

const REQUEST_INFO_MAILTO_LINK = `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(
  REQUEST_INFO_EMAIL_SUBJECT
)}&body=${encodeURIComponent(REQUEST_INFO_EMAIL_BODY)}`;

export { REQUEST_INFO_MAILTO_LINK, SUPPORT_EMAIL };
