import React, { forwardRef } from "react";

import { IconButtonProps } from "@mui/material";

import WrappedIconButton from "./WrappedIconButton";

const PrimaryIconButton: React.FC<IconButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedIconButton {...props} ref={ref} color="regularButtonBackground">
      {children}
    </WrappedIconButton>
  )
);

PrimaryIconButton.displayName = "PrimaryIconButton";

export default PrimaryIconButton;
