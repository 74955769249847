import React from "react";

import { Stack } from "@mui/material";

import ValidationRuleMarker from "./ValidationRuleMarker";

type PasswordStrengthHelperProps = {
  isLengthSatisfied?: boolean;
  isSymbolSatisfied?: boolean;
  isNumberSatisfied?: boolean;
  isCapitalLetterSatisfied?: boolean;
};

const PasswordStrengthHelper: React.VFC<PasswordStrengthHelperProps> = ({
  isLengthSatisfied,
  isSymbolSatisfied,
  isNumberSatisfied,
  isCapitalLetterSatisfied,
}) => (
  <Stack className="password-strength-helper" direction="row" spacing="10px">
    <ValidationRuleMarker isSatisfied={isLengthSatisfied}>
      8+ characters
    </ValidationRuleMarker>
    <ValidationRuleMarker isSatisfied={isSymbolSatisfied}>
      Symbol
    </ValidationRuleMarker>
    <ValidationRuleMarker isSatisfied={isNumberSatisfied}>
      Number
    </ValidationRuleMarker>
    <ValidationRuleMarker isSatisfied={isCapitalLetterSatisfied}>
      Capital
    </ValidationRuleMarker>
  </Stack>
);

PasswordStrengthHelper.defaultProps = {
  isLengthSatisfied: false,
  isSymbolSatisfied: false,
  isNumberSatisfied: false,
  isCapitalLetterSatisfied: false,
};

export default PasswordStrengthHelper;
