import { useSelector } from "react-redux";

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import patchCurrentUser from "~src/services/mutations/patchCurrentUser";
import getCurrentUser from "~src/services/queries/getCurrentUser";
import type { UserData } from "~src/services/types/core-entity-types";
import type { PatchCurrentUserPayload } from "~src/services/types/current-user-types";
import { selectCoreIsAuthenticated } from "~src/store";

type CurrentUserHandle = {
  isLoading: boolean;
  data?: UserData;
  patchMutation: UseMutationResult<
    UserData,
    unknown,
    PatchCurrentUserPayload,
    unknown
  >;
};

const useCurrentUserHandle = (): CurrentUserHandle => {
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const currentUserQueryKey = ["currentUser"];

  const { isLoading, data } = useQuery({
    queryKey: currentUserQueryKey,
    queryFn: () => getCurrentUser(),
    staleTime: Infinity,
    enabled: isAuthenticated,
  });

  const queryClient = useQueryClient();

  const patchMutation = useMutation({
    mutationFn: patchCurrentUser,
    onSuccess(updatedData) {
      queryClient.setQueryData(currentUserQueryKey, updatedData);
    },
  });

  return {
    isLoading,
    data,
    patchMutation,
  };
};

export default useCurrentUserHandle;
