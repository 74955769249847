import React, { forwardRef } from "react";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";

import WrappedButton from "./WrappedButton";

const DropToggleButton: React.FC<ButtonProps> = forwardRef(
  ({ children, sx = [], ...props }, ref) => (
    <WrappedButton
      {...props}
      ref={ref}
      variant="outlined"
      size="small"
      color="inherit"
      endIcon={<ArrowDropDownIcon />}
      sx={[
        ({ shape, spacing }) => ({
          "&.MuiButton-sizeSmall": {
            p: 1,
            minWidth: "auto",
            height: spacing(6),
            backgroundColor: "transparent",
            border: "none",
            borderRadius: `${shape.borderRadius}px`,
          },
          ".MuiButton-endIcon": {
            ml: 0,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </WrappedButton>
  )
);

DropToggleButton.displayName = "DropToggleButton";

export default DropToggleButton;
