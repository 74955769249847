import React from "react";

import { Stack, Typography } from "@mui/material";

import { JacquesPointingLeftIcon } from "~common/components/icons/vector";

const ErrorCodeBanner: React.FC = ({ children }) => (
  <Stack direction="row" spacing={3}>
    <Typography
      component="h1"
      sx={({ palette }) => ({
        fontSize: "6rem",
        fontWeight: 700,
        letterSpacing: "-0.01em",
        lineHeight: 1,
        color: "var(--color-main-bg)",
        textShadow: `2px 2px 0px ${palette.primary.main}`,
        WebkitTextStroke: `1px ${palette.text.primary}`,
      })}
    >
      {children}
    </Typography>
    <JacquesPointingLeftIcon size="original" />
  </Stack>
);

export default ErrorCodeBanner;
