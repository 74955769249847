import React from "react";

import { Typography } from "@mui/material";

const PageHeaderTitle: React.FC = ({ children }) => (
  // Allow long titles containing IDs to wrap anywhere.
  <Typography variant="h1" sx={{ overflowWrap: "anywhere" }}>
    {children}
  </Typography>
);

export default PageHeaderTitle;
