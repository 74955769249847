import { configureStore } from "@reduxjs/toolkit";

import businessAccountEnvironment from "./slices/businessAccountEnvironment-slice";
import core from "./slices/core-slice";

const store = configureStore({
  reducer: {
    core,
    businessAccountEnvironment,
  },
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

export default store;
export type { AppDispatch, RootState };
