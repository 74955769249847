import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Lock as LockIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { WrappedTooltip } from "~common/components/tooltips";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { useTracking } from "~common/tracking";
import { capitalizeFirstLetter } from "~common/utils/string-utils";
import { AccentAwareButton } from "~src/components/lib/buttons";
import { SkeletonWrapper } from "~src/components/lib/loading-states";
import {
  Dialog,
  DialogActionsContainer,
  DialogNoticeContent,
} from "~src/components/lib/modals";
import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";
import useRouteConfigHandle from "~src/hooks/useRouteConfigHandle";
import type { Environment } from "~src/store/slices/businessAccountEnvironment-slice";

// Wraps capitalizeFirstLetter just as a convenience so we can pass maybe-null values.
// (This is just to appease/trick the type checker, not because we intentionally pass null).
const unsafeTitleCase = (value: string | null) =>
  capitalizeFirstLetter(value || "");

type EnvironmentToggleProps = {
  ariaLabelledBy?: string;
};

const EnvironmentToggle: React.VFC<EnvironmentToggleProps> = ({
  ariaLabelledBy,
}) => {
  const { trackEvent } = useTracking();
  const {
    isLoading,
    activeEnvironment,
    activateEnvironment,
    isLiveEnvironmentLocked,
  } = useBusinessAccountHandle();
  const { isDetailPage, detailAncestorPath } = useRouteConfigHandle();
  const navigate = useNavigate();
  const [requestToggleAwayPath, setRequestToggleAwayPath] =
    useState<string | null>(null);
  const toggleDialogId = useUniqueId("environment-toggle-dialog");
  const oppositeEnvironment = activeEnvironment === "live" ? "sandbox" : "live";

  const requestActivateEnvironment = (environment: Environment): void => {
    if (isDetailPage) {
      setRequestToggleAwayPath(detailAncestorPath);
    } else {
      activateEnvironment(environment);
    }
  };

  const liveButton = (
    <button
      type="button"
      aria-pressed={activeEnvironment === "live"}
      disabled={isLiveEnvironmentLocked ? true : undefined}
      onClick={() => {
        trackEvent("Live environment toggle button clicked");
        activeEnvironment !== "live" && requestActivateEnvironment("live");
      }}
    >
      Live {isLiveEnvironmentLocked && <LockIcon fontSize="inherit" />}
    </button>
  );

  return (
    <>
      <SkeletonWrapper isLoading={isLoading} skeletonVariant="rectangular">
        <Box
          role="group"
          aria-labelledby={ariaLabelledBy}
          sx={({ shadows }) => ({
            display: "inline-flex",
            alignItems: "center",
            width: "fit-content",
            padding: "1px",
            backgroundColor: "grey.300",
            borderRadius: 2,
            overflow: "hidden",

            button: {
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: 80,
              py: 1,
              px: 4,
              borderRadius: 2,
              color: "grey.400",
              typography: "buttonSmall",

              "&[aria-pressed='true']": {
                color: "text.primary",
                backgroundColor: "background.default",
                boxShadow: shadows[2],

                "&.toggle-sandbox-button": {
                  color: "background.default",
                  backgroundColor: "var(--color-accent-main-sandbox)",
                },
              },

              ".MuiSvgIcon-root": {
                ml: 1,
                fontSize: "12px",
              },
            },
          })}
        >
          {isLiveEnvironmentLocked ? (
            <WrappedTooltip title="The Catch team will enable live mode once integration is complete.">
              <span>{liveButton}</span>
            </WrappedTooltip>
          ) : (
            <>{liveButton}</>
          )}
          <button
            type="button"
            className="toggle-sandbox-button"
            aria-pressed={activeEnvironment === "sandbox"}
            onClick={() => {
              trackEvent("Sandbox environment toggle button clicked");
              activeEnvironment !== "sandbox" &&
                requestActivateEnvironment("sandbox");
            }}
          >
            Sandbox
          </button>
        </Box>
      </SkeletonWrapper>

      <Dialog
        open={requestToggleAwayPath !== null}
        onClose={() => setRequestToggleAwayPath(null)}
        ariaLabelledBy={toggleDialogId}
      >
        <DialogNoticeContent
          title="Switching environments will take you away from this page"
          titleId={toggleDialogId}
          message={
            <>
              The data you&apos;re looking at is in the{" "}
              {unsafeTitleCase(activeEnvironment)} environment. Do you want to
              navigate away?
            </>
          }
        />
        <DialogActionsContainer>
          <AccentAwareButton
            environment={oppositeEnvironment}
            autoFocus
            onClick={() => {
              navigate(requestToggleAwayPath || "/");
              activateEnvironment(oppositeEnvironment);
              setRequestToggleAwayPath(null);
            }}
          >
            Switch to {unsafeTitleCase(oppositeEnvironment)}
          </AccentAwareButton>
          <DefaultButton onClick={() => setRequestToggleAwayPath(null)}>
            Stay here
          </DefaultButton>
        </DialogActionsContainer>
      </Dialog>
    </>
  );
};

export default EnvironmentToggle;
