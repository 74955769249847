import React from "react";

import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

type ValidationRuleMarkerProps = {
  isSatisfied?: boolean;
};

const ValidationRuleMarker: React.FC<ValidationRuleMarkerProps> = ({
  isSatisfied,
  children,
}) => (
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center",
      typography: "bodySmall",
      color: "grey.600",
    }}
  >
    <Box component="span" sx={{ color: "grey.400", lineHeight: 0, mr: "2px" }}>
      <CheckCircleIcon
        color={isSatisfied ? "primary" : "inherit"}
        fontSize="inherit"
      />
    </Box>
    <span>{children}</span>
  </Box>
);

ValidationRuleMarker.defaultProps = {
  isSatisfied: false,
};

export default ValidationRuleMarker;
