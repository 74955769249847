import React, { lazy, Suspense, useEffect, useState } from "react";

import {
  QueryClient,
  QueryClientProvider as Provider,
} from "@tanstack/react-query";

import { useEnv } from "~common/contexts/env-context";

const queryClient = new QueryClient();

const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

const QueryClientProvider: React.FC = ({ children }) => {
  const { APP_ENVIRONMENT } = useEnv();
  const [showDevtools, setShowDevtools] = useState<boolean>(
    () => APP_ENVIRONMENT === "development"
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleReactQueryDevTools = () =>
      setShowDevtools((currentVal) => !currentVal);
  }, []);

  return (
    <Provider client={queryClient}>
      {children}

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtools />
        </Suspense>
      )}
    </Provider>
  );
};

export default QueryClientProvider;
