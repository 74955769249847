import { LoggingMode } from "~common/logging";
import { AppEnvironment, AppStage } from "~common/utils/config-utils";

const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT as AppEnvironment;
const IS_DEV = APP_ENVIRONMENT === "development";
const IS_PROD = APP_ENVIRONMENT === "production";

// We only ever run in one stage, referred to as "universal".
// This config is hardcoded because in some cases we need to reference
// a stage. For example, our common library "env provider" requires an
// app stage.
const APP_STAGE: AppStage = "universal";

const EXTERNAL_ASSETS_BASE_URL = import.meta.env
  .VITE_EXTERNAL_ASSETS_BASE_URL as string;

const SEGMENT_KEY = import.meta.env.VITE_SEGMENT_KEY as string;

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;

const LOGGING_MODE: LoggingMode =
  (import.meta.env.VITE_LOGGING_MODE as LoggingMode) || "default";

const ROUTER_BASENAME = "/p";

const INTERCOM_APP_ID = "bx6xqtsr";

export {
  APP_ENVIRONMENT,
  APP_STAGE,
  EXTERNAL_ASSETS_BASE_URL,
  INTERCOM_APP_ID,
  IS_DEV,
  IS_PROD,
  LOGGING_MODE,
  ROUTER_BASENAME,
  SEGMENT_KEY,
  SENTRY_DSN,
};
