import { Components, Theme } from "@mui/material";

const buildPaper = (theme: Theme): Components["MuiPaper"] => {
  const { palette } = theme;
  return {
    styleOverrides: {
      root: {
        background: palette.common.white,
      },
    },
  };
};

export default buildPaper;
