import React, { useEffect } from "react";

import TopLoading from "~src/components/TopLoading";
import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";
import useRouteConfigHandle from "~src/hooks/useRouteConfigHandle";
import { restoreRootCssVar, setRootCssVar } from "~src/utils/dom-utils";

import NoPermissions from "./NoPermissions";
import NoTeams from "./NoTeams";

const PageContentRenderer: React.FC = ({ children }) => {
  const { isLoading, availableRoleInfos, activeRoleInfo, activeEnvironment } =
    useBusinessAccountHandle();
  const { isRenderIfNoTeams, requiredRole } = useRouteConfigHandle();

  // This effect manages the global accent color CSS variables when the sandbox
  // environment mode is activated. NOTE: We intentionally don't give this effect
  // a cleanup function to restore the CSS variables, to avoid unecessary toggling
  // back and forth of the CSS variables when navigating between pages (in which
  // scenario, this instance of the <PageContentRenderer> will unmount because
  // each route typically uses its own distinct <PageContentRenderer>).
  useEffect(() => {
    if (activeEnvironment === "sandbox") {
      setRootCssVar("--color-accent-main", "var(--color-accent-main-sandbox)");
      setRootCssVar("--color-accent-mid", "var(--color-accent-mid-sandbox)");
      setRootCssVar(
        "--color-accent-light",
        "var(--color-accent-light-sandbox)"
      );
    } else {
      restoreRootCssVar("--color-accent-main");
      restoreRootCssVar("--color-accent-mid");
      restoreRootCssVar("--color-accent-light");
    }
  }, [activeEnvironment]);

  if (isLoading) {
    return <TopLoading />;
  }

  if (!isRenderIfNoTeams && !availableRoleInfos?.length) {
    return <NoTeams />;
  }

  if (requiredRole && activeRoleInfo) {
    const { role } = activeRoleInfo;
    let hasPermissions = true;

    if (Array.isArray(requiredRole)) {
      if (requiredRole.length && !requiredRole.includes(role)) {
        hasPermissions = false;
      }
    } else if (requiredRole !== role) {
      hasPermissions = false;
    }

    if (!hasPermissions) {
      return <NoPermissions />;
    }
  }

  return <>{children}</>;
};

export default PageContentRenderer;
