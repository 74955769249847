import React from "react";
import { Link } from "react-router-dom";

import { TableCell } from "@mui/material";

import { ViewDetailsArrow } from "~src/components/lib/detail-elements";

type ViewDetailsCellProps = {
  href?: string;
  onClick?: () => void;
};

const ViewDetailsCell: React.VFC<ViewDetailsCellProps> = ({
  href,
  onClick,
}) => (
  <TableCell
    sx={({ spacing }) => ({
      ".MuiTable-root &": {
        padding: "0",
      },
      "a, button": {
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "auto",
        py: 3,
        pl: 4,
        color: "grey.500",

        ".MuiTableRow-hover &": {
          "--active-state-opacity": 1,
        },

        ".MuiTableRow-hover:hover &": {
          ".MuiSvgIcon-root": {
            transform: `translate3d(${spacing(1)}, 0, 0)`,
          },
        },
      },
    })}
  >
    {href ? (
      <Link to={href}>
        <ViewDetailsArrow />
      </Link>
    ) : (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          onClick && onClick();
        }}
      >
        <ViewDetailsArrow />
      </button>
    )}
  </TableCell>
);

export default ViewDetailsCell;
