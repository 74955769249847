import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

type ExternalLinkProps = {
  href: string;
  onClick?: () => void;
};

const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  onClick,
  children,
}) => (
  <Typography
    variant="bodyRegular"
    component="a"
    href={href}
    onClick={onClick}
    target="_blank"
    rel="noreferrer"
    sx={{
      display: "inline-flex",
      alignItems: "center",
    }}
  >
    <Typography variant="linkRegular">{children}</Typography>
    <OpenInNewIcon fontSize="inherit" sx={{ ml: 1 }} />
  </Typography>
);

ExternalLink.defaultProps = {
  onClick: undefined,
};

export default ExternalLink;
