import { Components, Theme } from "@mui/material";

const buildBadge = (theme: Theme): Components["MuiBadge"] => {
  const { spacing, palette, typography } = theme;
  return {
    defaultProps: {
      color: "default",
      variant: "standard",
      overlap: "rectangular",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
    styleOverrides: {
      root: {
        ".MuiBadge-badge": {
          backgroundColor: palette.grey[300],
          color: palette.grey[600],
          boxShadow: `0 0 1px ${palette.common.white}`,
          borderRadius: "50%",
          zIndex: "auto",
          height: spacing(5),
          width: spacing(5),
          transform: "translate(40%, -40%)",
          ...typography.bodySmall,
        },
      },
    },
  };
};

export default buildBadge;
