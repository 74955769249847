import { Components, Theme } from "@mui/material";

const buildSnackbar = (theme: Theme): Components["MuiSnackbar"] => {
  const { spacing, palette, typography, breakpoints } = theme;

  return {
    defaultProps: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
    styleOverrides: {
      root: {
        "&.MuiSnackbar-anchorOriginTopRight": {
          top: spacing(4),
          right: spacing(4),
          [breakpoints.down("sm")]: {
            left: spacing(4),
          },
        },
        "> .MuiBox-root": {
          width: "359px",
          [breakpoints.down("sm")]: {
            width: "100%",
          },
        },
        ".MuiAlert-root": {
          ...typography.bodyRegular,
          textAlign: "left",
          boxShadow: "0px 2px 4px rgba(35, 38, 57, 0.24)",
          padding: spacing(2, 5, 2, 3),
        },
        ".MuiPaper-root.MuiAlert-filledInfo": {
          backgroundColor: palette.info.main,
          color: palette.background.default,
        },
        ".MuiPaper-root.MuiAlert-filledError": {
          ".MuiAlert-message": {
            textAlign: "left",
          },
          ".MuiAlert-action": {
            color: palette.grey[400],
          },
        },
        ".MuiPaper-root.MuiAlert-filledSuccess": {
          backgroundColor: palette.primary.light,
          color: palette.primary.main,
        },
        ".MuiAlert-icon, .MuiAlert-message, .MuiAlert-action": {
          padding: 0,
        },
        ".MuiAlert-message": {
          paddingRight: spacing(3),
        },
        ".MuiAlert-icon, .MuiAlert-action": {
          marginTop: spacing(0.5),
          marginBottom: spacing(0.5),
        },
        ".MuiAlert-icon .MuiSvgIcon-fontSizeSmall": {
          fontSize: "20px",
        },
        ".MuiIconButton-root.MuiIconButton-sizeSmall": {
          color: "inherit",
          ".MuiSvgIcon-fontSizeSmall": {
            fontSize: "20px",
          },
        },
      },
    },
  };
};

export default buildSnackbar;
