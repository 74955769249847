import React from "react";
import { useSelector } from "react-redux";

import useRouteConfigHandle from "~src/hooks/useRouteConfigHandle";
import { selectCoreIsAuthenticated } from "~src/store";

import Appbar from "./Appbar";
import Skipnav from "./Skipnav";

const AppbarRenderer: React.VFC = () => {
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const { isDialogPage } = useRouteConfigHandle();

  if (!isAuthenticated || isDialogPage) {
    return null;
  }

  return (
    <>
      <Skipnav />
      <Appbar />
    </>
  );
};

export default AppbarRenderer;
