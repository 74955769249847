import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { Dayjs } from "dayjs";

import { useTracking } from "~common/tracking";
import type { LookupFieldHandle } from "~src/components/lib/form-controls";
import useDebounce from "~src/hooks/lib/useDebounce";
import useBusinessAccountHandle from "~src/hooks/useBusinessAccountHandle";
import type {
  BusinessAccountEnvironmentPayload,
  DateRangePaginationPayload,
  PageInfo,
} from "~src/services/types/core-entity-types";
import { UserApiError } from "~src/services/userApiClient";

type ListResponseData<TResultEntryData> = {
  results: TResultEntryData[];
  page_info: PageInfo;
};

type IndexViewOptions<TResultEntryData> = {
  baseQueryKey: string;
  listQueryFn: (
    payload: BusinessAccountEnvironmentPayload & DateRangePaginationPayload
  ) => Promise<ListResponseData<TResultEntryData>>;
  lookupQueryFn: (
    lookupValue: string,
    payload: BusinessAccountEnvironmentPayload
  ) => Promise<TResultEntryData>;
};

type IndexView<TResultEntryData> = {
  fromDate: Dayjs | null;
  setFromDate: Dispatch<SetStateAction<Dayjs | null>>;
  toDate: Dayjs | null;
  setToDate: Dispatch<SetStateAction<Dayjs | null>>;
  startDatetime: string;
  endDatetime: string;
  hasValidDateRange: boolean;
  clearDates: () => void;
  listQuery: UseInfiniteQueryResult<
    ListResponseData<TResultEntryData>,
    UserApiError
  >;
  rawLookupValue: string;
  setRawLookupValue: Dispatch<SetStateAction<string>>;
  lookupValue: string;
  isLookupExpanded: boolean;
  closeLookup: () => void;
  lookupFieldHandleRef: RefObject<LookupFieldHandle>;
  lookupQuery: UseQueryResult<TResultEntryData, UserApiError>;
  results: TResultEntryData[];
  pageHeaderRef: RefObject<HTMLElement>;
  isPageHeaderScrolled: boolean;
};

const useIndexView = <TResultEntryData>({
  baseQueryKey,
  listQueryFn,
  lookupQueryFn,
}: IndexViewOptions<TResultEntryData>): IndexView<TResultEntryData> => {
  const { trackEvent } = useTracking();
  const { businessAccountId, isSandbox } = useBusinessAccountHandle();

  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);

  const startDatetime =
    fromDate && fromDate.isValid() ? fromDate.toISOString() : "";
  const endDatetime = toDate && toDate.isValid() ? toDate.toISOString() : "";
  const hasValidDateRange = !!(
    startDatetime &&
    endDatetime &&
    toDate?.isAfter(fromDate)
  );

  const clearDates = (): void => {
    setFromDate(null);
    setToDate(null);
  };

  const listQueryKey = [`${baseQueryKey}List`, businessAccountId, isSandbox];
  if (hasValidDateRange && startDatetime && endDatetime) {
    listQueryKey.push(startDatetime);
    listQueryKey.push(endDatetime);
  }

  const listQuery = useInfiniteQuery<
    ListResponseData<TResultEntryData>,
    UserApiError
  >({
    queryKey: listQueryKey,
    queryFn: ({ pageParam }) =>
      listQueryFn({
        business_account_id: businessAccountId,
        sandbox: isSandbox,
        start_datetime: hasValidDateRange ? startDatetime : undefined,
        end_datetime: hasValidDateRange ? endDatetime : undefined,
        cursor: pageParam ? (pageParam as string) : undefined,
      }),
    getNextPageParam: ({ page_info }) =>
      page_info.has_next ? page_info.end_cursor : undefined,
    enabled: !!businessAccountId,
  });

  const [rawLookupValue, setRawLookupValue] = useState<string>("");
  const lookupValue = useDebounce(rawLookupValue);
  const [isLookupExpanded, setIsLookupExpanded] = useState<boolean>(false);
  const lookupFieldHandleRef = useRef<LookupFieldHandle>(null);
  const closeLookup = (): void => {
    setIsLookupExpanded(false);
    setRawLookupValue("");
    lookupFieldHandleRef.current?.notifyFieldClosed();
  };

  useEffect(() => {
    if (lookupValue) {
      if (!isLookupExpanded) {
        trackEvent("Lookup mode entered", {
          lookupValue,
        });
        setIsLookupExpanded(true);
      }
    } else if (isLookupExpanded) {
      setIsLookupExpanded(false);
    }
    // We don't need/want isLookupExpanded as a dependency of this effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupValue]);

  const lookupQuery = useQuery({
    queryKey: [
      `${baseQueryKey}Lookup`,
      businessAccountId,
      isSandbox,
      lookupValue,
    ],
    queryFn: () =>
      lookupQueryFn(lookupValue, {
        business_account_id: businessAccountId,
        sandbox: isSandbox,
      }),
    enabled: !!businessAccountId && !!lookupValue,
    // Don't retry 404s.
    retry: (_, error: UserApiError) => error.status !== 404,
  });

  const results: TResultEntryData[] = [];
  const listQueryData = listQuery.data;
  const lookupQueryData = lookupQuery.data;

  if (lookupQueryData) {
    results.push(lookupQueryData);
  } else if (!lookupValue && listQueryData) {
    listQueryData.pages.forEach((page) => {
      page.results.forEach((lisTResultEntryData) =>
        results.push(lisTResultEntryData)
      );
    });
  }

  const pageHeaderRef = useRef<HTMLElement>(null);
  const [isPageHeaderScrolled, setIsPageHeaderScrolled] =
    useState<boolean>(false);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      setIsPageHeaderScrolled(!entries[0].isIntersecting);
    });

    const pageHeader = pageHeaderRef.current;
    if (pageHeader) {
      intersectionObserver.observe(pageHeader);
    }

    return () => {
      if (pageHeader) {
        intersectionObserver.unobserve(pageHeader);
      }
      intersectionObserver.disconnect();
    };
  }, [pageHeaderRef]);

  return {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    startDatetime,
    endDatetime,
    hasValidDateRange,
    clearDates,
    listQuery,
    rawLookupValue,
    setRawLookupValue,
    lookupValue,
    isLookupExpanded,
    closeLookup,
    lookupFieldHandleRef,
    lookupQuery,
    results,
    pageHeaderRef,
    isPageHeaderScrolled,
  };
};

export default useIndexView;
