import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";

import { Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

import { TextButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";
import type { LookupFieldHandle } from "~src/components/lib/form-controls";
import { LookupField, TextFieldPair } from "~src/components/lib/form-controls";
import {
  PageBreadcrumbs,
  PageHeader,
  PageHeaderTitle,
} from "~src/components/lib/page-elements";
import { setTinyTimeout } from "~src/utils/timer-utils";

import IndexViewControls from "./IndexViewControls";

type IndexViewPageHeaderProps = {
  title: string;
  lookupTitle: string;
  lookupFieldLabel: string;
  fromDate: Dayjs | null;
  setFromDate: Dispatch<SetStateAction<Dayjs | null>>;
  toDate: Dayjs | null;
  setToDate: Dispatch<SetStateAction<Dayjs | null>>;
  hasValidDateRange: boolean;
  clearDates: () => void;
  rawLookupValue: string;
  setRawLookupValue: Dispatch<SetStateAction<string>>;
  isLookupExpanded: boolean;
  closeLookup: () => void;
  // We're intentionally just passing these refs as straight-up props,
  // rather than forwardRef'ing them. This is because these are required
  // props, and we have multiple different refs.
  lookupFieldHandleRef: RefObject<LookupFieldHandle>;
  pageHeaderRef: RefObject<HTMLElement>;
};

const IndexViewPageHeader: React.VFC<IndexViewPageHeaderProps> = ({
  title,
  lookupTitle,
  lookupFieldLabel,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  hasValidDateRange,
  clearDates,
  rawLookupValue,
  setRawLookupValue,
  isLookupExpanded,
  closeLookup,
  lookupFieldHandleRef,
  pageHeaderRef,
}) => {
  const { trackEvent } = useTracking();
  const fromInputRef = useRef<HTMLInputElement>(null);
  const toInputRef = useRef<HTMLInputElement>(null);

  const [hasFromFieldError, setHasFromFieldError] = useState<boolean>(false);
  const [hasToFieldError, setHasToFieldError] = useState<boolean>(false);

  return (
    <PageHeader ref={pageHeaderRef}>
      {isLookupExpanded ? (
        <>
          <PageBreadcrumbs
            crumbs={[
              {
                label: title,
                onClick: () => closeLookup(),
              },
            ]}
            current={lookupTitle}
          />
          <PageHeaderTitle>{lookupTitle}</PageHeaderTitle>
        </>
      ) : (
        <PageHeaderTitle>{title}</PageHeaderTitle>
      )}

      <IndexViewControls
        lookup={
          <LookupField
            ref={lookupFieldHandleRef}
            label={lookupFieldLabel}
            value={rawLookupValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRawLookupValue(event.target.value)
            }
            onClear={isLookupExpanded ? closeLookup : undefined}
          />
        }
        isLookupExpanded={isLookupExpanded}
      >
        <TextFieldPair>
          <DateTimePicker
            inputRef={fromInputRef}
            label="From"
            value={fromDate}
            onChange={(newFromDate) => {
              trackEvent("From date changed", {
                fromDate: newFromDate ? newFromDate.toISOString() : null,
              });
              setFromDate(newFromDate);
              if (hasFromFieldError) {
                setHasFromFieldError(false);
              }
            }}
            onAccept={() => {
              setTinyTimeout(() => {
                const toInput = toInputRef.current;
                if (!toDate && toInput) {
                  toInput.focus();
                }
              });
            }}
            maxDate={toDate || undefined}
            slotProps={{
              textField: {
                error: hasFromFieldError || undefined,
                onFocus: () => {
                  if (hasFromFieldError) {
                    setHasFromFieldError(false);
                  }
                },
                onBlur: () => {
                  setTinyTimeout(() => {
                    if (toDate && !fromDate) {
                      setHasFromFieldError(true);
                    }
                  });
                },
                inputProps: {
                  spellCheck: false,
                },
              },
              actionBar: {
                actions: ["clear", "accept"],
              },
            }}
          />
          <DateTimePicker
            inputRef={toInputRef}
            label="To"
            value={toDate}
            onChange={(newToDate) => {
              trackEvent("To date changed", {
                toDate: newToDate ? newToDate.toISOString() : null,
              });
              setToDate(newToDate);
              if (hasToFieldError) {
                setHasToFieldError(false);
              }
            }}
            onAccept={() => {
              setTinyTimeout(() => {
                const fromInput = fromInputRef.current;
                if (!fromDate && fromInput) {
                  fromInput.focus();
                }
              });
            }}
            minDate={fromDate || undefined}
            slotProps={{
              textField: {
                error: hasToFieldError || undefined,
                onFocus: () => {
                  if (hasToFieldError) {
                    setHasToFieldError(false);
                  }
                },
                onBlur: () => {
                  setTinyTimeout(() => {
                    if (fromDate && !toDate) {
                      setHasToFieldError(true);
                    }
                  });
                },
                inputProps: {
                  spellCheck: false,
                },
              },
              actionBar: {
                actions: ["clear", "accept"],
              },
            }}
          />
        </TextFieldPair>
        {hasValidDateRange && (
          <Box
            textAlign="right"
            sx={{
              ".MuiButton-text .label": {
                color: "grey.500",
              },
            }}
          >
            <TextButton
              onClick={() => {
                trackEvent("Clear dates button clicked");
                clearDates();
              }}
            >
              Clear dates
            </TextButton>
          </Box>
        )}
      </IndexViewControls>
    </PageHeader>
  );
};

export default IndexViewPageHeader;
