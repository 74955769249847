import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { createLogger } from "~common/logging";
import { useTracking } from "~common/tracking";
import {
  getLocalStorageWithFallback,
  removeLocalStorageWithFallback,
  setLocalStorageWithFallback,
} from "~common/utils/browser-storage";
import getUserRoleInfos from "~src/services/queries/getUserRoleInfos";
import type { RoleInfoData } from "~src/services/types/core-entity-types";
import {
  selectBusinessAccountId,
  selectCoreIsAuthenticated,
  selectEnvironment,
} from "~src/store";
import type { Environment } from "~src/store/slices/businessAccountEnvironment-slice";
import {
  setBusinessAccountId,
  setEnvironment,
} from "~src/store/slices/businessAccountEnvironment-slice";

type BusinessAcccountHandle = {
  isLoading: boolean;
  availableRoleInfos: RoleInfoData[] | null;
  activeRoleInfo: RoleInfoData | null;
  isAdmin: boolean;
  // While businessAccountId can always be derived from activeRoleInfo,
  // we expose businessAccountId, guaranteed to be a string, as a convenience
  // for components to use and avoid an if-not-null check.
  businessAccountId: string;
  activeEnvironment: Environment | null;
  // While isSandbox can always be derived from activeEnvironment,
  // we expose isSandbox, guaranteed to be a boolean, as a convenience
  // for components to use and avoid an if-not-null check.
  isSandbox: boolean;
  isLiveEnvironmentLocked: boolean;
  activateEnvironment: (environment: Environment) => void;
  activateBusinessAccount: (businessAccountId: string) => void;
  flush: () => Promise<void>;
};

// When useBusinessAccountHandle() detects a business account ID or environment
// in the URL path, it may throw this exception if the business account ID or environment
// from the URL is invalid (e.g. the user doesn't have an available role for that business
// account). The nearest error boundary may handle this as it chooses, for example by
// rendering a 404 page.
class BusinessAcccountHandlePermalinkError extends Error {}

const logger = createLogger("useBusinessAccountHandle");

// We only want to auto activate a business account once across all invocations
// of the useBusinessAccountHandle hook. This guard is shared globally between all
// hooks, in case the hook is invoked multiple times in a row.
let hasStartedAutoActivation = false;

// Remember the user's active business account ID and environment in browser storage.
// If the user reloads the app, we attempt to default to whatever their last active
// business account ID / environment was.
const ACTIVE_BUSINESS_ACCOUNT_ID_STORAGE_KEY =
  "business_portal_business_account_id";
const ACTIVE_ENVIRONMENT_STORAGE_KEY = "business_portal_environment";

const getRoleInfoByBusinessAccountId = (
  businessAccountId: string,
  availableRoleInfos: RoleInfoData[]
): RoleInfoData | null =>
  availableRoleInfos.find(
    (roleInfoData) => roleInfoData.business_account.id === businessAccountId
  ) || null;

const liveEnvironmentAvailable = (roleInfoData: RoleInfoData): boolean =>
  roleInfoData.business_account.merchant.onboarding_status === "compliant";

const useBusinessAccountHandle = (): BusinessAcccountHandle => {
  const { patchTrackingContext } = useTracking();
  const isAuthenticated = useSelector(selectCoreIsAuthenticated);
  const queryClient = useQueryClient();
  const { isLoading, data: userRoleInfosData } = useQuery({
    queryKey: ["userRoleInfos"],
    queryFn: () => getUserRoleInfos(),
    staleTime: Infinity,
    // In case the useBusinessAccountHanlde() is invoked before the user
    // is authenticated, defer running this query. (This happens, for example,
    // because <NavRenderer /> is mounted whether or not the user is authenticated).
    enabled: isAuthenticated,
  });
  const activeBusinessAccountId = useSelector(selectBusinessAccountId);
  const activeEnvironment = useSelector(selectEnvironment);
  const dispatch = useDispatch();
  const routerParams = useParams();

  const handle: BusinessAcccountHandle = useMemo(() => {
    let availableRoleInfos: RoleInfoData[] | null = null;
    let activeRoleInfo: RoleInfoData | null = null;
    let isLiveEnvironmentLocked = false;
    if (userRoleInfosData) {
      availableRoleInfos = userRoleInfosData.results;
      if (activeBusinessAccountId) {
        activeRoleInfo = getRoleInfoByBusinessAccountId(
          activeBusinessAccountId,
          availableRoleInfos
        );
        if (activeRoleInfo) {
          isLiveEnvironmentLocked = !liveEnvironmentAvailable(activeRoleInfo);
        }
      }
    }

    const activateEnvironment = (environment: Environment): void => {
      logger.info({ environment }, "Activating environment");

      if (activeRoleInfo && environment === "live" && isLiveEnvironmentLocked) {
        logger.warn(
          { activeRoleInfo },
          "Cannot activate live environment because it is locked"
        );
        return;
      }

      dispatch(setEnvironment(environment));
      setLocalStorageWithFallback(ACTIVE_ENVIRONMENT_STORAGE_KEY, environment);
      patchTrackingContext({
        environment,
      });
    };

    const activateBusinessAccount = (businessAccountId: string): void => {
      logger.info({ businessAccountId }, "Activating business account");

      const roleInfoData =
        availableRoleInfos &&
        getRoleInfoByBusinessAccountId(businessAccountId, availableRoleInfos);

      if (roleInfoData) {
        dispatch(setBusinessAccountId(businessAccountId));
        setLocalStorageWithFallback(
          ACTIVE_BUSINESS_ACCOUNT_ID_STORAGE_KEY,
          businessAccountId
        );
        patchTrackingContext({
          businessAccountId,
          merchantId: roleInfoData.business_account.merchant.merchant_id,
        });

        const isLiveEnvironmentAvailable =
          liveEnvironmentAvailable(roleInfoData);

        if (activeEnvironment === "live" && !isLiveEnvironmentAvailable) {
          activateEnvironment("sandbox");
        } else if (!activeEnvironment) {
          logger.info("Auto-activating environment");

          const { environment: pathEnvironment } = routerParams;

          if (pathEnvironment) {
            logger.info("Detected environment in URL path");

            if (pathEnvironment === "live" || pathEnvironment === "sandbox") {
              if (pathEnvironment === "live" && !isLiveEnvironmentAvailable) {
                throw new BusinessAcccountHandlePermalinkError();
              }
              activateEnvironment(pathEnvironment);
              return;
            }
            throw new BusinessAcccountHandlePermalinkError();
          }

          const lastActiveEnvironment = getLocalStorageWithFallback(
            ACTIVE_ENVIRONMENT_STORAGE_KEY
          );
          if (
            lastActiveEnvironment === "live" ||
            lastActiveEnvironment === "sandbox"
          ) {
            logger.info(
              "Using last activated environment recovered from local/cookie storage"
            );
            if (
              lastActiveEnvironment === "live" &&
              !isLiveEnvironmentAvailable
            ) {
              logger.warn(
                "The last activated environment cannot be activated because live environment is locked"
              );
              activateEnvironment("sandbox");
            } else {
              activateEnvironment(lastActiveEnvironment);
            }
          } else {
            logger.info(
              "Using default environment (based on merchant onboarding status)"
            );
            activateEnvironment(
              isLiveEnvironmentAvailable ? "live" : "sandbox"
            );
          }
        }
      } else {
        logger.warn(
          { businessAccountId, availableRoleInfos },
          "Cannot activate business account because it is not available"
        );
      }
    };

    const flush = async (): Promise<void> => {
      logger.info("Flushing");

      removeLocalStorageWithFallback(ACTIVE_BUSINESS_ACCOUNT_ID_STORAGE_KEY);
      removeLocalStorageWithFallback(ACTIVE_ENVIRONMENT_STORAGE_KEY);

      await queryClient.invalidateQueries({
        queryKey: ["userRoleInfos"],
      });

      hasStartedAutoActivation = false;
      dispatch(setBusinessAccountId(null));
      patchTrackingContext({
        businessAccountId: null,
        merchantId: null,
      });
    };

    return {
      isLoading,
      availableRoleInfos,
      activeRoleInfo,
      isAdmin: activeRoleInfo?.role === "admin",
      businessAccountId: activeRoleInfo
        ? activeRoleInfo.business_account.id
        : "",
      activeEnvironment,
      isSandbox: activeEnvironment === "sandbox",
      isLiveEnvironmentLocked,
      activateEnvironment,
      activateBusinessAccount,
      flush,
    };
  }, [
    activeBusinessAccountId,
    activeEnvironment,
    dispatch,
    isLoading,
    patchTrackingContext,
    queryClient,
    routerParams,
    userRoleInfosData,
  ]);

  // This effect manages the auto-activation of a business account
  // once the user role infos have been loaded.
  useEffect(() => {
    if (userRoleInfosData && !activeBusinessAccountId) {
      if (hasStartedAutoActivation) {
        return;
      }

      hasStartedAutoActivation = true;
      logger.info("Auto-activating business account");

      const roleInfos = userRoleInfosData.results;
      if (!roleInfos.length) {
        logger.warn(
          { availableRoleInfos: roleInfos },
          "Cannot activate business account because user has no available roles"
        );
        return;
      }

      const { businessAccountId: pathBusinessAccountId } = routerParams;

      if (pathBusinessAccountId) {
        logger.info("Detected business account ID in URL path");
        if (getRoleInfoByBusinessAccountId(pathBusinessAccountId, roleInfos)) {
          handle.activateBusinessAccount(pathBusinessAccountId);
          return;
        }
        throw new BusinessAcccountHandlePermalinkError();
      }

      const lastActiveBusinessAccountId = getLocalStorageWithFallback(
        ACTIVE_BUSINESS_ACCOUNT_ID_STORAGE_KEY
      );
      if (
        typeof lastActiveBusinessAccountId === "string" &&
        // Make sure that the role from storage is actually available
        getRoleInfoByBusinessAccountId(lastActiveBusinessAccountId, roleInfos)
      ) {
        logger.info(
          "Using last activated business account ID recovered from local/cookie storage"
        );
        handle.activateBusinessAccount(lastActiveBusinessAccountId);
      } else {
        logger.info(
          "Using default business account ID (most recently created available role info)"
        );
        handle.activateBusinessAccount(roleInfos[0].business_account.id);
      }
    }
  }, [
    activeBusinessAccountId,
    dispatch,
    handle,
    routerParams,
    userRoleInfosData,
  ]);

  return handle;
};

export default useBusinessAccountHandle;
export { BusinessAcccountHandlePermalinkError };
