import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { Box } from "@mui/material";

type NavItemProps = {
  href: string;
  icon: ReactNode;
  onClick?: () => void;
};

const NavItem: React.FC<NavItemProps> = ({ href, icon, onClick, children }) => {
  const isExternal = href.startsWith("http");

  const content = (
    <>
      {icon}
      {children}
    </>
  );

  return (
    <Box
      sx={{
        color: "grey.600",
        typography: "buttonSmall",

        a: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          py: 1.5,
          px: 2,
          borderRadius: 1,

          "&.active": {
            color: "var(--color-accent-main)",
            backgroundColor: "var(--color-accent-light)",
          },

          "&:hover": {
            color: "var(--color-accent-main)",
          },
        },

        ".MuiSvgIcon-root": {
          fontSize: 20,
          mr: 2,
        },
      }}
    >
      {isExternal ? (
        <a href={href} onClick={onClick} target="_blank" rel="noreferrer">
          {content}
        </a>
      ) : (
        <NavLink to={href} onClick={onClick}>
          {content}
        </NavLink>
      )}
    </Box>
  );
};

NavItem.defaultProps = {
  onClick: undefined,
};

export default NavItem;
