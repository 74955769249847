import React from "react";

import { Box } from "@mui/material";

const AppContainer: React.FC = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
      alignItems: "center",
    }}
  >
    {children}
  </Box>
);

export default AppContainer;
