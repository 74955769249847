import type {
  GetPurchasesListPayload,
  GetPurchasesListResponseData,
} from "../types/purchases-types";
import userApiClient from "../userApiClient";

const getPurchasesList = async (
  payload: GetPurchasesListPayload
): Promise<GetPurchasesListResponseData> =>
  userApiClient.get<GetPurchasesListResponseData>("/purchases", payload);

export default getPurchasesList;
