import React, { forwardRef } from "react";

import WrappedButton, { WrappedButtonProps } from "./WrappedButton";

const DefaultButton: React.FC<WrappedButtonProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedButton {...props} ref={ref}>
      {children}
    </WrappedButton>
  )
);

DefaultButton.displayName = "DefaultButton";

export default DefaultButton;
